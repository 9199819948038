
<!-- Header: Standard ----------------------------------------------------------------------------->
<appHeader></appHeader>

<!-- Main Content --------------------------------------------------------------------------------->



<div class="container-xxl vsMainContent vsMainContentContainer"><!--container-xxl Start-->

  <form [formGroup]="this.editForm">

    <vsLayoutGroup class="col-xl-12">
      <h4>{{'application.commit.heading'| translate}}</h4>
  </vsLayoutGroup>

  <vsLayoutGroup class="col-xl-12" Orientation="horizontal" >
    <vsLayoutGroup class="col-xl-6">
      <h4>{{'application.commit.application.heading'| translate}}</h4>
    </vsLayoutGroup>

  </vsLayoutGroup>

    <ng-template [ngIf]="kennDisplayPersData" [ngIfElse]="elseBlockAntrag">

      <vsLayoutGroup class="col-xl-12" Orientation="horizontal" isPanel="true">

        <!-- 50 % der Breite -->
        <vsLayoutGroup class="col-xl-6" Orientation="vertical">
          <vsLayoutGroup class="col" Orientation="horizontal">
            <label class="vsInputLabel">{{'application.commit.application.application_kind'| translate}}</label>
            <vsLayoutGroup class="col">
              <div *ngIf="dataModel.antrag.Antr_ID_Art==='I'" class="vsFormTextDiv">{{'application_kind.heading_3'| translate}}</div>
              <div *ngIf="dataModel.antrag.Antr_ID_Art==='U'" class="vsFormTextDiv">{{'application_kind.heading_1'| translate}}</div>
              <div *ngIf="dataModel.antrag.Antr_ID_Art==='E'" class="vsFormTextDiv">{{'application_kind.heading_2'| translate}}</div>
              <div *ngIf="dataModel.antrag.Antr_ID_Art==='G'" class="vsFormTextDiv">{{'application_kind.heading_4'| translate}}</div>
              <div *ngIf="dataModel.antrag.Antr_ID_Art==='GA'" class="vsFormTextDiv">{{'application_kind.heading_5'| translate}}</div>
              <div *ngIf="dataModel.antrag.Antr_ID_Art==='EA'" class="vsFormTextDiv">{{'application_kind.heading_6'| translate}}</div>
            </vsLayoutGroup>
          </vsLayoutGroup>
        </vsLayoutGroup>
  
        <!-- 50 % der Breite -->
        <vsLayoutGroup class="col-xl-6" Orientation="vertical">
  
          <vsLayoutGroup class="col" Orientation="horizontal">
            <label class="vsInputLabel">{{'application.commit.application.application_date'| translate}}</label>
            <vsLayoutGroup class="col">
              <div class="vsFormTextDiv">{{antrDatum}}</div>
            </vsLayoutGroup>
          </vsLayoutGroup>
  
        </vsLayoutGroup>
  
        <div style="margin-top: 1rem; margin-bottom: 0.25rem;">
          <!--<p-button (click)="actionStepNext()">Weiter</p-button>-->
          <!-- <button pButton label="{{'application.button_edit'| translate}}" (click)="doBearb_Antrag()" class="vsElternButton" [attr.aria-label]="'application.commit.buttonLabelsAria.edit_application'| translate"></button> -->
        </div>
  
      </vsLayoutGroup><!--Ende Angaben zur Fahrt-->
  
      <vsLayoutGroup class="col" Orientation="vertical">
        <h4>{{'application.commit.student.heading'| translate}}</h4>
      </vsLayoutGroup>
  
      <vsLayoutGroup class="col" Orientation="horizontal" isPanel="true">
  
        <!-- 50 % der Breite -->
        <vsLayoutGroup class="col-xl-6" Orientation="vertical">
  
          <vsLayoutGroup class="col" Orientation="horizontal">
            <label class="vsInputLabel" width="180px">{{'application.commit.student.student'| translate}}</label>
            <vsLayoutGroup class="col">
              <div class="vsFormTextDiv">{{dataModel.antrag?.Azubi_Anrede_Kuerzel}} {{dataModel.antrag?.Azubi_Nachname}}, {{dataModel.antrag?.Azubi_Vorname}}</div>
            </vsLayoutGroup>
          </vsLayoutGroup>
  
          <vsLayoutGroup class="col" Orientation="horizontal">
            <label class="vsInputLabel">{{'application.commit.student.date_birth'| translate}}</label>
            <vsLayoutGroup class="col">
              <div class="vsFormTextDiv">{{gebDatumAzubi}}</div>
            </vsLayoutGroup>
          </vsLayoutGroup>
  
        </vsLayoutGroup><!--Ende col-xl-6-->
  

  
        <div style="margin-top: 1rem; margin-bottom: 0.25rem;">
          <!-- <button pButton label="{{'application.button_edit'| translate}}" (click)="doBearb_Student()" class="vsElternButton" [attr.aria-label]="'application.commit.buttonLabelsAria.edit_student'| translate"></button> -->
        </div>
      </vsLayoutGroup>
  
      <!--Beschreibung -->
  
      <vsLayoutGroup class="col" Orientation="vertical">
        <h4>{{'application.commit.school.heading'| translate}}</h4>
      </vsLayoutGroup>
  
      <vsLayoutGroup class="col" Orientation="horizontal" isPanel="true">
  
        <!-- 100 % der Breite -->
        <vsLayoutGroup class="col-xl-6">
  
          <vsLayoutGroup class="col" Orientation="horizontal">
            <label class="vsInputLabel">{{'application.commit.school.school_name'| translate}}</label>
            <vsLayoutGroup class="col">
              <div class="vsFormTextDiv">{{dataModel.antrag?.SchuleAntr_Name}}</div>
            </vsLayoutGroup>
          </vsLayoutGroup>
  
          <vsLayoutGroup class="col" Orientation="horizontal">
            <label class="vsInputLabel">{{'application.commit.school.class'| translate}}</label>
            <vsLayoutGroup class="col">
              <div class="vsFormTextDiv" style="overflow-wrap: break-word;">{{dataModel.antrag?.Antr_Klasse_Beg_Nr}}{{dataModel.antrag?.Antr_Klasse_Beg_Zusatz}}</div>
            </vsLayoutGroup>
          </vsLayoutGroup>
  
        </vsLayoutGroup><!--Ende col-xl-6-->
  
        <vsLayoutGroup class="col-xl-6">
  
          <vsLayoutGroup class="col" Orientation="horizontal">
            <label class="vsInputLabel">{{'application.commit.school.school_kind'| translate}}</label>
            <vsLayoutGroup class="col">
              <div class="vsFormTextDiv">{{schulart?.Art_Name}}</div>
            </vsLayoutGroup>
          </vsLayoutGroup>
  
          <vsLayoutGroup class="col" Orientation="horizontal">
          </vsLayoutGroup>
  
        </vsLayoutGroup><!--Ende col-xl-6-->
  
        <div style="margin-top: 1rem; margin-bottom: 0.25rem;">
          <!-- <button pButton label="{{'application.button_edit'| translate}}" (click)="doBearb_Schule()" class="vsElternButton" [attr.aria-label]="'application.commit.buttonLabelsAria.edit_school'| translate"></button> -->
        </div>
  
      </vsLayoutGroup>
  
      <vsLayoutGroup *ngIf="dataModel.antrag?.Antr_AusbStaett_Beruf_Name1" class="col" Orientation="vertical">
        <h4>{{'application.school_company.heading_training_company'| translate}}:</h4>
      </vsLayoutGroup>
  
      <vsLayoutGroup *ngIf="dataModel.antrag?.Antr_AusbStaett_Beruf_Name1" class="col-xl-12" Orientation="horizontal" isPanel="true">
  
        <!-- 50 % der Breite -->
        <vsLayoutGroup class="col-xl-6" Orientation="vertical">
          <vsLayoutGroup class="col" Orientation="horizontal">
            <label class="vsInputLabel">{{'application.school_company.training_company'| translate}}:</label>
            <vsLayoutGroup class="col">
              <div class="vsFormTextDiv">{{dataModel.antrag?.Antr_AusbStaett_Beruf_Name1}}</div>
            </vsLayoutGroup>
          </vsLayoutGroup>
  
        </vsLayoutGroup><!-- Ende col-xl-6 -->
  
      </vsLayoutGroup>
 
    </ng-template>
    <ng-template #elseBlockAntrag>
          
    <vsLayoutGroup class="col-xl-12" Orientation="horizontal" isPanel="true">

      <!-- 50 % der Breite -->
      <vsLayoutGroup class="col-xl-6" Orientation="vertical">
        <vsLayoutGroup class="col" Orientation="horizontal">
          <label class="vsInputLabel">{{'application.commit.application.application_kind'| translate}}</label>
          <vsLayoutGroup class="col">
            <div *ngIf="dataModel.antrag.Antr_ID_Art==='I'" class="vsFormTextDiv">{{'application_kind.heading_3'| translate}}</div>
            <div *ngIf="dataModel.antrag.Antr_ID_Art==='U'" class="vsFormTextDiv">{{'application_kind.heading_1'| translate}}</div>
            <div *ngIf="dataModel.antrag.Antr_ID_Art==='E'" class="vsFormTextDiv">{{'application_kind.heading_2'| translate}}</div>
            <div *ngIf="dataModel.antrag.Antr_ID_Art==='G'" class="vsFormTextDiv">{{'application_kind.heading_4'| translate}}</div>
            <div *ngIf="dataModel.antrag.Antr_ID_Art==='GA'" class="vsFormTextDiv">{{'application_kind.heading_5'| translate}}</div>
            <div *ngIf="dataModel.antrag.Antr_ID_Art==='EA'" class="vsFormTextDiv">{{'application_kind.heading_6'| translate}}</div>
          </vsLayoutGroup>
        </vsLayoutGroup>
      </vsLayoutGroup>

      <!-- 50 % der Breite -->
      <vsLayoutGroup class="col-xl-6" Orientation="vertical">

        <vsLayoutGroup class="col" Orientation="horizontal">
          <label class="vsInputLabel">{{'application.commit.application.application_date'| translate}}</label>
          <vsLayoutGroup class="col">
            <div class="vsFormTextDiv">{{antrDatum}}</div>
          </vsLayoutGroup>
        </vsLayoutGroup>

      </vsLayoutGroup>

      <div style="margin-top: 1rem; margin-bottom: 0.25rem;">
        <!--<p-button (click)="actionStepNext()">Weiter</p-button>-->
        <!-- <button pButton label="{{'application.button_edit'| translate}}" (click)="doBearb_Antrag()" class="vsElternButton" [attr.aria-label]="'application.commit.buttonLabelsAria.edit_application'| translate"></button> -->
      </div>

    </vsLayoutGroup><!--Ende Angaben zur Fahrt-->

    <vsLayoutGroup class="col" Orientation="vertical">
      <h4>{{'application.commit.student.heading'| translate}}</h4>
    </vsLayoutGroup>

    <vsLayoutGroup class="col" Orientation="horizontal" isPanel="true">

      <!-- 50 % der Breite -->
      <vsLayoutGroup class="col-xl-6" Orientation="vertical">

        <vsLayoutGroup class="col" Orientation="horizontal">
          <label class="vsInputLabel" width="180px">{{'application.commit.student.student'| translate}}</label>
          <vsLayoutGroup class="col">
            <div class="vsFormTextDiv">{{dataModel.antrag?.Azubi_Anrede_Kuerzel}} {{dataModel.antrag?.Azubi_Nachname}}, {{dataModel.antrag?.Azubi_Vorname}}</div>
          </vsLayoutGroup>
        </vsLayoutGroup>

        <vsLayoutGroup class="col" Orientation="horizontal">
          <label class="vsInputLabel">{{'application.commit.student.date_birth'| translate}}</label>
          <vsLayoutGroup class="col">
            <div class="vsFormTextDiv">{{gebDatumAzubi}}</div>
          </vsLayoutGroup>
        </vsLayoutGroup>

      </vsLayoutGroup><!--Ende col-xl-6-->

              <!-- 50 % der Breite -->
      <vsLayoutGroup class="col-xl-6" Orientation="vertical">

          <vsLayoutGroup class="col" Orientation="horizontal">
              <label class="vsInputLabel">{{'general.address.address'| translate}}</label>
            <vsLayoutGroup class="col">
              <div class="vsFormTextDiv">{{dataModel.antrag?.Azubi_Strasse}} {{dataModel.antrag?.Azubi_HausNr}} {{dataModel.antrag?.Antr_Bescheid_Text_Zusatz_01}}</div>
              <div class="vsFormTextDiv">{{dataModel.antrag?.Azubi_PLZ}} {{dataModel.antrag?.Azubi_Ort}}  {{dataModel.antrag?.Azubi_Ortsteil}}</div>
            </vsLayoutGroup>
          </vsLayoutGroup>

          <vsLayoutGroup class="col" Orientation="horizontal">
            <label class="vsInputLabel">{{'general.contact.phone'| translate}}</label>
            <vsLayoutGroup class="col">
              <div class="vsFormTextDiv">{{dataModel.antrag?.Azubi_Phone}}</div>
            </vsLayoutGroup>
          </vsLayoutGroup>

          <vsLayoutGroup class="col" Orientation="horizontal">
            <label class="vsInputLabel">{{'general.contact.mobile'| translate}}</label>
            <vsLayoutGroup class="col">
              <div class="vsFormTextDiv">{{dataModel.antrag?.Azubi_Mobile}}</div>
            </vsLayoutGroup>
          </vsLayoutGroup>

          <vsLayoutGroup class="col" Orientation="horizontal">
            <label class="vsInputLabel">{{'general.contact.email'| translate}}</label>
            <vsLayoutGroup class="col">
              <div class="vsFormTextDiv">{{dataModel.antrag?.Azubi_eMail}}</div>
            </vsLayoutGroup>
          </vsLayoutGroup>

      </vsLayoutGroup>

      <div style="margin-top: 1rem; margin-bottom: 0.25rem;">
        <!-- <button pButton label="{{'application.button_edit'| translate}}" (click)="doBearb_Student()" class="vsElternButton" [attr.aria-label]="'application.commit.buttonLabelsAria.edit_student'| translate"></button> -->
      </div>
    </vsLayoutGroup>

    <vsLayoutGroup class="col" Orientation="vertical">
      <h4>{{'application.commit.legal_rep.heading'| translate}}</h4>
    </vsLayoutGroup>

    <vsLayoutGroup class="col" Orientation="horizontal" isPanel="true">

      <!-- 50 % der Breite -->
      <vsLayoutGroup class="col-xl-6" Orientation="vertical">

        <vsLayoutGroup class="col" Orientation="horizontal">
          <label class="vsInputLabel" width="180px">{{'application.commit.legal_rep.heading'| translate}}</label>
          <vsLayoutGroup class="col">
            <div class="vsFormTextDiv">{{dataModel.antrag?.GesVertr_Anrede_Kuerzel}} {{dataModel.antrag?.GesVertr_Titel_Kuerzel}} {{dataModel.antrag?.GesVertr_Nachname}}, {{dataModel.antrag?.GesVertr_Vorname}}</div>
          </vsLayoutGroup>
        </vsLayoutGroup>

        <vsLayoutGroup class="col" Orientation="horizontal">
          <label class="vsInputLabel">{{'application.commit.legal_rep.date_birth'| translate}}</label>
          <vsLayoutGroup class="col">
            <div class="vsFormTextDiv">{{gebDatumGesVertr}}</div>
          </vsLayoutGroup>
        </vsLayoutGroup>

      </vsLayoutGroup><!--Ende col-xl-6-->

              <!-- 50 % der Breite -->
      <vsLayoutGroup class="col-xl-6" Orientation="vertical">

          <vsLayoutGroup class="col" Orientation="horizontal">
              <label class="vsInputLabel">{{'general.address.address'| translate}}</label>
            <vsLayoutGroup class="col">
              <div class="vsFormTextDiv">{{dataModel.antrag?.GesVertr_Strasse}} {{dataModel.antrag?.GesVertr_HausNr}} {{dataModel.antrag?.GesVertr_Name_Zusatz_1}}</div>
              <div class="vsFormTextDiv">{{dataModel.antrag?.GesVertr_PLZ}} {{dataModel.antrag?.GesVertr_Ort}} {{dataModel.antrag?.GesVertr_Ortsteil}} </div>
            </vsLayoutGroup>
          </vsLayoutGroup>

          <vsLayoutGroup class="col" Orientation="horizontal">
            <label class="vsInputLabel">{{'general.contact.phone'| translate}}</label>
            <vsLayoutGroup class="col">
              <div class="vsFormTextDiv">{{dataModel.antrag?.GesVertr_Phone}}</div>
            </vsLayoutGroup>
          </vsLayoutGroup>

          <vsLayoutGroup class="col" Orientation="horizontal">
            <label class="vsInputLabel">{{'general.contact.mobile'| translate}}</label>
            <vsLayoutGroup class="col">
              <div class="vsFormTextDiv ">{{dataModel.antrag?.GesVertr_Mobile}}</div>
            </vsLayoutGroup>
          </vsLayoutGroup>

          <vsLayoutGroup class="col" Orientation="horizontal">
            <label class="vsInputLabel">{{'general.contact.email'| translate}}</label>
            <vsLayoutGroup class="col">
              <div class="vsFormTextDiv ">{{dataModel.antrag?.GesVertr_eMail}}</div>
            </vsLayoutGroup>
          </vsLayoutGroup>

      </vsLayoutGroup>

      <div style="margin-top: 1rem; margin-bottom: 0.25rem;">
        <!-- <button pButton label="{{'application.button_edit'| translate}}" (click)="doBearb_Student()" class="vsElternButton" [attr.aria-label]="'application.commit.buttonLabelsAria.edit_legal_rep'| translate"></button> -->
      </div>
    </vsLayoutGroup>

    <!--Beschreibung -->

    <vsLayoutGroup class="col" Orientation="vertical">
      <h4>{{'application.commit.school.heading'| translate}}</h4>
    </vsLayoutGroup>

    <vsLayoutGroup class="col" Orientation="horizontal" isPanel="true">

      <!-- 100 % der Breite -->
      <vsLayoutGroup class="col-xl-6">

        <vsLayoutGroup class="col" Orientation="horizontal">
          <label class="vsInputLabel">{{'application.commit.school.school_name'| translate}}</label>
          <vsLayoutGroup class="col">
            <div class="vsFormTextDiv">{{dataModel.antrag?.SchuleAntr_Name}}</div>
          </vsLayoutGroup>
        </vsLayoutGroup>

        <vsLayoutGroup class="col" Orientation="horizontal">
          <label class="vsInputLabel">{{'application.commit.school.class'| translate}}</label>
          <vsLayoutGroup class="col">
            <div class="vsFormTextDiv" style="overflow-wrap: break-word;">{{dataModel.antrag?.Antr_Klasse_Beg_Nr}}{{dataModel.antrag?.Antr_Klasse_Beg_Zusatz}}</div>
          </vsLayoutGroup>
        </vsLayoutGroup>

      </vsLayoutGroup><!--Ende col-xl-6-->

      <vsLayoutGroup class="col-xl-6">

        <vsLayoutGroup class="col" Orientation="horizontal">
          <label class="vsInputLabel">{{'application.commit.school.school_kind'| translate}}</label>
          <vsLayoutGroup class="col">
            <div class="vsFormTextDiv">{{schulart?.Art_Name}}</div>
          </vsLayoutGroup>
        </vsLayoutGroup>

        <vsLayoutGroup class="col" Orientation="horizontal">
        </vsLayoutGroup>

      </vsLayoutGroup><!--Ende col-xl-6-->

      <div style="margin-top: 1rem; margin-bottom: 0.25rem;">
        <!-- <button pButton label="{{'application.button_edit'| translate}}" (click)="doBearb_Schule()" class="vsElternButton" [attr.aria-label]="'application.commit.buttonLabelsAria.edit_school'| translate"></button> -->
      </div>

    </vsLayoutGroup>

    <vsLayoutGroup *ngIf="dataModel.antrag?.Antr_AusbStaett_Beruf_Name1" class="col" Orientation="vertical">
      <h4>{{'application.school_company.heading_training_company'| translate}}:</h4>
    </vsLayoutGroup>

    <vsLayoutGroup *ngIf="dataModel.antrag?.Antr_AusbStaett_Beruf_Name1" class="col-xl-12" Orientation="horizontal" isPanel="true">

      <!-- 50 % der Breite -->
      <vsLayoutGroup class="col-xl-6" Orientation="vertical">
        <vsLayoutGroup class="col" Orientation="horizontal">
          <label class="vsInputLabel">{{'application.school_company.training_company'| translate}}:</label>
          <vsLayoutGroup class="col">
            <div class="vsFormTextDiv">{{dataModel.antrag?.Antr_AusbStaett_Beruf_Name1}}</div>
          </vsLayoutGroup>
        </vsLayoutGroup>

        <vsLayoutGroup class="col" Orientation="horizontal">
          <label class="vsInputLabel">{{'general.address.address'| translate}}:</label>
          <vsLayoutGroup class="col">
            <div class="vsFormTextDiv">{{dataModel.antrag?.Antr_AusbStaett_Beruf_Strasse}} {{dataModel.antrag?.Antr_AusbStaett_Beruf_HausNr}}, {{dataModel.antrag?.Antr_AusbStaett_Beruf_Name_Zu1}}</div>
              <div class="vsFormTextDiv">{{dataModel.antrag?.Antr_AusbStaett_Beruf_PLZ}} {{dataModel.antrag?.Antr_AusbStaett_Beruf_Ort}} {{dataModel.antrag?.Antr_AusbStaett_Beruf_Ortsteil}}</div>
          </vsLayoutGroup>
        </vsLayoutGroup>

      </vsLayoutGroup><!-- Ende col-xl-6 -->

    </vsLayoutGroup>


    <vsLayoutGroup *ngIf="dataModel.antrag.Antr_ID_Art==='E'" class="col" Orientation="vertical">
      <h4>{{'application.reimbursement.heading'| translate}}</h4>
    </vsLayoutGroup>

    <vsLayoutGroup *ngIf="dataModel.antrag.Antr_ID_Art==='E'" class="col" Orientation="horizontal" isPanel="true">

      <!-- 100 % der Breite -->
      <vsLayoutGroup class="col-xl-12">

        <table id="mockTableErstatt">
          <tr>
            <th scope="col">{{'application.reimbursement.table_heading_item'| translate}}</th>
            <th style="text-align: center;"   scope="col">{{'application.reimbursement.table_heading_amount'| translate}}</th>
            <th style="text-align: right;"    scope="col">{{'application.reimbursement.table_heading_item_price'| translate}}</th>
            <th style="text-align: right;"    scope="col">{{'application.reimbursement.table_heading_distance'| translate}}</th>
            <th style="text-align: right;"    scope="col">{{'application.reimbursement.table_heading_total_price'| translate}}</th>
          </tr>
          <tr *ngFor="let item of lookupPosList">
          <td>{{item?.Obj_Name}}</td>
          <td style="text-align: center;">{{item?.Pos_Anzahl}}</td>
          <td style="text-align: right;">{{item?.xPos_Betrag_Einzel}} €</td>
          <td style="text-align: right;">{{item?.xPos_Strecke_km_Tag}}</td>
          <td style="text-align: right;">{{item?.xPos_Betrag_Gesamt}} €</td>
        </tr>
        </table>

        <vsLayoutGroup class="col" Orientation="horizontal">
        </vsLayoutGroup>

      </vsLayoutGroup><!--Ende col-xl-12-->

      <div style="margin-top: 1rem; margin-bottom: 0.25rem;">
        <!-- <button pButton label="{{'application.button_edit'| translate}}" (click)="doBearb_Erstatt()" class="vsElternButton" [attr.aria-label]="'application.commit.buttonLabelsAria.edit_reimburse'| translate"></button> -->
      </div>

    </vsLayoutGroup>


    <vsLayoutGroup *ngIf="dataModel.antrag.Antr_ID_Art==='I'" class="col" Orientation="vertical">
      <h4>{{'application.individual.heading'| translate}}</h4>
    </vsLayoutGroup>

    <vsLayoutGroup *ngIf="dataModel.antrag.Antr_ID_Art==='I'" class="col" Orientation="horizontal" isPanel="true">

      <!-- 100 % der Breite -->
      <vsLayoutGroup class="col-xl-6 row">
        <vsLayoutGroup class="col" Orientation="horizontal">
        <table id="mockTableIndiv" style="margin-left: -6px;">
          <tr>
            <th> </th>
            <th>{{'application.individual.table_heading_monday'| translate}}</th>
            <th>{{'application.individual.table_heading_tuesday'| translate}}</th>
            <th>{{'application.individual.table_heading_wednesday'| translate}}</th>
            <th>{{'application.individual.table_heading_thursday'| translate}}</th>
            <th>{{'application.individual.table_heading_friday'| translate}}</th>
            <th>{{'application.individual.table_heading_saturday'| translate}}</th>
            <th>{{'application.individual.table_heading_sunday'| translate}}</th>
          </tr>
          <tr>
            <th>{{'application.individual.table_heading_school_start'| translate}}</th>
            <td>{{this.dataIndiv?.pickupHome.Time_PickupHome_Monday}}</td>
            <td>{{this.dataIndiv?.pickupHome.Time_PickupHome_Tuesday}}</td>
            <td>{{this.dataIndiv?.pickupHome.Time_PickupHome_Wednesday}}</td>
            <td>{{this.dataIndiv?.pickupHome.Time_PickupHome_Thursday}}</td>
            <td>{{this.dataIndiv?.pickupHome.Time_PickupHome_Friday}}</td>
            <td>{{this.dataIndiv?.pickupHome.Time_PickupHome_Saturday}}</td>
            <td>{{this.dataIndiv?.pickupHome.Time_PickupHome_Sunday}}</td>
          </tr>
          <tr>
            <th>{{'application.individual.table_heading_school_end'| translate}}</th>
            <td>{{this.dataIndiv?.pickupSchool.Time_PickupSchool_Monday}}</td>
            <td>{{this.dataIndiv?.pickupSchool.Time_PickupSchool_Tuesday}}</td>
            <td>{{this.dataIndiv?.pickupSchool.Time_PickupSchool_Wednesday}}</td>
            <td>{{this.dataIndiv?.pickupSchool.Time_PickupSchool_Thursday}}</td>
            <td>{{this.dataIndiv?.pickupSchool.Time_PickupSchool_Friday}}</td>
            <td>{{this.dataIndiv?.pickupSchool.Time_PickupSchool_Saturday}}</td>
            <td>{{this.dataIndiv?.pickupSchool.Time_PickupSchool_Sunday}}</td>
          </tr>
        </table>
        </vsLayoutGroup>

        <vsLayoutGroup class="col" Orientation="horizontal">
          <label class="vsInputLabel">{{'application.individual.wheelchair'| translate}}:</label>
          <vsLayoutGroup class="col">
            <div *ngIf="this.dataIndiv.additInfo.Wheelchair" class="vsFormTextDiv">{{'application.individual.wheelchair_yes'| translate}}</div>
            <div *ngIf="!this.dataIndiv.additInfo.Wheelchair" class="vsFormTextDiv">{{'application.individual.wheelchair_no'| translate}}</div>
          </vsLayoutGroup>
        </vsLayoutGroup>

        <vsLayoutGroup class="col" Orientation="horizontal">
          <label class="vsInputLabel">{{'application.individual.accomp_person'| translate}}:</label>
          <vsLayoutGroup class="col">
            <div *ngIf="this.dataIndiv?.additInfo.Assistant" class="vsFormTextDiv">{{'application.individual.accomp_person_yes'| translate}}</div>
            <div *ngIf="!this.dataIndiv?.additInfo.Assistant" class="vsFormTextDiv">{{'application.individual.accomp_person_no'| translate}}</div>
          </vsLayoutGroup>
        </vsLayoutGroup>
        
        <vsLayoutGroup class="col" Orientation="horizontal">
          <label class="vsInputLabel">{{'application.individual.add_info'| translate}}:</label>
          <vsLayoutGroup class="col">
            <div *ngIf="this.dataIndiv?.additInfo.AdditInfoMsg" class="vsFormTextDiv">{{this.dataIndiv.additInfo.AdditInfoMsg}}</div>
            <div *ngIf="!this.dataIndiv?.additInfo.AdditInfoMsg" class="vsFormTextDiv">{{'application.individual.add_info_no'| translate}}</div>
          </vsLayoutGroup>
        </vsLayoutGroup>

        <vsLayoutGroup class="col" Orientation="horizontal">
        </vsLayoutGroup>

      </vsLayoutGroup><!--Ende col-xl-6-->

      <div style="margin-top: 1rem; margin-bottom: 0.25rem;">
        <!-- <button pButton label="{{'application.button_edit'| translate}}" (click)="doBearb_Indiv()" class="vsElternButton" [attr.aria-label]="'application.commit.buttonLabelsAria.edit_indiv'| translate"></button> -->
      </div>

    </vsLayoutGroup>


    <vsLayoutGroup class="col" Orientation="vertical">
      <h4>{{'application.commit.bank.heading'| translate}}:</h4>
    </vsLayoutGroup>

    <vsLayoutGroup class="col-xl-12" Orientation="horizontal" isPanel="true">

      <!-- 50 % der Breite -->
      <vsLayoutGroup class="col-xl-6" Orientation="vertical">

        <vsLayoutGroup class="col" Orientation="horizontal">
          <label class="vsInputLabel">{{'application.commit.bank.account_owner'| translate}}:</label>
          <vsLayoutGroup class="col">
            <div class="vsFormTextDiv" >{{dataModel.antrag?.Bank_Inhaber_Name}}</div>
          </vsLayoutGroup>
        </vsLayoutGroup>

        <vsLayoutGroup class="col" Orientation="horizontal">
          <label class="vsInputLabel">{{'application.commit.bank.IBAN'| translate}}:</label>
          <vsLayoutGroup class="col">
            <div class="vsFormTextDiv">{{dataModel.antrag?.Bank_IBAN}}</div>
          </vsLayoutGroup>
        </vsLayoutGroup>

        <vsLayoutGroup class="col" Orientation="horizontal">
          <label class="vsInputLabel">{{'application.commit.bank.bank_name'| translate}}:</label>
          <vsLayoutGroup class="col">
            <div class="vsFormTextDiv">{{dataModel.antrag?.BankVerb_Institut_Name}}</div>
          </vsLayoutGroup>
        </vsLayoutGroup>

      </vsLayoutGroup><!-- Ende col-xl-6 -->

      <!-- 50 % der Breite -->
      <vsLayoutGroup class="col-xl-6" Orientation="vertical">

        <vsLayoutGroup class="col" Orientation="horizontal">
        
        </vsLayoutGroup>

        <vsLayoutGroup class="col" Orientation="horizontal">
         
        </vsLayoutGroup>

        <vsLayoutGroup class="col" Orientation="horizontal">
        
        </vsLayoutGroup>

        <vsLayoutGroup class="col" Orientation="horizontal">
          
        </vsLayoutGroup>

      </vsLayoutGroup><!--Ende col-xl-6 -->

      <div style="margin-top: 1rem; margin-bottom: 0.25rem;">
        <!--<p-button (click)="actionStepNext()">Weiter</p-button>-->
        <!-- <button pButton label="{{'application.button_edit'| translate}}" (click)="doBearb_Bank()" class="vsElternButton" [attr.aria-label]="'application.commit.buttonLabelsAria.edit_bank'| translate"></button> -->
      </div>

    </vsLayoutGroup><!--Ende col-xl-12 Panel -->
    
    
    <vsLayoutGroup class="col" Orientation="vertical">
      <h4>{{'application.commit.attachment.heading'| translate}}:</h4>
    </vsLayoutGroup>

    <vsLayoutGroup class="col-xl-12" Orientation="horizontal" isPanel="true">

      <!-- <vsLayoutGroup class="col">
        <div class='row p-inputgroup vsInputGroup' style="width: 100%; margin: 0!important;">
          <div style="width: inherit; padding: 0!important;"> -->


            <!-- <vsInputChips FrmCtlName='Attachment' label="{{'application.commit.attachment.attachments'| translate}}" ></vsInputChips> -->


          <!-- </div>
        </div>
     </vsLayoutGroup> -->

     <vsLayoutGroup class="col" Orientation="vertical">

        <!-- Erste Zeile mit überschriften -->

        <div class="row vsGutterWidth-2 vsMb-2">
          <div class="col-2"><b>{{'application.documents.date'| translate}}:</b></div>

          <div class="col-2"><b>{{'application.documents.time'| translate}}:</b></div>

          <div class="col-8"><b>{{'application.documents.download'| translate}}:</b></div>

        </div>


        <div *ngFor="let item of lookupVorgangFiles; let i = index" class="row vsGutterWidth-2">
        
          <div class="col-2">{{lookupVorgangFiles[i].Vorgang.Datum}}</div>

          <div class="col-2">{{lookupVorgangFiles[i].Vorgang.Uhrzeit}}</div>
        
          <div class="col-8" style="display: flex;">

              <button *ngFor="let item of lookupVorgangFiles[i].Vorgang.Files" pButton 
              label="&quot;{{item.UploadFileName}}&quot;" (click)="docDownload(item)" 
              class="vsElternButton vsMb-2" style="margin-right: 0.425rem;"></button>

          </div>

        </div>

    </vsLayoutGroup>


      <div style="margin-top: 1rem; margin-bottom: 0.25rem;">
        <!-- <button pButton label="{{'application.button_edit'| translate}}" (click)="doBearb_Doc()" class="vsElternButton" [attr.aria-label]="'application.commit.buttonLabelsAria.edit_attachment'| translate"></button> -->
      </div>

    </vsLayoutGroup>
    
    
    <vsLayoutGroup *ngIf="dataModel.antrag.antrag_Art==='U'" class="col" Orientation="vertical">
      <h4>{{'application.commit.spec_circumstances.heading'| translate}}:</h4>
    </vsLayoutGroup>

    <vsLayoutGroup *ngIf="dataModel.antrag.antrag_Art==='U'" class="col-xl-12" Orientation="horizontal" isPanel="true">

      <!-- 100 % der Breite -->
      <vsLayoutGroup class="col-xl-12" Orientation="vertical">

        <vsLayoutGroup class="col" Orientation="horizontal">
          <label class="vsInputLabel">{{'application.commit.spec_circumstances.explanation'| translate}}:</label>
          <vsLayoutGroup class="col">
            <div class="vsFormTextDivParagraph" >{{dataModel.antrag.antr_Bes_Erlaeut}}</div>
          </vsLayoutGroup>
        </vsLayoutGroup>

        <div style="margin-top: 1rem; margin-bottom: 0.25rem;">
          <!-- <button pButton label="{{'application.button_edit'| translate}}" (click)="doBearb_Besond()" class="vsElternButton" [attr.aria-label]="'application.commit.buttonLabelsAria.edit_spec_circumstances'| translate"></button> -->
        </div>

      </vsLayoutGroup><!-- Ende col-xl-12 -->

    </vsLayoutGroup><!--Ende col-xl-12 Panel -->

    </ng-template>
    



  </form><!-- Formulare Ende-->

  <div style="margin-top: 3rem; margin-bottom: 1.5rem;">
    
    <button pButton label="{{'application.button_back'| translate}}"              (click)="actionStepPrev()"  class="vsElternButton vsBack" style="margin-right: 10px;"></button>
    <!-- <button pButton label="{{'application.button_application_send'| translate}}"  (click)="dataSave()"        class="vsElternButton" [disabled]="dataModel.antrag.isSubmitted"></button> -->
  </div>

</div><!--container-xxl Ende-->

<!-- Footer --------------------------------------------------------------------------------------->
<appFooter></appFooter>

<!-- Messages (Toasts) ---------------------------------------------------------------------------->

<p-toast></p-toast>

  <!-- <pre>{{Summary}}</pre> -->






<vsViewData>
  [ViewProperties]="ViewProperties"
</vsViewData>

<!-- Header --------------------------------------------------------------------------------------->

<appHeader></appHeader>


<div *ngIf = "true && !globalResources.UserProfile.IsLoggedIn" class="sysViewUserRegSelection">


<!-- Main Content --------------------------------------------------------------------------------->


  <div class="container-xxl akMainContent vsMainContentContainer">

  <form [formGroup]="this.editForm">

        <vsLayoutGroup class="col" Orientation="horizontal">

          <!-- Platzhalter links-->
          <vsLayoutGroup class="col-xl-4 col-lg-4 col-md-2 col-sm-0">
          </vsLayoutGroup>

          <div class="col-xl-4 col-lg-4 col-md-8 col-sm-12 vsLoginSelect">
            <fieldset>

              <legend>
                <div class="col-12" style="margin-bottom: 30px; justify-content: center;">
                  <!-- <h4 class="vsElternLoginTitle">{{'register.choose_registration_method_heading'| translate}}</h4> -->
                  <h4 class="vsElternLoginTitle">{{'register.choose_registration_method_heading'| translate}}</h4>
                </div>
              </legend>

              <div class="vsLoginRadioButtonGroup">
                <vsInputRadioButton FrmCtlName="radioButton" label="{{'register.link_account_registration_heading'| translate}}" Value="A" inputId="opt2"></vsInputRadioButton>
                <div [innerHtml]="'register.link_account_registration_text'| translate"></div>
                <!-- <div>Registrierung mit Verknüpfung zu vorhandenen Anträgen.</div> -->
                <!-- <div>Sie haben alte Anträge in Papierform aber noch keinen Webaccount?. Hier sind Sie richtig.</div> -->
              </div>
    
              <div class="vsLoginRadioButtonGroup">
                <vsInputRadioButton FrmCtlName="radioButton" label="{{'register.regular_registration_heading'| translate}}" Value="R" inputId="opt1">
                </vsInputRadioButton>
                <div [innerHtml]="'register.regular_registration_text'| translate"></div>
                <!-- <div>Neue Registrierung</div> -->
              </div>

            </fieldset>

            <div *ngIf="this.editForm.controls['radioButton'].value === 'R'" class="col">

              <div *ngIf="!registrationInProgess" style="margin-bottom: 30px; margin-top:30px; display: flex;">
  
                <button pButton label="{{'application.button_back'| translate}}" (click)="actionStepPrev()" class="vsElternButton vsBack" style="margin-right: 10px;"></button>

                <button pButton type="button"
                        label="{{'application.button_forward'| translate}}"
                        (click)="actionStepRegister()"
                        class="vsElternButton vsForward">
                </button>
       
              </div>	<!-- Ende col-sm-12 -->
              
              <div *ngIf="registrationInProgess" style="margin-bottom: 30px; margin-top:30px; display: flex;">
                <button pButton label="{{'application.button_back'| translate}}" (click)="actionStepPrev()" class="vsElternButton vsBack" style="margin-right: 10px;"></button>
                <button pButton
                        disabled
                        type="button"
                        label="{{'register.button_register'| translate}}"
                        (click)="actionStepRegister()"
                        class="vsElternButton vsForward">
                </button>
              </div>	<!-- Ende col-sm-12 -->
  
            </div>
  
            <div *ngIf="this.editForm.controls['radioButton'].value === 'A'" class="col">
  
              <div *ngIf="!registrationInProgess" style="margin-bottom: 30px; margin-top:30px; display: flex;">
  
                <button pButton label="{{'application.button_back'| translate}}" (click)="actionStepPrev()" class="vsElternButton vsBack" style="margin-right: 10px;"></button>
            
                <button pButton type="button"
                        label="{{'application.button_forward'| translate}}"
                        (click)="actionStepLinkAccount()"
                        class="vsElternButton vsForward">
                </button>
       
              </div>	<!-- Ende col-sm-12 -->
          
              <div *ngIf="registrationInProgess" style="margin-bottom: 30px; margin-top:30px; display: flex;">
                <button pButton label="{{'application.button_forward'| translate}}" (click)="actionStepPrev()" class="vsElternButton vsBack" style="margin-right: 10px;"></button>
                <button pButton
                        disabled
                        type="button"
                        label="{{'register.button_register'| translate}}"
                        (click)="actionStepLinkAccount()"
                        class="vsElternButton vsForward">
                </button>
              </div>	<!-- Ende col-sm-12 -->
  
            </div>

          </div>



          <!-- Platzhalter rechts-->
          <vsLayoutGroup class="col-xl-4 col-lg-4 col-md-2 col-sm-0">
          </vsLayoutGroup>

        </vsLayoutGroup>



      </form>

  </div><!-- Ende container-xxl -->

</div>  <!-- Ende ngif UserProfile.IsLoggedIn -->

<!-- Dialoge--------------------------------------------------------------------------------------->

<p-dialog header="{{'dialog.error_fill_heading'| translate}}" [(visible)]="displayDialogError" [baseZIndex]="10000" class="vsDialog vsDanger vsRequired">
  <p [innerHtml]="'dialog.error_fill_text'| translate"></p>
  <ng-template pTemplate="footer">
      <button pButton pRipple type="button" (click)="displayDialogError=false" label="{{'dialog.error_fill_button_ok'| translate}}" class="vsToolbarItem vsToolbarItemOK vsMb-2">
        <span>
          <img alt="close" src="/assets/img/Icons/vs-check-circle.svg" />
        </span>
      </button>
  </ng-template>
</p-dialog>

<!-- Text zur Confirmation-Page verlagert. -->
<!-- <p-dialog header="{{'register.text_registration_processing'| translate}}" [(visible)]="displayDialogRegInProgress" [baseZIndex]="10000" class="vsDialog vsSuccess vsRequired">
  <p [innerHtml]="'register.text_registration_confirmation'| translate"></p>
  <p [innerHtml]="'register.text_registration_activation'| translate"></p>
  <ng-template pTemplate="footer">
      <button pButton pRipple type="button" (click)="displayDialogRegInProgress=false" label="{{'dialog.error_fill_button_ok'| translate}}" class="vsToolbarItem vsToolbarItemOK vsMb-2">
        <span>
          <img alt="close" src="/assets/img/Icons/vs-check-circle.svg" />
        </span>
      </button>
  </ng-template>
</p-dialog> -->

<p-dialog header="{{'dialog.error_fill_heading'| translate}}" [(visible)]="displayDialogSameEmail" [baseZIndex]="10000" class="vsDialog vsDanger vsRequired">
  <p [innerHtml]="'register.text_same_email'| translate"></p>
  <ng-template pTemplate="footer">
      <button pButton pRipple type="button" (click)="displayDialogSameEmail=false" label="{{'dialog.error_fill_button_ok'| translate}}" class="vsToolbarItem vsToolbarItemOK vsMb-2">
        <span>
          <img alt="close" src="/assets/img/Icons/vs-check-circle.svg" />
        </span>
      </button>
  </ng-template>
</p-dialog>


  <!-- Spinner bei längerer Dauer -->
  <p-dialog [(visible)]="displaySpinner" [modal]="true" cloeseable="false" [closeOnEscape]="false" [baseZIndex]="10000" class="vsDialogSpinnerContainer" [style]="{width:'150px', height:'250px'}">
    <p-progressSpinner
    [style]             = "{width: '100px', height: '100px'}"
    styleClass          = "vsDialogSpinner"
    ></p-progressSpinner>
  </p-dialog>


<!-- Footer --------------------------------------------------------------------------------------->


<appFooter></appFooter>



<!-- Messages (Toasts) ---------------------------------------------------------------------------->


<p-toast></p-toast>



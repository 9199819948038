//--------------------------------------------------------------------------------------------------
//  AMIS7school - Externes Portal: Eltern
//
//  Domain:   School
//  Title:    Grundantrag Schülerbeförderung (Edit View)
//  Notes:    MOCKUP
//
//
//  H I S T O R Y
//
//  2021-08-05  TC   Initial Version
//--------------------------------------------------------------------------------------------------

import {  Component, ViewChild
        , OnInit
        , ComponentFactoryResolver,
        ElementRef}         from '@angular/core';

import { Router }                           from '@angular/router';
import { FormGroup, FormControl, Validators }           from '@angular/forms';

import  * as schoolDataModel                from 'AMIS7school_Portal_Eltern/src/app/school/schoolDataModel.service';


// PrimeNG
import { MenuItem }                         from 'primeng/api';
import { MessageService }                   from 'primeng/api';


// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import * as vsView                          from '@vsLib/View/components/vsView/vsView.component';
import * as vsViewData                      from '@vsLib/View/components/vsViewData/vsViewData.component';
import * as vsGlobalResources               from '@vsLib/Services/vsGlobalResources.service';
import * as vsTabControl                    from '@vsLib/Components/vsTab/vsTabControl.component';

import { TvsGetType, TvsHTTPMethod, TvsHttpServiceComponent }          from '@vsLib/Services/vsHttp.service.component';


// AMIS7core
import * as AMIS7coreConst                  from '@AMIS7core/$Common/comConst';
import { DatePipe, formatDate }             from '@angular/common';
// import { asLiteral }                        from '@angular/compiler/src/render3/view/util';
import { HttpClient }                       from '@angular/common/http';


import { TdlgDoc, TFileData }               from 'AMIS7school_Portal_Eltern/src/app/school/dlgDoc.component';



@Component({
  selector:       'schoolViewAntragEdit_Commit',
  templateUrl:  './schoolViewAntragEdit_Commit.component.html',
  styleUrls:   ['./schoolViewAntragEdit_Commit.component.scss']
})
export class TschoolViewAntragEdit_Commit extends vsViewData.TvsViewData implements OnInit {

  //====== Properties (PRIVATE)

  // ./.

  //====== Properties (PUBLIC)

  // ./.

  public      editForm:                     FormGroup               = new FormGroup({});
  public      dataObject:                   any;

  public      antrID:                       string;

  public      antr_ID_CRM_Vorf:             string;

 //$REV MP 2023-01-19: neue Variable antragsArt hinzugefügt, Prüfung von dataModel ergab manchmal "null"
  public      antragsArt:                   string;

  private     erstattPos:                   any[];

  //$REV MP 2023-07-20: neue Variable besUmst hinzugefügt, Prüfung von dataModel ergab manchmal "null"
  public      besUmstText:                  string;
  public      besUmst:                      any;

  public      Files:                        TFileData[] = [];

  public      KennTransportOptions:         boolean = false;

  public      KennApplicationReason:        boolean = false;

  public      kennDisplayStudentAddress:    boolean = true;

  public      displayCancelDialog:          boolean   = false;

  @ViewChild('explanation_header')  explanation_header: ElementRef;
  @ViewChild('explanation_text')    explanation_text:   ElementRef;

  //====== Constructor

  constructor(protected router:                     Router,
              public    globalResources:            vsGlobalResources.TvsGlobalResources
            , public    dataModel:                  schoolDataModel.TschoolDataModel
            , protected messageService:             MessageService
            , protected HttpServiceComponent:       TvsHttpServiceComponent
            , protected httpClient:                 HttpClient
  ) {
    super(router, globalResources, HttpServiceComponent);

    this.ViewProperties.Instance        = this;
    this.ViewProperties.ViewTitle       = 'Prüfen & Bestätigen';

    this.ViewProperties.ProgFuncID      = null;
    this.ViewProperties.DataSourceID    = 'schoolDOStammAntrag';
    this.ViewProperties.QueryFields     = [];


  } // constructor



  //====== Methods: NG event handler


  ngOnInit(): void {

    //REV MP 2024-05-24: Schüleradresse kann in der Zusammenfassung des Erstattungsantrages generell versteckt werden.
    if(this.dataModel.antrag_Art === 'E' || this.dataModel.antrag_Art === 'EA') {
      this.kennDisplayStudentAddress = false;
    }

    //REV MP 2023: Transportoptionen sollen beim Erstattungsantrag nicht angezeigt werden. Da im DataModel auf true gesetzt, kam es auf der Commit-Seite zu Fehlern.
    if(vsCommon.vsVarAssigend(this.dataModel.KennTransportOptions)) {
      this.KennTransportOptions = this.dataModel.KennTransportOptions;
      if (this.dataModel.antrag_Art === 'E') {
        this.KennTransportOptions = false;
      }
      if (this.dataModel.antrag_Art === 'EA') {
        this.KennTransportOptions = false;
      }
    }

    //REV MP 2024-05-22: Antragsgrund soll beim Erstattungsantrag nicht angezeigt werden.
    if(vsCommon.vsVarAssigend(this.dataModel.KennApplicationReason)) {
      this.KennApplicationReason = this.dataModel.KennApplicationReason;
        if (this.dataModel.antrag_Art === 'E') {
          this.KennApplicationReason = false;
        }
        if (this.dataModel.antrag_Art === 'EA') {
          this.KennApplicationReason = false;
        }
    }

    if(this.dataModel.antrag_Art === 'G' || this.dataModel.antrag_Art === 'E' || this.dataModel.antrag_Art === 'I') {
      this.antragsArt = this.dataModel.antrag_Art;
    }
    if(this.dataModel.antrag_Art === 'GA') {
      this.antragsArt = 'G';
    }
    if(this.dataModel.antrag_Art === 'EA') {
      this.antragsArt = 'E';
    }

    this.erstattPos = this.dataModel.articles;

    this.HttpServiceComponent.dataGet(this.ViewProperties.DataSourceID
      , false
      , this.ViewProperties.QueryFields
      , null
      , TvsGetType.gtNewRecord)
      .subscribe(
        data => {
          this.ResponseHandler(TvsHTTPMethod.hmGet, data, false, false);

          this.DataFilter.ItemsClear();
          // let item = this.DataFilter.ItemAdd('Status');
          // item.CriteriaAdd('VorfQueue_Kenn_System', 'true');

          // this.DOMain.Dataset.Data[0].Vorf_ID_Queue = null;

        });


    // this.editForm.addControl('Attachment', new FormControl(null));

    this.editForm.addControl('Zust_Box', new FormControl(null));

    this.besUmstText =  this.dataModel.antr_Bes_Erlaeut;

    this.besUmst = this.dataModel.vorgHint;

  } // ngOnInit


  ngAfterViewInit(): void {

    //$REV MP 2023-03-20: Wenn in der Übersetzungsdatei kein Text für eine Erläuterung/Erklärung vorhanden ist wird das HTML-Element entfernt.
    if(this.explanation_header.nativeElement.innerText === '') {
      this.explanation_header.nativeElement.remove();
    }
    if(this.explanation_text.nativeElement.innerText === '') {
      this.explanation_text.nativeElement.remove();
    }

  }


  public ValidateData(): void {

      this.editForm.get('Zust_Box').setValidators(Validators.requiredTrue);
      this.editForm.get('Zust_Box').updateValueAndValidity();
      let bOK = !this.editForm.invalid;

      if (bOK) {
        this.dataSave();

      }
  }


  public actionStepPrev(): void {
    //$REV MP 2023-09-25: Bugfix, bei Erstattung auf SEPA. In allen anderen Fällen auf Dokumente
    if(this.dataModel.antrag_Art === 'E' || this.dataModel.antrag_Art === 'EA') {
      this.router.navigate(['antragEdit/sepa']);
    }
    else {
      this.router.navigate(['antragEdit/doc']);
    }

  } // actionStepPrev()

  //Aufruf beim drücken des Buttons Abbruch
  public actionDialogCancel(): void {
    
    this.displayCancelDialog = true;
  }

  //Aufruf beim Bestätigen des Warndialogs Abbruch mit OK
  public actionCancel(): void {

    //eGovSad - Abbruch melden
    this.dataModel.azubiNutzungsDaten.processEnd = this.convertDate(new Date());
    //Id: 1 für Abgebrochen
    this.dataModel.azubiNutzungsDaten.processStatus = {id: 1};
    const jsonData = JSON.stringify(this.dataModel.azubiNutzungsDaten, null, 2); // Beautify the JSON output
    console.log(jsonData);

    //eGovSad
    this.HttpServiceComponent.HttpServicePostRequest('school/export/egovsad', '&usagesMode=online', jsonData)
    .subscribe(response => {console.log('Response:', response )},
    error => {
    console.log('Error Get-Request: ', error);
    },
    () => {
    //Bei Abschluss navigation auf home und leeren des Datenmodells
      this.dataModel.EmptyData();
      this.router.navigate(['home']);
    }
  )

  } // actionStepPrev()

  public dataSave() :void{
    this.dataModel.isSubmitted = true;

    var dtStr: string = vsCommon.RetrieveDateStrNow();
    var tmStr: string = vsCommon.RetrieveTimeStrNow();


    // Antragsdaten
    this.DOMain.Dataset.Data[0].Antr_Erfass_Datum            = vsCommon.DateStrToISO8061(dtStr);

    // Datum Uhrzeit liefern genau den String den man sieht (Einstellung in vsDataTime: dataType="string")
    this.DOMain.Dataset.Data[0].Antr_Datum_Antrag            = vsCommon.DateStrToISO8061(this.dataModel.antrag_Datum);



    if(vsCommon.vsStringHasValue(this.dataModel?.article_Data)) {
        //Im Falle eines Erstattungsantrages: Erstattungszeitraum von-bis
        this.DOMain.Dataset.Data[0].Antr_Datum_Guelt_Beg         = vsCommon.DateStrToISO8061(this.dataModel.article_Data.Refund_from);
        this.DOMain.Dataset.Data[0].Antr_Datum_Guelt_End         = vsCommon.DateStrToISO8061(this.dataModel.article_Data.Refund_till);
    }

    else {
      //Testweise Antragsdatum für Beginn und Ende eingesetzt, muss später z.B. durch Beginn / Ende Schuljahr ersetzt werden
      this.DOMain.Dataset.Data[0].Antr_Datum_Guelt_Beg         = vsCommon.DateStrToISO8061(this.dataModel.antr_Datum_Guelt_Beg);
      // this.DOMain.Dataset.Data[0].Antr_Datum_Guelt_End         = vsCommon.DateStrToISO8061(this.dataModel.antr_Datum_Guelt_Beg);
    }


    this.DOMain.Dataset.Data[0].Antr_ID_Status               = 'NEU';
    this.DOMain.Dataset.Data[0].Antr_ID_Art                  = this.antragsArt;
    this.DOMain.Dataset.Data[0].Art_ID                       = this.antragsArt;

    // this.editForm.addControl('Antr_ID_Mand', new FormControl(null));
    this.DOMain.Dataset.Data[0].Antr_ID_Src                  = 'WEB';

    //$REV MP 24-05-2023: Azubi-ID Übermittlung bei schon vorhandenen Schülern.
    if (vsCommon.vsStringHasValue(this.dataModel.azubi_ID)) {
      this.DOMain.Dataset.Data[0].Antr_ID_Azubi              = this.dataModel.azubi_ID;
    }

    //$Rev MP 2023-11-06 Fallunterscheidung für Grundanträge und Erstattungsanträge eingefügt. Im Grundantrag wird die Anrede neu befüllt,
    // im Erstattungsantrag wird die Anrede aus den Grundantragsdaten geladen.
    if(this.antragsArt === 'G' || this.antragsArt === 'I') {
      this.DOMain.Dataset.Data[0].Azubi_ID_Anrede              = this.dataModel.azubi_Anrede_Rec.Anrede_ID;
      this.DOMain.Dataset.Data[0].Azubi_Anrede_Kuerzel         = this.dataModel.azubi_Anrede_Rec.Anrede_Kuerzel;
    }

    if(this.antragsArt === 'E') {
      this.DOMain.Dataset.Data[0].Azubi_ID_Anrede              = this.dataModel.azubi_Anrede.Anrede_ID;
      this.DOMain.Dataset.Data[0].Azubi_Anrede_Kuerzel         = this.dataModel.azubi_Anrede.Anrede_Kuerzel;
    }

    // this.DOMain.Dataset.Data[0].Azubi_ID_Anrede              = this.dataModel.azubi_Anrede_Rec.Anrede_ID;
    // this.DOMain.Dataset.Data[0].Azubi_Anrede_Kuerzel         = this.dataModel.azubi_Anrede_Rec.Anrede_Kuerzel;
    this.DOMain.Dataset.Data[0].Azubi_Nachname               = this.dataModel.azubi_Nachname;
    this.DOMain.Dataset.Data[0].Azubi_Vorname                = this.dataModel.azubi_Vorname;
    this.DOMain.Dataset.Data[0].Azubi_Geb_Datum              = vsCommon.DateStrToISO8061(this.dataModel.azubi_Geb_Datum);
    this.DOMain.Dataset.Data[0].Azubi_Strasse                = this.dataModel.azubi_Strasse;
    this.DOMain.Dataset.Data[0].Azubi_HausNr                 = this.dataModel.azubi_HausNr;

    //REV MP 2023-08-21: Azubi-Adresszusatz hinzugefügt
    if (vsCommon.vsStringHasValue(this.dataModel.azubi_Adr_Zusatz)) {
      this.DOMain.Dataset.Data[0].Azubi_Name_Zusatz_1        = this.dataModel.azubi_Adr_Zusatz;
    }

    // if(vsCommon.vsVarAssigend(this.dataModel.transp_Art_Rec)) {
    //   this.DOMain.Dataset.Data[0].Antr_Anmerk                = this.dataModel.transp_Art_Rec.Value;
    // }

    // if(vsCommon.vsVarAssigend(this.dataModel.transp_Reason_Rec)) {
    //   this.DOMain.Dataset.Data[0].Antr_Anmerk                = this.dataModel.transp_Reason_Rec.Value;
    // }

    //$REV MP 2024-05-16: Anmerkungen werden erst in Array gepackt, da mehrere Optionen gleichzeitig aktiv sein können, die ansonsten überschrieben werden.
    let anmerkArray = [];

    if (vsCommon.vsVarAssigend(this.dataModel.transp_Art_Rec)) {
      anmerkArray.push(this.dataModel.transp_Art_Rec.Value);
    }

    if (vsCommon.vsVarAssigend(this.dataModel.transp_Reason_Rec)) {
      anmerkArray.push(this.dataModel.transp_Reason_Rec.Value);
    }

    if (anmerkArray.length > 0) {
      this.DOMain.Dataset.Data[0].Antr_Anmerk = anmerkArray.join(' '); // Concatenate with a space or any desired delimiter
    }

    this.DOMain.Dataset.Data[0].Azubi_Ortsteil               = this.dataModel.azubi_Ortsteil;
    this.DOMain.Dataset.Data[0].Azubi_PLZ                    = this.dataModel.azubi_PLZ;
    this.DOMain.Dataset.Data[0].Azubi_Ort                    = this.dataModel.azubi_Ort;
    this.DOMain.Dataset.Data[0].Azubi_Phone                  = this.dataModel.azubi_Phone;
    this.DOMain.Dataset.Data[0].Azubi_Mobile                 = this.dataModel.azubi_Mobile;
    this.DOMain.Dataset.Data[0].Azubi_eMail                  = this.dataModel.azubi_eMail;

    //Gesetzliche Vertretung
    //Wenn der Schüler volljährig ist und sich selbst vertritt werden die Antragstellerdaten nicht übermittelt
    if(this.dataModel.KennAzubiGesVertr === false) {

      // im DO ist das Kennzeichen umgekehrt, deshalb true
      this.DOMain.Dataset.Data[0].xKennAzubiGesVertrAbw        = true;

      // this.DOMain.Dataset.Data[0].GesVertr_ID_Anrede          = this.dataModel.antrStell_Anrede_Rec.Anrede_ID;
      // this.DOMain.Dataset.Data[0].GesVertr_Anrede_Kuerzel     = this.dataModel.antrStell_Anrede.Anrede_Kuerzel;

      // this.DOMain.Dataset.Data[0].GesVertr_ID_Titel           = this.dataModel.antrStell_Anrede.Titel_ID;
      // this.DOMain.Dataset.Data[0].GesVertr_Titel_Kuerzel      = this.dataModel.antrStell_Anrede.Titel_Kuerzel;

      // this.DOMain.Dataset.Data[0].GesVertr_Nachname           = this.dataModel.antrStell_Nachname;
      // this.DOMain.Dataset.Data[0].GesVertr_Vorname            = this.dataModel.antrStell_Vorname;
      // this.DOMain.Dataset.Data[0].GesVertr_Geb_Datum          = vsCommon.DateStrToISO8061(this.dataModel.antrStell_Geb_Datum);
      // this.DOMain.Dataset.Data[0].GesVertr_Strasse            = this.dataModel.azubi_Strasse;
      // this.DOMain.Dataset.Data[0].GesVertr_HausNr             = this.dataModel.antrStell_HausNr;

    //REV MP 2023-08-21: Antragsteller-Adresszusatz hinzugefügt
    if (vsCommon.vsStringHasValue(this.dataModel.antrStell_Adr_Zusatz)) {
      this.DOMain.Dataset.Data[0].GesVertr_Name_Zusatz_1       = this.dataModel.antrStell_Adr_Zusatz;
    }

      this.DOMain.Dataset.Data[0].GesVertr_Ortsteil           = this.dataModel.antrStell_Ortsteil;
      this.DOMain.Dataset.Data[0].GesVertr_PLZ                = this.dataModel.antrStell_PLZ;
      this.DOMain.Dataset.Data[0].GesVertr_Ort                = this.dataModel.antrStell_Ort;
      this.DOMain.Dataset.Data[0].GesVertr_Phone              = this.dataModel.antrStell_Phone;
      this.DOMain.Dataset.Data[0].GesVertr_Mobile             = this.dataModel.antrStell_Mobile;
      this.DOMain.Dataset.Data[0].GesVertr_eMail              = this.dataModel.antrStell_eMail;
    }

    //Wenn der Schüler sich selbst vertritt wird der Abweichende Vertreter auf false gesetzt.
    if(this.dataModel.KennAzubiGesVertr === true) {
      this.DOMain.Dataset.Data[0].xKennAzubiGesVertrAbw = false;
    }

    this.DOMain.Dataset.Data[0].Antr_ID_Kontakt_AntrStell    = this.dataModel.antr_ID_Kontakt_AntrStell;

    //Bankverbindung
    if (vsCommon.vsStringHasValue(this.dataModel.antrStell_Bank_ID)) {
      this.DOMain.Dataset.Data[0].Bank_ID_Bank               = this.dataModel.antrStell_Bank_ID;
    }

    if (vsCommon.vsStringHasValue(this.dataModel.antrStell_Kto_Inh)) {
      this.DOMain.Dataset.Data[0].Bank_Inhaber_Name           = this.dataModel.antrStell_Kto_Inh;
    }

    if (vsCommon.vsStringHasValue(this.dataModel.antrStell_IBAN)) {
      this.DOMain.Dataset.Data[0].Bank_IBAN                   = this.dataModel.antrStell_IBAN;
    }

    if (vsCommon.vsStringHasValue(this.dataModel.antrStell_Bank_BIC)) {
      this.DOMain.Dataset.Data[0].Bank_BIC                   = this.dataModel.antrStell_Bank_BIC;
    }



    //Schule

    this.DOMain.Dataset.Data[0].Antr_ID_Ausb_Staett_Art      = this.dataModel.antr_Ausb_Staett_Rec.Link_ID_AusbStaett_Art;
    this.DOMain.Dataset.Data[0].Antr_ID_Ausb_Staett_Antr     = this.dataModel.antr_Ausb_Staett_Rec.Link_ID_AusbStaett;

    // this.DOMain.Dataset.Data[0].Antr_ID_AusbStaett_Art_Bew   = this.dataModel.antr_Ausb_Staett_Rec?.AusbStaett_ID_Art;
    this.DOMain.Dataset.Data[0].Antr_ID_AusbStaett_Art_Antr  = this.dataModel.antr_Ausb_Staett_Rec?.Link_ID_AusbStaett_Art;


    this.DOMain.Dataset.Data[0].Antr_Klasse_Beg_Nr           = this.dataModel.antr_Klasse_Beg_Nr;
    this.DOMain.Dataset.Data[0].Antr_Klasse_Beg_Zusatz       = this.dataModel.antr_Klasse_Beg_Zusatz;

    if (this.antragsArt === 'E' && vsCommon.vsStringHasValue(this.dataModel.antrID_Grundantrag)) {
      this.DOMain.Dataset.Data[0].Antr_ID_Antr_Parent        = this.dataModel.antrID_Grundantrag;
    }
    // sonder
    // this.DOMain.Dataset.Data[0].Antr_Bes_Schulweg_Danger    = this.dataModel.antr_Bes_Schulweg_Danger;
    // this.DOMain.Dataset.Data[0].Antr_Bes_Erlaeut            = this.dataModel.antr_Bes_Erlaeut;

    //$REV MP 2024-05-22: Erst einmal auskommentiert.
    // this.DOMain.Dataset.Data[0].Antr_Data_Indiv        = JSON.stringify(this.dataModel.antr_Data_Individual);
    this.DOMain.Dataset.Data[0].Antr_Data_Erstattung        = JSON.stringify({articles: this.dataModel.articles, article_data: this.dataModel.article_Data});

    //$REV MP 2023-08-22: Beginn des Schulbesuches, Beginn der Kostenübernahmne.

    this.DOMain.Dataset.Data[0].Antr_Kost_Ueber_Datum_Beg   =  vsCommon.DateStrToISO8061(this.dataModel.antr_Datum_Guelt_Beg);

    //$REV MP 2023-08-22: Ausbildungsbetrieb & Informationen zum Berufsschulbesuch

    this.DOMain.Dataset.Data[0].Antr_AusbStaett_Beruf_Name1         =  this.dataModel.antrStell_AusbBetr_Name;
    this.DOMain.Dataset.Data[0].Antr_AusbStaett_Beruf_Strasse       =  this.dataModel.antrStell_AusbBetr_Strasse;
    this.DOMain.Dataset.Data[0].Antr_AusbStaett_Beruf_HausNr        =  this.dataModel.antrStell_AusbBetr_HausNr;
    this.DOMain.Dataset.Data[0].Antr_AusbStaett_Beruf_PLZ           =  this.dataModel.antrStell_AusbBetr_PLZ;
    this.DOMain.Dataset.Data[0].Antr_AusbStaett_Beruf_Ort           =  this.dataModel.antrStell_AusbBetr_Ort;
    this.DOMain.Dataset.Data[0].Antr_AusbStaett_Beruf_Ortsteil      =  this.dataModel.antrStell_AusbBetr_Ortsteil;
    // this.DOMain.Dataset.Data[0].Antr_AusbStaett_Beruf_Zusatz    =  this.editForm.get('Antr_AusbStaett_Beruf_Zusatz').value;
    this.DOMain.Dataset.Data[0].Antr_AusbStaett_Beruf_Zeiten        =  this.dataModel.antrStell_Ausb_School_Text;



      this.HttpServiceComponent.dataUpdate(this.ViewProperties.DataSourceID,
        this.ViewProperties.DOMain,
        null)
        .subscribe(
        dataPost => {

          //Füllen der AntragsID für das Speichern von Erstattungspositionen
            this.antrID = dataPost.PKValue;

            this.DataFilter.ItemsClear();
            // let item = this.DataFilter.ItemAdd('Filter PKValue');
            // item.CriteriaAdd('PKValue', dataPost.PKValue);

            // this.HttpServiceComponent.dataGet( this.ViewProperties.DataSourceID
            //           , this.ViewProperties.DOMain.KennFieldDefs
            //           ,[]
            //           ,this.DataFilter)
            this.HttpServiceComponent.HttpServiceGetRequest('school/data/antrag', '&Antr_ID='+ dataPost.PKValue)
            .subscribe(
            data => {
              this.DataFilter.ItemsClear();

            //Füllen der antr_ID_CRM_Vorf für das Anhängen von Vorgängen unter den gerade gespeicherten Antrag
            
            if(vsCommon.vsStringHasValue(data['Dataset'].Data[0].Antr_ID_CRM_Vorf)) {
              this.antr_ID_CRM_Vorf = data['Dataset'].Data[0].Antr_ID_CRM_Vorf;
            }
     

            // this.globalResources.ViewMain.msgShowToastSuccess('Neuer Datensatz wurde gespeichert');

            //Falls Erstattungspositionen vorhanden sind und der Antrag ein Erstattungsantrag ist.
            //$REV MP 2023-01-19: neue Variable antragsArt hinzugefügt, Prüfung von dataModel ergab an dieser Stelle immer "null"
            if (this.antragsArt === 'E') {

              this.dataObject = {
                "accID": "",
                "accLoginID": "",
                // "applicationID": this.dataModel.antrID_Grundantrag,
                "applicationID": this.antrID,
                "positions": []
              }

              //saveData enthält nun für jede Erstattsungsposition ein JSON Objekt mit neuen properties,
              //welche dann jeweils an den Service übergeben werden müssen
              //articles sollte zu positions umbenannt werden
              this.erstattPos.map((article) => {

                  this.dataObject.positions.push(
                  {
                    "articleID": article.id,
                    "posQuantity": article.amount,
                    "posQuantityGranted": article.amount,
                    "posRouteKMPerDay": vsCommon.vsCvKilometersToInt(article.streckenlaenge),
                    "posPriceSingle": vsCommon.vsCvCurrencyToInt(article.price),
                    "posPriceTotal": vsCommon.vsCvCurrencyToInt(article.totalprice),
                    "posPriceSingleGranted": vsCommon.vsCvCurrencyToInt(article.price),
                    "posPriceTotalGranted": vsCommon.vsCvCurrencyToInt(article.totalprice),
                    "posNum": 0,
                    "posName": article.bezeichnung,
                    "articleNum": "",
                    "articleName": ""
                  }
                );

              });

          this.HttpServiceComponent.HttpServicePostRequestWebAccount('schoolDataAppPositionsPost', '', JSON.stringify(this.dataObject))
            .subscribe(response => {

            });//Ende HttpService

          }

            //Falls Text für den Punkt besondere Umstände vorhanden ist.
            if (vsCommon.vsStringHasValue(this.besUmstText)) {

              let vorgHint:  vsCommon.TVorgMain  =  new vsCommon.TVorgMain();

              //Antrags-ID für die Zuordnung des Vorgangs zum Antrag
              vorgHint.crmTicketID               =  this.antr_ID_CRM_Vorf;

              vorgHint.crmActivityID             = null;
              vorgHint.crmActivityType           = vsCommon.TcrmVorgangArt.vaNotiz;
              vorgHint.crmActivityDate           = this.besUmst.crmActivityDate;
              vorgHint.crmActivityTime           = this.besUmst.crmActivityTime;
              vorgHint.crmActivityName           = this.besUmst.crmActivityName;
              vorgHint.crmActivityDesc           = this.besUmst.crmActivityDesc;
              vorgHint.crmActivityMultiDoc       = false;
              vorgHint.crmActivityIsIncoming     = true;

              let vDoc: vsCommon.TVorg = new vsCommon.TVorg(vorgHint);

              //------ HTTP Request

              this.HttpServiceComponent.HttpServicePostRequest('crmDataDocumentAdd', '',JSON.stringify(vDoc))
                .subscribe(response => {

                },
                  error => {
                  alert(  'Beim hochladen des Dokumentes ist ein Fehler aufgetreten:\n'
                  + error.error.Error.ErrCode + '\n'
                  + error.error.Error.ErrMsg + '\n\n'
                  + error.message );
                  this.DOMain.IsRefreshing = false;
                  },
                  () => {

                  //Nach Abschluss des Speichervorganges Weiterleitung zur Confirmation-Page
                    // this.router.navigate(['Confirmation']);
                  }
                ) // subscribe, Ende crm Vorgang add

            }

            //Falls Attachments vorhanden sind
            if (this.dataModel.KennMessageWithAttachment && this.dataModel.KennAttachment) {

              this.Files = this.dataModel.files;

              let vorgMain:  vsCommon.TVorgMain  =  new vsCommon.TVorgMain();

              //Antrags-ID für die Zuordnung des Vorgangs zum Antrag
              vorgMain.crmTicketID               =  this.antr_ID_CRM_Vorf;

              //Vorf_ID_Kontakt muss später noch hinzugefügt werden
              // vorgMain.crmContactID           = this.ParamList.get('Vorf_ID_Kontakt');

              vorgMain.crmActivityID             = null;
              vorgMain.crmActivityType           = vsCommon.TcrmVorgangArt.vaDokumentSonstiges;
              vorgMain.crmActivityDate           = this.dataModel.vorgMain.crmActivityDate;
              vorgMain.crmActivityTime           = this.dataModel.vorgMain.crmActivityTime;
              vorgMain.crmActivityName           = this.dataModel.vorgMain.crmActivityName;
              vorgMain.crmActivityDesc           = this.dataModel.vorgMain.crmActivityDesc;
              vorgMain.crmActivityMultiDoc       = false;
              vorgMain.crmActivityIsIncoming     = true;

              let vDoc: vsCommon.TVorg = new vsCommon.TVorg(vorgMain);
              for (let i=0; i < this.Files.length; i++) {
                vDoc.DocAdd(this.Files[i].UploadFileName,
                  null,
                  vsCommon.vsStringFileExtractExt(this.Files[i].UploadFileName),
                  this.Files[i].UploadFileDataBase64);
              }

              //------ HTTP Request

              this.HttpServiceComponent.HttpServicePostRequest('crmDataDocumentAdd', '',JSON.stringify(vDoc))
                .subscribe(response => {

                },
                  error => {
                  console.log('Error DocumenAdd: ', error);
                  alert(  'Beim hochladen des Dokumentes ist ein Fehler aufgetreten:\n'
                  + error.error.Error.ErrCode + '\n'
                  + error.error.Error.ErrMsg + '\n\n'
                  + error.message );
                  this.DOMain.IsRefreshing = false;
                  },
                  () => {
                  console.log('Dokumentupload completed');

                  //Nach Abschluss des Speichervorganges Weiterleitung zur Confirmation-Page
                    this.router.navigate(['Confirmation']);
                  }
                ) // subscribe, Ende crm Vorgang add

            }

            },
            error => {
            console.log('Error Get-Request: ', error);
            alert(  'Bei dem Get-Request ist ein Fehler aufgetreten:\n'
            + error.error.Error.ErrCode + '\n'
            + error.error.Error.ErrMsg + '\n\n'
            + error.message );
            this.DOMain.IsRefreshing = false;

            },
            () => {
            }

            ) // subscribe
        },
        error => {
          this.dataModel.azubiNutzungsDaten.processEnd = this.convertDate(new Date());
          this.dataModel.azubiNutzungsDaten.processStatus = {id: 1};
          const jsonData = JSON.stringify(this.dataModel.azubiNutzungsDaten, null, 2); // Beautify the JSON output

          this.HttpServiceComponent.HttpServicePostRequest('school/export/egovsad', '&usagesMode=online', jsonData) .subscribe(response => {console.log('Response:', response ); })

          // this.HttpServiceComponent.dataPostRequestToEndpoint('http://localhost:3000/api/egovsad/data', jsonData, '', 'post', false, 'json').subscribe((res) => {
          //   console.log(res);
          // })
        console.log('Error Put-Request: ', error);
        alert(  'Bei dem Put-Request ist ein Fehler aufgetreten:\n'
        + error.error.Error.ErrCode + '\n'
        + error.error.Error.ErrMsg + '\n\n'
        + error.message );
        this.DOMain.IsRefreshing = false;

        }, () => {
          // //Anzeige und dataModel leeren erst bei Abschluss des Speichervorgangs
          this.messageService.add({severity: 'success',  summary: 'Info',  detail: 'Ihr Antrag wurde gesendet'});

          // // Muss später aktiviert werden um mehrfaches Absenden zu verhindern, sperrt den Senden Button
          this.dataModel.isSubmitted = true;

          // Nach Abschluss des Speichervorganges Weiterleitung zur Confirmation-Page, wird zu früh ausgeführt, wenn Anhänge vorhanden sind, deshalb if case
          if (!this.dataModel.KennAttachment) {
            this.router.navigate(['Confirmation']);
          }

          this.dataModel.azubiNutzungsDaten.processEnd = this.convertDate(new Date());
          this.dataModel.azubiNutzungsDaten.processStatus = {id: 2};
          const jsonData = JSON.stringify(this.dataModel.azubiNutzungsDaten, null, 2); // Beautify the JSON output
          console.log(jsonData);

          //eGovSad
          this.HttpServiceComponent.HttpServicePostRequest('school/export/egovsad', '&usagesMode=online', jsonData) .subscribe(response => {console.log('Response:', response ); })

          // this.HttpServiceComponent.dataPostRequestToEndpoint('http://localhost:3000/api/egovsad/data', jsonData, '', 'post', false, 'json').subscribe((res) => {
          //   console.log(res);
          // })
        }

        )// subscribe

  } // dataSave


  convertDate(dateString) {
    // Create a new Date object using the input string
    const date = new Date(dateString);

    // Extract the year, month, and day from the Date object
    const year = date.getFullYear(); // yyyy
    let month: string | number  = date.getMonth() + 1; // getMonth() returns month from 0-11 (0 = January, 11 = December)
    let day: string | number  = date.getDate(); // getDate() returns day of the month
    let hours: string | number = date.getHours(); // getHours() returns hour of the day
    let minutes: string | number  = date.getMinutes(); // getMinutes() returns minutes
    let seconds: string | number  = date.getSeconds(); // getSeconds() returns seconds

    // Ensure the month, day, hours, minutes, and seconds are two digits by adding a leading zero if necessary
    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;

    // Format the date and time in yyyy-mm-ddTHH:MM:SS format
    const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;

    return formattedDateTime;
  }

  public doBearb_Antrag():  void { this.router.navigate(['antragEdit/antrag']);     }
  public doBearb_Student(): void { this.router.navigate(['antragEdit/student']);    }
  public doBearb_Schule():  void { this.router.navigate(['antragEdit/school']);     }
  public doBearb_Doc():     void { this.router.navigate(['antragEdit/doc']);        }
  public doBearb_Besond():  void { this.router.navigate(['antragEdit/besondere']);  }
  public doBearb_Bank():    void { this.router.navigate(['antragEdit/sepa']);       }
  public doBearb_Erstatt(): void { this.router.navigate(['antragEdit/erstattung']); }
  public doBearb_Indiv():   void { this.router.navigate(['antragEdit/pickup']);     }


} // schoolViewAntragEdit_Commit



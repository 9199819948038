
<!-- Wird nicht benötigt, da container-xxl und eine min-height schon in der schoolViewAntragEdit.component.html vorhanden sind-->
<!--<div class="container-xxl"  style="margin-top: 1.5rem; margin-bottom: 1.5rem; min-height: 608px; color: #808080; ">-->

  <!-- <div style="display: flex; font-size: 1.5rem; font-weight: bold; color: #f07918;  margin-top: 3rem; margin-bottom: 1.5rem; justify-content: center">
    {{this.ViewProperties.ViewTitle}} | Work In Progress
  </div> -->


  <form [formGroup]="this.editForm">

      <vsLayoutGroup class="col-12" Orientation="horizontal" MediumGutterWidth=true>

        <vsLayoutGroup class="col-12">
          <div #explanation_header class="vsFormTextDiv">
            <b>{{'explanation.reimbursement_list_heading'| translate}}</b>
          </div>
          <div #explanation_text class="vsFormTextDivParagraph vsMb-2">
            {{'explanation.reimbursement_list_text'| translate}}
          </div>
        </vsLayoutGroup>

        <vsLayoutGroup class="col-12" isPanel=true panelTitleVisible=true panelTitleText="{{'application.reimbursement.heading'| translate}}" Orientation="horizontal" MediumGutterWidth=true>

          <vsLayoutGroup class="col-lg-6 col-md-6 col-sm-12 col-12">
            <vsInputAutoComplete FrmCtlName="SchoolYear" placeholder="*Eingabe erforderlich" (onSelect)="doSchuljahrSelect($event)" label="Schul-Halbjahr" [suggestions]="filteredLookupSchulHalbjahr" FieldName="Halbjahr" MinLength=1 Dropdown=true ></vsInputAutoComplete>
          </vsLayoutGroup>
          <vsLayoutGroup class="col-lg-6 col-md-6 col-sm-0 col-0">
          </vsLayoutGroup>

          <vsLayoutGroup class="col-lg-6 col-md-6 col-sm-12 col-12">
            <vsInputDateTime FrmCtlName="Refund_from" label="{{'application.reimbursement.time_from'| translate}}" placeholder="{{'application.input_required'| translate}}"></vsInputDateTime>
          </vsLayoutGroup>

          <vsLayoutGroup class="col-lg-6 col-md-6 col-sm-12 col-12">
            <vsInputDateTime FrmCtlName="Refund_till" label="{{'application.reimbursement.time_till'| translate}}" placeholder="{{'application.input_required'| translate}}"></vsInputDateTime>
          </vsLayoutGroup>

          <div class="vsMb-2"></div>
          <div class="vsMb-2"></div>

          <vsLayoutGroup class="col-12 d-none d-md-block" Orientation="horizontal">

            <vsLayoutGroup class="col-lg-3 col-md-3 col-sm-12 col-12">
              <div class="vsFormTextDiv"><b>{{'application.reimbursement.table_heading_item'| translate}}</b></div>
            </vsLayoutGroup>

            <vsLayoutGroup class="col-lg-1 col-md-2 col-sm-12 col-12">
              <div class="vsFormTextDiv"><b>{{'application.reimbursement.table_heading_amount'| translate}}</b></div>
            </vsLayoutGroup>

            <vsLayoutGroup class="col-lg-2 col-md-2 col-sm-12 col-12">
              <div class="vsFormTextDiv"><b>{{'application.reimbursement.table_heading_item_price'| translate}}</b></div>
            </vsLayoutGroup>

            <vsLayoutGroup class="col-lg-2 col-md-2 col-sm-12 col-12">
              <div class="vsFormTextDiv"><b>{{'application.reimbursement.table_heading_distance'| translate}}</b></div>
            </vsLayoutGroup>

            <vsLayoutGroup class="col-lg-2 col-md-2 col-sm-12 col-12">
              <div class="vsFormTextDiv"><b>{{'application.reimbursement.table_heading_total_price'| translate}}</b></div>
            </vsLayoutGroup>

          </vsLayoutGroup>

          <div class="vsMb-2"></div>

          <vsLayoutGroup *ngFor="let article of articles; index as i" class="col-12 vsMb-2" Orientation="horizontal" #articleList>

            <vsLayoutGroup class="col-lg-3 col-md-3 col-sm-12 col-12">
              <!-- {{article.frmCtrlNames[0]}} -->
              <div class="d-xl-none d-lg-none d-md-none vsMb-2"><b>{{'application.reimbursement.row'| translate}} {{i + 1}}</b></div>
              <div class="d-xl-none d-lg-none d-md-none vsMt-2 vsMb-2">{{'application.reimbursement.table_heading_item'| translate}}:</div>
              <vsInputAutoComplete id="article.frmCtrlNames[0]" [FrmCtlName]="article.frmCtrlNames[0]" label="{{'application.reimbursement.item_name'| translate}}" [NoLabel]="true" [suggestions]=articleOptions FieldName="Obj_Name" MinLength=1 Dropdown=true (onSelect)="disableEnableEditForm(article)"></vsInputAutoComplete>
            </vsLayoutGroup>

            <vsLayoutGroup class="col-lg-1 col-md-1 col-sm-12 col-12">
              <!-- {{article.frmCtrlNames[1]}} -->
              <div class="d-xl-none d-lg-none d-md-none vsMb-2">{{'application.reimbursement.table_heading_amount'| translate}}:</div>
              <vsInputNumber id="article.frmCtrlNames[1]" [FrmCtlName]="article.frmCtrlNames[1]" label="{{'application.reimbursement.item_amount'| translate}}" [NoLabel]="true" [valueChanges]="calcTotalprice(article)"></vsInputNumber>
            </vsLayoutGroup>

            <vsLayoutGroup class="col-lg-2 col-md-2 col-sm-12 col-12">
              <!-- {{article.frmCtrlNames[2]}} -->
              <div class="d-xl-none d-lg-none d-md-none vsMb-2">{{'application.reimbursement.table_heading_item_price'| translate}}:</div>
              <vsInputNumber id="article.frmCtrlNames[2]" [FrmCtlName]="article.frmCtrlNames[2]" label="{{'application.reimbursement.item_single_price'| translate}}" [NoLabel]="true" [KennCurrency]=true [valueChanges]="calcTotalprice(article)"></vsInputNumber>
            </vsLayoutGroup>

            <vsLayoutGroup class="col-lg-2 col-md-2 col-sm-12 col-12">
              <div class="d-xl-none d-lg-none d-md-none vsMb-2">{{'application.reimbursement.table_heading_distance'| translate}}:</div>
              <vsInputNumber id="article.frmCtrlNames[3]" [FrmCtlName]="article.frmCtrlNames[3]" label="{{'application.reimbursement.item_distance'| translate}}" [NoLabel]="true" [KennFractionDigits]=true></vsInputNumber>
            </vsLayoutGroup>

            <vsLayoutGroup class="col-lg-2 col-md-2 col-sm-12 col-12">
              <!-- {{article.frmCtrlNames[3]}} -->
              <div class="d-xl-none d-lg-none d-md-none vsMb-2">{{'application.reimbursement.item_total_price'| translate}}:</div>
              <vsInputNumber id="article.frmCtrlNames[4]" [FrmCtlName]="article.frmCtrlNames[4]" label="{{'application.reimbursement.item_total_price'| translate}}" [NoLabel]="true" [KennCurrency]=true></vsInputNumber>
              <!-- gesamt: {{this.editForm.controls[article.frmCtrlNames[1]].value*this.editForm.controls[article.frmCtrlNames[2]].value}} -->
            </vsLayoutGroup>

            <vsLayoutGroup class="col-lg-2 col-md-2 col-sm-12 col-12">
              <!-- <button class="btn-remove" type="button" (click)="removeArticle(article)">x</button> -->

              <button *ngIf="articles.length > 1 && i !== 0" pButton type="button" class="vsCardTitleButton me-2" style="display:inline;" (click)="removeArticleRowAndControl(article, index)">
                <img src="/assets/img/Icons/vs-close-circle.svg" style="width: 1.4rem" />
              </button>

              <button *ngIf="i+1 === articles.length" pButton type="button" class="vsCardTitleButton me-2" style="display:inline;" (click)="addArticleRowAndControl()">
                <img src="/assets/img/Icons/vs-plus-circle.svg" style="width: 1.4rem" />
              </button>

            </vsLayoutGroup>

            <div class="d-xl-none d-lg-none vsMb-2"></div>

          </vsLayoutGroup>

        </vsLayoutGroup>  

    </vsLayoutGroup>

  </form>

  <div style="margin-top: 1rem; margin-bottom: 1rem;">
      <button pButton label="{{'application.button_back'| translate}}" (click)="actionStepPrev()" class="vsElternButton vsBack" style="margin-right: 10px;"></button>
      <button pButton label="{{'application.button_forward'| translate}}" (click)="actionStepNext()" class="vsElternButton vsForward"></button>
  </div>
  
  

  <p-dialog header="{{'dialog.error_fill_heading'| translate}}" [(visible)]="displayDialogError" [baseZIndex]="10000" class="vsDialog vsDanger vsRequired">
    <p [innerHtml]="'dialog.error_fill_text'| translate"></p>
    <ng-template pTemplate="footer">
        <button pButton pRipple type="button" (click)="displayDialogError=false" label="{{'dialog.error_fill_button_ok'| translate}}" class="vsToolbarItem vsToolbarItemOK vsMb-2">
          <span>
            <img alt="close" src="/assets/img/Icons/vs-check-circle.svg" />
          </span>
        </button>
    </ng-template>
  </p-dialog>

  <p-dialog header="{{'dialog.error_startDate_wrong_heading'| translate}}" [(visible)]="displayDateRangeFromError" [baseZIndex]="10000" class="vsDialog vsDanger vsRequired">
    <!-- <p [innerHtml]="'dialog.error_fill_text'| translate"></p> -->
    <p>{{'dialog.error_startDate_wrong_text'| translate}} {{printStartDate}}</p>
    <ng-template pTemplate="footer">
        <button pButton pRipple type="button" (click)="displayDateRangeFromError=false" label="{{'dialog.error_fill_button_ok'| translate}}" class="vsToolbarItem vsToolbarItemOK vsMb-2">
          <span>
            <img alt="close" src="/assets/img/Icons/vs-check-circle.svg" />
          </span>
        </button>
    </ng-template>
  </p-dialog>

  <p-dialog header="{{'dialog.error_dateRange_wrong_heading'| translate}}" [(visible)]="displayDateRangeFromTillError" [baseZIndex]="10000" class="vsDialog vsDanger vsRequired">
    <!-- <p [innerHtml]="'dialog.error_fill_text'| translate"></p> -->
    <p>{{'dialog.error_dateRange_wrong_text_1'| translate}} {{printStartDate}} {{'dialog.error_dateRange_wrong_text_2'| translate}} {{printEndDate}}.</p>
    <ng-template pTemplate="footer">
        <button pButton pRipple type="button" (click)="displayDateRangeFromTillError=false" label="{{'dialog.error_fill_button_ok'| translate}}" class="vsToolbarItem vsToolbarItemOK vsMb-2">
          <span>
            <img alt="close" src="/assets/img/Icons/vs-check-circle.svg" />
          </span>
        </button>
    </ng-template>
  </p-dialog>


  <!-- <pre>{{ this.editForm.value | json}}</pre> -->
  


 
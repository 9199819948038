//--------------------------------------------------------------------------------------------------
//  AMIS7school - Externes Portal: Eltern
//
//  Domain:   Application
//  Title:    Component: Application Header
//  Notes:
//
//
//  H I S T O R Y
//
//  2021-08-12  TC   Initial Version
//--------------------------------------------------------------------------------------------------

import {  Component
        , OnInit
       }                                    from '@angular/core';

import { ActivatedRoute, Router }           from '@angular/router';


// [ngx-translate]
import { LangChangeEvent, TranslateService }                 from '@ngx-translate/core';


// PrimeNG
import { MenuItem }                         from 'primeng/api';
import { MessageService }                   from 'primeng/api';


// vsLib
import * as vsGlobalResources               from '@vsLib/Services/vsGlobalResources.service';
import * as vsCommon                        from '@vsLib/vsCommon';
import { DialogService, DynamicDialogRef }  from 'primeng/dynamicdialog';
import { TsysViewAccPwdChangeDlg }          from './sys/sysViewAccPwdChangeDlg.component';
import * as vsDataFilter                    from '@vsLib/vsDataFilter';
import {  TvsHttpServiceComponent}          from '@vsLib/Services/vsHttp.service.component';


import  * as schoolDataModel                from 'AMIS7school_Portal_Eltern/src/app/school/schoolDataModel.service';

// [LABOR] TODO:
// # Eigene Klassen für die GUI Language (Liste & Items)
// # Die Liste der Sprachen Sollte vermutlich über die GlobalResources zur Verfügung gestellt werden

export interface IGUILanguage {
  ID:         string;
  Caption:    string;
  IconURL:    string;
}


type TranslationMenuItemsType = {
  translationLabel: string;
  icon: string;
  command: any;
  escape?: boolean;
}



type TranslationDialogItemsType = {
  data:         any;
  header:       string;
  width:        string;
  styleClass:   string;
  style?:        any;
  baseZIndex:   number;
}



@Component({
  selector:       'appHeader',
  templateUrl:  './appHeader.component.html',
  providers: [DialogService]
  // styleUrls:   ['./appHeader.component.scss']
})
export class TappHeader implements OnInit {


  //====== Properties (PRIVATE)

  // ./.



  //====== Properties (PUBLIC)

  public      MenuUser:                       MenuItem[];

  public      GUILanguageList:                IGUILanguage[];       // [LABOR] TODO: Verlagern in eigene Datei/Klasse
  public      GUILanguageSelected:            IGUILanguage;         // [LABOR] TODO: Verlagern in eigene Datei/Klasse

  public      value:                          any;
  public      refUserPwdChangeDlg:            DynamicDialogRef;

  public      translationMenuItems:           TranslationMenuItemsType[]                          = [];

  public      translationDialogItems:         TranslationDialogItemsType[]                          = [];

  public      menuMessageBadge:               number = 0;

  public      KennTestMode:                   boolean = false;

  //====== Constructor

  constructor(protected router:                     Router
            , public    activatedRoute:             ActivatedRoute
            , public    globalResources:            vsGlobalResources.TvsGlobalResources
            , public    translate:                  TranslateService     // [ngx-translate]
            , protected messageService:             MessageService
            , public    dataModel:                  schoolDataModel.TschoolDataModel
            , public    dialogService:              DialogService
            , protected HttpServiceComponent:       TvsHttpServiceComponent

  ) {

    this.translationMenuItems = [
        {translationLabel:'user_menu.profile',          icon: 'vsUserIcon',   command: event => {this.mnuAccMyProfile_OnClick()}}
        //, {label: 'user_menu.settings',  icon: 'vsCogIcon',    command: event => {this.mnuUserConfig_OnClick()}}
      , {translationLabel:'user_menu.applications',     icon: 'vsFileIcon',   command: event => {this.mnuAccAntragList_OnClick()}}
      // Erst einmal auskommentiert, wird später durch eine Liste mit Antragsinformationen + Nachrichten pro Antrag ersetzt (Anzeige aller Anträge +  dazugehörige Nachrichten)
      , {translationLabel: 'user_menu.messages',        icon: 'vsEmailIcon',  command: event => {this.mnuAccMessage_OnClick()}, escape: false}
      , {translationLabel: 'user_menu.change_password', icon: 'vsLock' ,      command: event => {this.mnuAccPwdChange_OnClick()}}
      , {translationLabel: 'user_menu.logout',          icon: 'vsPowerIcon',  command: event => {this.actionLogout()}}
    ];

    this.translationDialogItems = [
      { data:  null,
        header: 'password_change.dialog_password_change_header',
        width: '30%',
        styleClass: 'vsDialog vsSmallFullscreenDialog',
        baseZIndex: 10000}
    ]

    //------ User Menu
    this.MenuUser = [] // User Menu


    //------ GUI Language

    let sURLBaseIconsFlags:   string  = 'assets/img/CountryFlags/';
    this.GUILanguageList = [
        {ID: 'de-DE',  Caption: 'Deutsch',           IconURL: sURLBaseIconsFlags + 'Germany.png'}
      // , {ID: 'de-ES',  Caption: 'Einfache Sprache',  IconURL: sURLBaseIconsFlags + 'Einfache_Sprache.png'}
      , {ID: 'en-GB',  Caption: 'English',           IconURL: sURLBaseIconsFlags + 'UnitedKingdom.png'}
      // , {ID: 'ru-RU',  Caption: 'Русский',           IconURL: sURLBaseIconsFlags + 'RussianFederation.png'}
      // , {ID: 'tr-TR',  Caption: 'Türkçe',            IconURL: sURLBaseIconsFlags + 'Turkey.png'}
      // , {ID: 'zh-ZH',  Caption: 'Chinese',  IconURL: sURLBaseIconsFlags + 'CountryFlagUnknown.png'}
    ];

    this.GUILanguageSelected = translate.currentLang ? this.GUILanguageList.find(lang => lang.ID === translate.currentLang) : this.GUILanguageList[0];   // $rev AK 2022-07-25 ALT: this.GUILanguageSelected = this.GUILanguageList[0]; [LABOR] TODO: Kann vermutlich beim Laden des UserProfiles erfolgen
    this.globalResources.AppInfo.selectedLang = this.GUILanguageSelected.ID;
    //Initial die MenuItems in der Startsprache erzeugen
    this.translationMenuItems.map((item) => {

      this.translate.get(item.translationLabel).subscribe((res)=>{
        this.MenuUser.push(
           {label: res,
            icon: item.icon,
            command: item.command
          });
      });

    });


  } // constructor



  //====== Methods: NG event handler


  ngOnInit(): void {
    //Zugelassene Routen
    //$REV MP 2024-03-08 dataprotection und imprint hinzugefügt.
    let sUrlOK: Array<string> = ['home', 'login', 'userAccReg', 'regConfirmation', 'regSelection', 'regLinkAcc', 'imprint', 'dataprotection'];

    if (!this.globalResources.WebAccProfile.IsLoggedIn &&
        sUrlOK.indexOf(this.activatedRoute.snapshot.url[0].path) == -1)
    {
      //  this.router.navigate(['home']);
    }
    else if (this.globalResources.WebAccProfile.IsLoggedIn) {
      this.calculateNewMessages();
    }

    if(vsCommon.vsVarAssigend(this.globalResources.AppConfigData.KennTestMode)) {
      this.KennTestMode = this.globalResources.AppConfigData.KennTestMode;
    }

    // this.translateMenuItems();



  } // ngOnInit

  calculateNewMessages() {

    // let DataFilter: vsDataFilter.TvsDataFilter = new vsDataFilter.TvsDataFilter();

    // DataFilter.ItemsClear();

    //let item = DataFilter.ItemAdd('Filter Azubi_ID_Gesetzl_Vertret');
    // item.CriteriaAdd('Azubi_ID_Gesetzl_Vertret', this.globalResources.WebAccProfile.KontaktID);

    // let item2 = DataFilter.ItemAdd('Filter Visibility = Public');
    // item2.CriteriaAdd('Vorg_ID_Visibility', 'PUBLIC');

    //this.HttpServiceComponent.dataGet('schoolDODataAntrVorgSumPortal', false, [], DataFilter)
    this.HttpServiceComponent.HttpServiceGetRequest('school/data/antrVorgSumPortal', '')
    .subscribe(data => {
      // console.log(data);
      for (let i = 0; i < data['Dataset'].Data.length; i++) {
        this.menuMessageBadge = this.menuMessageBadge + parseInt(data['Dataset'].Data[i].Vorg_Aus_Anz_Ungelesen);
        // console.log('menuMessageBadge', this.menuMessageBadge);
      }

    },
    error => {
      console.log('Error Get-Request: ', error);
      alert(  'Bei dem Get-Request ist ein Fehler aufgetreten:\n'
            + error.error.Error.ErrCode + '\n'
            + error.error.Error.ErrMsg + '\n\n'
            + error.message );
          },
    () => {
      // console.log('Get Request completed');
      this.translateMenuItems();
    });

  }



  //====== Methods: Actions

  translateMenuItems() {

    //Wird nur bei language-change getriggert
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      //Items initial leeren damit die übersetzten Items nicht dazu kommen.
      this.MenuUser = [];

      this.translationMenuItems.map((item) => {
        //$REV MP 2023-02-20: die badge-property wird im p-menu mit unserer aktuellen Version noch nicht unterstützt, deshalb Einbau einer eigenen Badge bein pushen der translation.
        if (item.translationLabel === 'user_menu.messages' && this.menuMessageBadge > 0) {
          this.translate.get(item.translationLabel).subscribe((res)=>{
            this.MenuUser.push(
               {label: res +'<span class="vsMenuBadge">'+this.menuMessageBadge+'</span>',
                icon: item.icon,
                command: item.command,
                escape: item?.escape,
              });
          });
        }
        else {
          this.translate.get(item.translationLabel).subscribe((res)=>{
            this.MenuUser.push(
               {label: res,
                icon: item.icon,
                command: item.command,
                escape: item?.escape,
              });
          });
        }

      });
    });

    //$REV MP 2023-02-20: Falls Messages vorhanden sind, werden die translationItems neu erstellt.
    if (this.menuMessageBadge > 0) {

      //Items initial leeren damit die übersetzten Items nicht dazu kommen.
      this.MenuUser = [];

      this.translationMenuItems.map((item) => {

        if (item.translationLabel === 'user_menu.messages' && this.menuMessageBadge > 0) {
          this.translate.get(item.translationLabel).subscribe((res)=>{
            this.MenuUser.push(
               {label: res +'<span class="vsMenuBadge">'+this.menuMessageBadge+'</span>',
                icon: item.icon,
                command: item.command,
                escape: item?.escape,
              });
          });
        }
        else {
          this.translate.get(item.translationLabel).subscribe((res)=>{
            this.MenuUser.push(
               {label: res,
                icon: item.icon,
                command: item.command,
                escape: item?.escape,
              });
          });
        }

      });
    }
  }


  public doHandleChangeEventAndSetSelectedLang(event) {
    this.value = event.value;
  }

  public doHandleKeyUpEventAndChangeLang(event) {
    const idToSearchFor = this.value.ID;
    const objectWithSpecificId = this.GUILanguageList.find(
      obj => obj.ID === idToSearchFor
    );

    this.GUILanguageSelected = objectWithSpecificId;
    this.translate.use(this.GUILanguageSelected.ID);
  }


  public actionLogin(): void {
    // [LABOR]
    console.log('>>>> appHeader: actionLogin()');
    this.router.navigateByUrl('/login');
  } // actionLogin

  public actionLogout(): void {
    // [LABOR]
    console.log('>>>> appHeader: actionLogout()');
    this.globalResources.WebAccProfile.Logout();

    //Bei ausloggen das dataModel leeren
    this.dataModel.EmptyData();

    //Bei ausloggen localStorage leeren
    localStorage.removeItem('accConfirm');
    // localStorage.removeItem('location');

    this.router.navigateByUrl('/home');
  } // actionLogin



  public navHome(): void {
    console.log('>>>> appHeader: navHome()');
    this.router.navigateByUrl('/home');
  } // navHome



  //------------------------------------------------------------------------------------------------
  // Method:  doDropdownGUILanguageOnClick
  //
  // Args:    a_iLangIndex            Index der ausgewählten Sprache bezogen auf GUILanguageList
  //
  // Result:  ./.
  //
  // Notes:   Ereignisbehandlung: Sprachauswahl
  //------------------------------------------------------------------------------------------------

  public doDropdownGUILanguageOnClick(a_iLangIndex: number) {
    console.log('>>>> Language selected:  ', a_iLangIndex);

    if (this.GUILanguageSelected.ID != this.GUILanguageList[a_iLangIndex].ID) {
      this.GUILanguageSelected = this.GUILanguageList[a_iLangIndex];
      this.translate.use(this.GUILanguageSelected.ID);
    }

  } // doDropdownGUILanguageOnClick


  //------------------------------------------------------------------------------------------------
  // Method:  doDropdownGUILanguageSetAsStandard
  //
  // Args:    ./.
  //
  // Result:  ./.
  //
  // Notes:   Ereignisbehandlung: Ausgwählte Sprache als Standard speichern
  //------------------------------------------------------------------------------------------------

  // [MOCKUP]
  public doDropdownGUILanguageSetAsStandard() {
    alert('Ausgewählte Sprache als Standard einstellen:  ' + this.GUILanguageSelected.Caption);
  }



  //------------------------------------------------------------------------------------------------
  // Method:  mnuUser...
  //
  // Args:    ./.
  //
  // Result:  ./.
  //
  // Notes:   Ereignisbehandlung: User Menu Items
  //------------------------------------------------------------------------------------------------

  public mnuAccMyProfile_OnClick(): void {
    // this.messageService.add({severity: 'warn',  summary: 'Work In Progress',  detail: 'Mein Benutzerprofil'});
    this.router.navigateByUrl('profile');
  } // mnuAccMyProfile_OnClick


  public mnuAccConfig_OnClick(): void {
    this.messageService.add({severity: 'warn',  summary: 'Work In Progress',  detail: 'Mein Einstellungen'});
  } // mnuAccConfig_OnClick


  // public mnuAccLogout_OnClick(): void {
  //   this.globalResources.UserProfile.Logout();
  // } // mnuUserLogout_OnClick

  public mnuAccMessage_OnClick(): void {
    this.router.navigateByUrl('mymessagelist');
  } // mnuAccMessage_OnClick

  public mnuAccAntragList_OnClick(): void {
    this.router.navigateByUrl('main');
  } // mnuAccAntragList_OnClick


  public mnuAccPwdChange_OnClick(): void {

      //bei jedem Aufruf wird der Titel neu übersetzt.
      this.translationDialogItems.map((item) => {
        this.translate.get(item.header).subscribe((res)=>{

          this.refUserPwdChangeDlg = this.dialogService.open(TsysViewAccPwdChangeDlg, {
            data:         item.data,
            header:       res,
            width:        item.width,
            styleClass:   item.styleClass,
            style:        item.style,
            baseZIndex:   item.baseZIndex

          });
        });
      });

    this.refUserPwdChangeDlg.onClose.subscribe((Data: any) =>{
    });

  } // mnuUserAntragList_OnClick



} // TappHeader



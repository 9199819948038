<!-- Wird nicht benötigt, da container-xxl und eine min-height schon in der schoolViewAntragEdit.component.html vorhanden sind-->
<!--<div class="container-xxl"  style="margin-top: 1.5rem; margin-bottom: 1.5rem; min-height: 608px; color: #808080; ">-->

<!-- <div style="display: flex; font-size: 1.5rem; font-weight: bold; color: #f07918;  margin-top: 3rem; margin-bottom: 1.5rem; justify-content: center">
    {{this.ViewProperties.ViewTitle}} | Work In Progress
  </div> -->

<!-- <span *ngIf="data"> -->
  <form [formGroup]="this.editForm">

    <vsLayoutGroup class="col-12" Orientation="horizontal" MediumGutterWidth=true>

      <vsLayoutGroup class="col" MediumGutterWidth=true Orientation="horizontal">

        <vsLayoutGroup class="col-12" Orientation="horizontal" MediumGutterWidth=true>

          <vsLayoutGroup class="col-12" isPanel=true panelTitleVisible=true panelTitleText="Abholzeiten"
            Orientation="horizontal">

            <vsLayoutGroup class="col-12" Orientation="horizontal">

              <vsLayoutGroup class="col-2">
                <div class="vsFormTextDiv"><b>{{'application.individual.table_heading_monday'| translate}}</b></div>
              </vsLayoutGroup>

              <vsLayoutGroup class="col-2">
                <vsInputCheckBox label="{{'application.individual.bring_to'| translate}}" labelPosition="right" [labelPlain]=true labelWidth="100px" FrmCtlName="Checkbox_PickupHome_Monday"></vsInputCheckBox>
              </vsLayoutGroup>

              <vsLayoutGroup class="col-3">
                <vsInputDateTime FrmCtlName="Time_PickupHome_Monday" label="{{'application.individual.school_start'| translate}}" labelWidth="110px" KennTimeOnly=true></vsInputDateTime>
              </vsLayoutGroup>

              <vsLayoutGroup class="col-2">
                <vsInputCheckBox label="{{'application.individual.pick_up'| translate}}" labelPosition="right" [labelPlain]=true labelWidth="100px" FrmCtlName="Checkbox_PickupSchool_Monday"></vsInputCheckBox>
              </vsLayoutGroup>

              <vsLayoutGroup class="col-3">
                <vsInputDateTime FrmCtlName="Time_PickupSchool_Monday" label="{{'application.individual.school_end'| translate}}" labelWidth="110px" KennTimeOnly=true></vsInputDateTime>
              </vsLayoutGroup>


            </vsLayoutGroup>

            <vsLayoutGroup class="col-12" Orientation="horizontal">

              <vsLayoutGroup class="col-2">
                <div class="vsFormTextDiv"><b>{{'application.individual.table_heading_tuesday'| translate}}</b></div>
              </vsLayoutGroup>

              <vsLayoutGroup class="col-2">
                <vsInputCheckBox label="{{'application.individual.bring_to'| translate}}" labelPosition="right" [labelPlain]=true labelWidth="100px" FrmCtlName="Checkbox_PickupHome_Tuesday"></vsInputCheckBox>
              </vsLayoutGroup>

              <vsLayoutGroup class="col-3">
                <vsInputDateTime FrmCtlName="Time_PickupHome_Tuesday" label="{{'application.individual.school_start'| translate}}" labelWidth="110px" KennTimeOnly=true></vsInputDateTime>
              </vsLayoutGroup>

              <vsLayoutGroup class="col-2">
                <vsInputCheckBox label="{{'application.individual.pick_up'| translate}}" labelPosition="right" [labelPlain]=true labelWidth="100px" FrmCtlName="Checkbox_PickupSchool_Tuesday"></vsInputCheckBox>
              </vsLayoutGroup>

              <vsLayoutGroup class="col-3">
                <vsInputDateTime FrmCtlName="Time_PickupSchool_Tuesday" label="{{'application.individual.school_end'| translate}}" labelWidth="110px" KennTimeOnly=true></vsInputDateTime>
              </vsLayoutGroup>


            </vsLayoutGroup>

            <vsLayoutGroup class="col-12" Orientation="horizontal">

              <vsLayoutGroup class="col-2">
                <div class="vsFormTextDiv"><b>{{'application.individual.table_heading_wednesday'| translate}}</b></div>
              </vsLayoutGroup>

              <vsLayoutGroup class="col-2">
                <vsInputCheckBox label="{{'application.individual.bring_to'| translate}}" labelPosition="right" [labelPlain]=true labelWidth="100px" FrmCtlName="Checkbox_PickupHome_Wednesday"></vsInputCheckBox>
              </vsLayoutGroup>

              <vsLayoutGroup class="col-3">
                <vsInputDateTime FrmCtlName="Time_PickupHome_Wednesday" label="{{'application.individual.school_start'| translate}}" labelWidth="110px" KennTimeOnly=true></vsInputDateTime>
              </vsLayoutGroup>

              <vsLayoutGroup class="col-2">
                <vsInputCheckBox label="{{'application.individual.pick_up'| translate}}" labelPosition="right" [labelPlain]=true labelWidth="100px" FrmCtlName="Checkbox_PickupSchool_Wednesday"></vsInputCheckBox>
              </vsLayoutGroup>

              <vsLayoutGroup class="col-3">
                <vsInputDateTime FrmCtlName="Time_PickupSchool_Wednesday" label="{{'application.individual.school_end'| translate}}" labelWidth="110px" KennTimeOnly=true></vsInputDateTime>
              </vsLayoutGroup>


            </vsLayoutGroup>

            <vsLayoutGroup class="col-12" Orientation="horizontal">

              <vsLayoutGroup class="col-2">
                <div class="vsFormTextDiv"><b>{{'application.individual.table_heading_thursday'| translate}}</b></div>
              </vsLayoutGroup>

              <vsLayoutGroup class="col-2">
                <vsInputCheckBox label="{{'application.individual.bring_to'| translate}}" labelPosition="right" [labelPlain]=true labelWidth="100px" FrmCtlName="Checkbox_PickupHome_Thursday"></vsInputCheckBox>
              </vsLayoutGroup>

              <vsLayoutGroup class="col-3">
                <vsInputDateTime FrmCtlName="Time_PickupHome_Thursday" label="{{'application.individual.school_start'| translate}}" labelWidth="110px" KennTimeOnly=true></vsInputDateTime>
              </vsLayoutGroup>

              <vsLayoutGroup class="col-2">
                <vsInputCheckBox label="{{'application.individual.pick_up'| translate}}" labelPosition="right" [labelPlain]=true labelWidth="100px" FrmCtlName="Checkbox_PickupSchool_Thursday"></vsInputCheckBox>
              </vsLayoutGroup>

              <vsLayoutGroup class="col-3">
                <vsInputDateTime FrmCtlName="Time_PickupSchool_Thursday" label="{{'application.individual.school_end'| translate}}" labelWidth="110px" KennTimeOnly=true></vsInputDateTime>
              </vsLayoutGroup>

            </vsLayoutGroup>

            
            <vsLayoutGroup class="col-12" Orientation="horizontal">

              <vsLayoutGroup class="col-2">
                <div class="vsFormTextDiv"><b>{{'application.individual.table_heading_friday'| translate}}</b></div>
              </vsLayoutGroup>

              <vsLayoutGroup class="col-2">
                <vsInputCheckBox label="{{'application.individual.bring_to'| translate}}" labelPosition="right" [labelPlain]=true labelWidth="100px" FrmCtlName="Checkbox_PickupHome_Friday"></vsInputCheckBox>
              </vsLayoutGroup>

              <vsLayoutGroup class="col-3">
                <vsInputDateTime FrmCtlName="Time_PickupHome_Friday" label="{{'application.individual.school_start'| translate}}" labelWidth="110px" KennTimeOnly=true></vsInputDateTime>
              </vsLayoutGroup>

              <vsLayoutGroup class="col-2">
                <vsInputCheckBox label="{{'application.individual.pick_up'| translate}}" labelPosition="right" [labelPlain]=true labelWidth="100px" FrmCtlName="Checkbox_PickupSchool_Friday"></vsInputCheckBox>
              </vsLayoutGroup>

              <vsLayoutGroup class="col-3">
                <vsInputDateTime FrmCtlName="Time_PickupSchool_Friday" label="{{'application.individual.school_end'| translate}}" labelWidth="110px" KennTimeOnly=true></vsInputDateTime>
              </vsLayoutGroup>

            </vsLayoutGroup>

            <vsLayoutGroup class="col-12" Orientation="horizontal">

              <vsLayoutGroup class="col-2">
                <div class="vsFormTextDiv"><b>{{'application.individual.table_heading_saturday'| translate}}</b></div>
              </vsLayoutGroup>

              <vsLayoutGroup class="col-2">
                <vsInputCheckBox label="{{'application.individual.bring_to'| translate}}" labelPosition="right" [labelPlain]=true labelWidth="100px" FrmCtlName="Checkbox_PickupHome_Saturday"></vsInputCheckBox>
              </vsLayoutGroup>

              <vsLayoutGroup class="col-3">
                <vsInputDateTime FrmCtlName="Time_PickupHome_Saturday" label="{{'application.individual.school_start'| translate}}" labelWidth="110px" KennTimeOnly=true></vsInputDateTime>
              </vsLayoutGroup>

              <vsLayoutGroup class="col-2">
                <vsInputCheckBox label="{{'application.individual.pick_up'| translate}}" labelPosition="right" [labelPlain]=true labelWidth="100px" FrmCtlName="Checkbox_PickupSchool_Saturday"></vsInputCheckBox>
              </vsLayoutGroup>

              <vsLayoutGroup class="col-3">
                <vsInputDateTime FrmCtlName="Time_PickupSchool_Saturday" label="{{'application.individual.school_end'| translate}}" labelWidth="110px" KennTimeOnly=true></vsInputDateTime>
              </vsLayoutGroup>

            </vsLayoutGroup>

            <vsLayoutGroup class="col-12" Orientation="horizontal">

              <vsLayoutGroup class="col-2">
                <div class="vsFormTextDiv"><b>{{'application.individual.table_heading_sunday'| translate}}</b></div>
              </vsLayoutGroup>

              <vsLayoutGroup class="col-2">
                <vsInputCheckBox label="{{'application.individual.bring_to'| translate}}" labelPosition="right" [labelPlain]=true labelWidth="100px" FrmCtlName="Checkbox_PickupHome_Sunday"></vsInputCheckBox>
              </vsLayoutGroup>

              <vsLayoutGroup class="col-3">
                <vsInputDateTime FrmCtlName="Time_PickupHome_Sunday" label="{{'application.individual.school_start'| translate}}" labelWidth="110px" KennTimeOnly=true></vsInputDateTime>
              </vsLayoutGroup>

              <vsLayoutGroup class="col-2">
                <vsInputCheckBox label="{{'application.individual.pick_up'| translate}}" labelPosition="right" [labelPlain]=true labelWidth="100px" FrmCtlName="Checkbox_PickupSchool_Sunday"></vsInputCheckBox>
              </vsLayoutGroup>

              <vsLayoutGroup class="col-3">
                <vsInputDateTime FrmCtlName="Time_PickupSchool_Sunday" label="{{'application.individual.school_end'| translate}}" labelWidth="110px" KennTimeOnly=true></vsInputDateTime>
              </vsLayoutGroup>

            </vsLayoutGroup>

            <vsLayoutGroup class="col-12" Orientation="horizontal">
              
              <vsLayoutGroup class="col-2" Orientation="vertical">
                <vsInputCheckBox label="{{'application.individual.wheelchair'| translate}}" labelPosition="right" [labelPlain]=true labelWidth="100px" FrmCtlName="Wheelchair"></vsInputCheckBox>
                <vsInputCheckBox label="{{'application.individual.accomp_person'| translate}}" labelPosition="right" [labelPlain]=true labelWidth="100px" FrmCtlName="Assistant"></vsInputCheckBox>
              </vsLayoutGroup>

              <vsLayoutGroup class="col-10">
                <div class="vsInputLabel" style="width: 200px;">{{'application.individual.add_info'| translate}}:</div>
                <vsInputMemo FrmCtlName="AdditInfoMsg" NoLabel="true" Rows="8" maxLength=4000 counter=true ></vsInputMemo>
              </vsLayoutGroup>

            </vsLayoutGroup>


          </vsLayoutGroup>


        </vsLayoutGroup>

      </vsLayoutGroup>

    </vsLayoutGroup>

  </form>

  <div style="margin-top: 1rem; margin-bottom: 1rem;">
    <button pButton label="{{'application.button_back'| translate}}" (click)="actionStepPrev()" class="vsElternButton vsBack"
      style="margin-right: 10px;"></button>
    <button pButton label="{{'application.button_forward'| translate}}" (click)="actionStepNext()" class="vsElternButton vsForward"></button>
  </div>
<!-- </span> -->

<p-dialog header="{{'dialog.error_fill_heading'| translate}}" [(visible)]="displayDialogError" [baseZIndex]="10000" class="vsDialog vsDanger vsRequired">
  <p [innerHtml]="'dialog.error_fill_text'| translate"></p>
  <ng-template pTemplate="footer">
      <button pButton pRipple type="button" (click)="displayDialogError=false" label="{{'dialog.error_fill_button_ok'| translate}}" class="vsToolbarItem vsToolbarItemOK vsMb-2">
        <span>
          <img alt="close" src="/assets/img/Icons/vs-check-circle.svg" />
        </span>
      </button>
  </ng-template>
</p-dialog>



<!-- <div style="margin-top: 3rem; margin-bottom: 1.5rem;">
    <button pButton label="Weiter" (click)="actionStepNext()" class="vsElternButton"></button>
  </div> -->

<!--</div>-->
<!-- Ende container-xxl -->

<!-- <pre>{{ this.editForm.value | json}}</pre> -->

//--------------------------------------------------------------------------------------------------
//  AMIS7school - Externes Portal: Eltern
//
//  Domain:   School
//  Title:    Grundantrag Schülerbeförderung (Edit View)
//  Notes:    MOCKUP
//
//
//  H I S T O R Y
//
//  2021-08-05  TC   Initial Version
//--------------------------------------------------------------------------------------------------

import {  Component, ViewChild
        , OnInit
        , ComponentFactoryResolver}         from '@angular/core';

import { Router }                           from '@angular/router';

import { LangChangeEvent, TranslateService }    from '@ngx-translate/core';

import { FormGroup, FormControl, Validators }           from '@angular/forms';

import  * as schoolDataModel                from 'AMIS7school_Portal_Eltern/src/app/school/schoolDataModel.service';

// PrimeNG
import { MenuItem }                         from 'primeng/api';
import { MessageService }                   from 'primeng/api';


// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import * as vsView                          from '@vsLib/View/components/vsView/vsView.component';
import * as vsGlobalResources               from '@vsLib/Services/vsGlobalResources.service';
import * as vsTabControl                    from '@vsLib/Components/vsTab/vsTabControl.component';

import { TvsHttpServiceComponent }          from '@vsLib/Services/vsHttp.service.component';


// AMIS7core
import * as AMIS7coreConst                  from '@AMIS7core/$Common/comConst';
import { formatDate } from '@angular/common';
import { asLiteral } from '@angular/compiler/src/render3/view/util';

type TranslationLookup = {
  Name:         string;
  Value:        string;
  PKValue:      string;
}


@Component({
  selector:       'schoolViewAntragEdit_Antrag_Uebernahme',
  templateUrl:  './schoolViewAntragEdit_Antrag_Uebernahme.component.html',
  // styleUrls:   ['./schoolViewAntragEdit_Antrag.component.scss']
})
export class TschoolViewAntragEdit_Antrag_Uebernahme extends vsView.TvsView implements OnInit {


  //====== Properties (PRIVATE)

  // ./.

  //====== Properties (PUBLIC)

  public      editForm:                     FormGroup               = new FormGroup({});

  public KennTransportOptions:              boolean = false;

  public KennApplicationReason:             boolean = false;

  public translationLookupTranspArt:        TranslationLookup[]        = [];

  public translationLookupTranspArtTranslated: any[] = [];

  public translationLookupApplicationReason:  TranslationLookup[]    = [];

  public translationLookupApplicationReasonTranslated: TranslationLookup[] = [];


  // public lookupArtName:               any[];

  public displayDialogError: boolean   = false;

  //====== Constructor

  constructor(protected router:                     Router,
              public    globalResources:            vsGlobalResources.TvsGlobalResources
            , public    dataModel:                  schoolDataModel.TschoolDataModel
            , protected messageService:             MessageService
            , protected HttpServiceComponent:       TvsHttpServiceComponent
            , public    translate:                  TranslateService     // [ngx-translate]
  ) {
    super(router, globalResources);

    this.ViewProperties.Instance        = this;
    this.ViewProperties.ViewTitle       = 'Antragsauswahl';

    this.ViewProperties.ProgFuncID      = null;
    this.ViewProperties.DataSourceID    = null;
    this.ViewProperties.QueryFields     = [];

    this.translationLookupTranspArt = [
      {Name: 'application.transp_Option_1', Value: 'Nutzung öffentlicher Verkehrsmittel (Schülerticket Hessen)',  PKValue: 'TICKET'},
      {Name: 'application.transp_Option_2', Value: 'Nutzung privater Verkehrsmittel',  PKValue: 'PKW'},
      {Name: 'application.transp_Option_3', Value: 'Sammelbeförderung (Taxi)',  PKValue: 'TAXI'}
    ];

    this.translationLookupApplicationReason = [
      {Name: 'application.application_Reason_1', Value: 'Einschulung',  PKValue: 'EINSCHUL'},
      {Name: 'application.application_Reason_2', Value: 'Umzug',  PKValue: 'UMZUG'},
      {Name: 'application.application_Reason_3', Value: 'Schulwechsel',  PKValue: 'SCHULWECHS'}
    ];

    if(vsCommon.vsVarAssigend(this.dataModel.KennTransportOptions)) {
      this.KennTransportOptions = this.dataModel.KennTransportOptions;
    }

    if(vsCommon.vsVarAssigend(this.dataModel.KennApplicationReason)) {
      this.KennApplicationReason = this.dataModel.KennApplicationReason;
    }

  
  // this.lookupArtName = [
  //   {Art_Name: 'Antrag auf Übernahme von Schülerfahrtkosten',      Art_ID: 'U'},
  //   {Art_Name: 'Antrag auf Erstattung von Schülerfahrtkosten',      Art_ID: 'E'}
  // ];


    // this.Anrede = [
    //   {Anrede: 'Herr',      Anrede_ID: 'HERR'},
    //   {Anrede: 'Frau',      Anrede_ID: 'FRAU'},
    //   // {Anrede: 'Divers',    Anrede_ID: 'DIVERS'},
    //   {Anrede: '<Unbek>',   Anrede_ID: 'UNBEK'}
    // ];


  } // constructor

  //====== Methods: NG event handler

  ngOnInit(): void {

    
    //TODO: DATUM GUELT BEG hinzufügen.

    if(!vsCommon.vsStringHasValue(this.dataModel.antrag_Datum)) {
      this.dataModel.antrag_Datum = vsCommon.RetrieveDateStrNow();
      console.log('Antragsdatum', this.dataModel.antrag_Datum);
    }

    // this.editForm.addControl('Art_Name',       new FormControl(null));
    this.editForm.addControl('Antr_Datum',     new FormControl(this.dataModel.antrag_Datum));


    this.editForm.controls['Antr_Datum'].setValue(this.dataModel.antrag_Datum);

    if(this.KennTransportOptions) {
      this.editForm.addControl('Transp_Art',    new FormControl(this.dataModel.transp_Art));
    }

    if(this.KennApplicationReason) {
      this.editForm.addControl('Transp_Reason',    new FormControl(this.dataModel.transp_Reason));
    }

     //Initial Übersetzungen in translationLookupTranspArtTranslated pushen

     this.translationLookupTranspArt.map((item) => {
      this.translate.get(item.Name).subscribe((res)=>{
            this.translationLookupTranspArtTranslated.push(
              {
                Name:       res,
                Value:      item.Value,
                PKValue:    item.PKValue,
              }
            ) 
          });
        });

      this.translationLookupApplicationReason.map((item) => {
          this.translate.get(item.Name).subscribe((res)=>{
              this.translationLookupApplicationReasonTranslated.push(
                 {
                    Name:       res,
                    Value:      item.Value,
                    PKValue:    item.PKValue,
                }
              ) 
             });
          });

    this.translateItems();


  } // ngOnInit

  ngAfterViewInit(): void {

    if (vsCommon.vsStringHasValue(this.dataModel.transp_Art)) {
      this.editForm.controls['Transp_Art'].setValue(vsCommon.vsRetrieveRecordByPKValue(this.translationLookupTranspArt, this.dataModel.transp_Art));
    }
    
    //$REV MP 2023-03-17: Wenn in der Übersetzungsdatei kein Text für eine Erläuterung/Erklärung vorhanden ist wird das HTML-Element entfernt.
    // if(this.explanation_header.nativeElement.innerText === '') {
    //   this.explanation_header.nativeElement.remove();
    // }
    // if(this.explanation_text.nativeElement.innerText === '') {
    //   this.explanation_text.nativeElement.remove();
    // }
    // if(this.explanation_header2.nativeElement.innerText === '') {
    //   this.explanation_header2.nativeElement.remove();
    // }
    // if(this.explanation_text2.nativeElement.innerText === '') {
    //   this.explanation_text2.nativeElement.remove();
    // }
  }


  translateItems() { 

    if(this.KennTransportOptions) {
          //Wird nur bei language-change getriggert
          this.translate.onLangChange.subscribe((event: LangChangeEvent) => {


            //Übersetzungen in translationLookupTranspArtTranslated pushen
            //Items initial leeren damit die übersetzten Items nicht dazu kommen.
            this.translationLookupTranspArtTranslated = []
    
            this.translationLookupTranspArt.map((item) => {
              this.translate.get(item.Name).subscribe((res)=>{
                    this.translationLookupTranspArtTranslated.push(
                      {
                        Name:       res,
                        Value:      item.Value,
                        PKValue:    item.PKValue,
                      }
                    ) 
                  });
                });
            });
    }

    if(this.KennApplicationReason) {
      //Wird nur bei language-change getriggert
      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {

        //Übersetzungen in translationLookupTranspArtTranslated pushen
        //Items initial leeren damit die übersetzten Items nicht dazu kommen.
        this.translationLookupApplicationReasonTranslated = []

        this.translationLookupApplicationReason.map((item) => {
          this.translate.get(item.Name).subscribe((res)=>{
                this.translationLookupApplicationReasonTranslated.push(
                  {
                    Name:       res,
                    Value:      item.Value,
                    PKValue:    item.PKValue,
                  }
                ) 
              });
            });
        });
      }
    }


  public actionStepPrev(): void {
    this.dataSave();
    this.dataModel.EmptyData();
    this.router.navigate(['landing']);
  }

  public actionStepNext(): void {

    let bOK:        boolean       = true;

    
    // if (bOK) {
    //   // this.editForm.get('Art_Name').setValidators(Validators.required);
    //   // this.editForm.get('Art_Name').updateValueAndValidity();

    //   // this.editForm.get('Antr_Datum').setValidators(Validators.required);
    //   // this.editForm.get('Antr_Datum').updateValueAndValidity();

    //   bOK = !this.editForm.invalid;
    //   this.displayDialogError = !bOK;

    // }

    if (bOK) {
      this.dataSave();
      this.router.navigate(['antragEdit/studentSelection']);
    }

  } // actionStepNext()



  public dataSave() :void{

    // this.dataModel.antrag_Art      =  this.editForm.controls['Art_Name'].value;
    this.dataModel.antrag_Datum    =  this.editForm.controls['Antr_Datum'].value;
    // this.dataModel.antrag_Datum    =  vsCommon.DateStrToISO8061(this.editForm.controls['Antr_Datum'].value);

    //TODO: DATUM GUELT BEG hinzufügen.
    // this.dataModel.antr_Datum_Guelt_Beg      =  this.editForm.controls['Datum_Guelt_Beg'].value;

    console.log('this.dataModel.antrag_Art ' , this.dataModel.antrag_Art)
    // console.log('this.editForm.controls_Art_Name' , this.editForm.controls['Art_Name'])

    if (this.KennTransportOptions) {
      this.dataModel.transp_Art                =  this.editForm.controls['Transp_Art'].value?.PKValue;
      this.dataModel.transp_Art_Rec            =  this.editForm.controls['Transp_Art']?.value;
    }

    if (this.KennApplicationReason) {
      this.dataModel.transp_Reason                =  this.editForm.controls['Transp_Reason'].value?.PKValue;
      this.dataModel.transp_Reason_Rec            =  this.editForm.controls['Transp_Reason']?.value;
    }

  }


} // schoolViewAntragEdit_Student



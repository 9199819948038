//--------------------------------------------------------------------------------------------------
//  vedisys Library
//
//  Titel:  vsGlobalResources
//  Notes:  Service: Global Resources (Singleton)
//
//
//  H I S T O R Y
//
//  2021-06-24  TC   Genesis
//--------------------------------------------------------------------------------------------------

import { Injectable, Injector }                       from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import { TvsAppDispatcher }                 from '@vsLib/Services/vsAppDispatcher.service';
import { TvsAppViewList }                   from '@vsLib/vsAppViewList';
import { Observable } from 'rxjs';




// App    $Rev TC 2021-05-27: in der vsLib darf natürlich nichts aus irgendeiner app importiert und verwendet werden
// import { MainView }                         from 'AMIS7school/src/app/components/$main/main-view.component';



//
// TvsGlobalResources
//
// Service: Global Resources (Singleton)
//
@Injectable({
  providedIn: 'root',
})
export class TvsGlobalResources {

  public  static    InstanceCounter:      number        = 0;


  //====== Properties (PRIVATE)

  // ./.


  //====== Properties (PUBLIC)

  // To ensure the correct creation sequence the following objects should be created in the constructor
  public    AppInfo:                TvsAppInfo;


  public    AppDispatcher:          TvsAppDispatcher;
  public    AppViewList:            TvsAppViewList;       // Liste der AppViewItems (alle existierenden Views der App)
  public    UserProfile:            TvsUserProfile;       // Benutzerprofil
  public    MandProfile:            TvsMandProfile;       // Mandantenprofil
  public    WebAccProfile:          TvsWebAccProfile;     // WebAccountprofil

  public    AppConfigData:          TvsAppConfigData      = new TvsAppConfigData();


  public    ViewMain:               any;                  // TODO: TvsViewMain als Basisklasse für die ViewMain
  public    AccessToken:            string  = '';
  public    LoginID:                string  = '';


  // $Anmerkung TC 2021-06-29:
  //
  // Beispiel für die Verwendung des Injectors:
  //   service = this.globalResources.AppInjector.get(TxyzWichtigerService)
  //
  // Dadurch kann jedes Objekt auch Services instanzieren, auch wenn das Objekt selbst keine Komponente ist,
  // dadurch also auch keine automatische Injection eines Services über den Kontruktor möglich ist.
  //
  // Soweit die Theorie... allerdings funktioniert das noch nicht so wirklich...
  //
  public    AppInjector:            Injector;

  public    AzubiID:                any;                  // das wird benötigt, um Daten an Edits zu übergeben (AzubiStaett-Edit)
  public    KontaktID:              any;                  // das wird benötigt, um Daten an Edits zu übergeben (Vorg-Edit)


  //====== Constructor

  constructor(protected     HttpClient: HttpClient) {
    TvsGlobalResources.InstanceCounter++;
    // console.log("TvsGlobalResources.constructor(): iInstanceCounter = " + TvsGlobalResources.InstanceCounter.toString());


    //---- Child objects (composition)

    this.AppInfo          = new TvsAppInfo();
    this.AppDispatcher    = new TvsAppDispatcher(this);       // $Rev TC 2020-12-31: dies führt zu einer zirkulären Abhängigkeit (Warnmeldung)! Funktioniert aber anscheinend trotzdem...
    this.AppViewList      = new TvsAppViewList();
    this.UserProfile      = new TvsUserProfile(this, HttpClient);
    this.MandProfile      = new TvsMandProfile(HttpClient);
    this.WebAccProfile    = new TvsWebAccProfile(this, HttpClient);

  } // constructor



  //====== Methods (PUBLIC)


  //------------------------------------------------------------------------------------------------
  // Method:  ...
  //
  // Args:    ...
  //
  // Result:  ...
  //
  // Notes:   ...
  //------------------------------------------------------------------------------------------------

  // ./.

  public RetrieveURLBase(a_bGenericDataRequest: boolean = true): string {

    let sRequestProtocol      =  this.AppConfigData.requestProtocol;
    let sRequestIPAddress     =  this.AppConfigData.requestIPAddress;
    let sRequestPortNumber    =  this.AppConfigData.requestPortNumber;
    let sRequestPath          =  this.AppConfigData.requestPath;
// console.log('AppConfigData: ', this.AppConfigData);
    //---- local (Entwicklung)

    // let sRequestProtocol      = 'http';
    // let sRequestIPAddress     = 'localhost';
    // let sRequestPortNumber    = '2001';
    // let sRequestPath          =  '';


    //---- APPS (Development)

    // let sRequestProtocol      = 'https';
    // let sRequestIPAddress     = '192.168.115.39';
    // let sRequestPortNumber    = '443';
    // sRequestPath              = 'servicesDev/';


    //---- APPS (QS)

    // let sRequestProtocol      = 'https';
    // let sRequestIPAddress     = '192.168.115.39';
    // let sRequestPortNumber    = '443';
    // sRequestPath              = 'servicesQS/';


    //---- APPS (Demo)

    // let sRequestProtocol      = 'https';
    // let sRequestIPAddress     = '192.168.115.39';
    // let sRequestPortNumber    = '443';
    // sRequestPath              = 'servicesDemo/';



    //---- Cloud Server: RTK | Test (QWERTIKO)

    // let sRequestProtocol      = 'http';
    // let sRequestIPAddress     = '185.30.157.135';
    // let sRequestPortNumber    = '80';
    // sRequestPath              = 'services/';

    // let sRequestProtocol      = 'https';
    // let sRequestIPAddress     = '<domain>>';
    // let sRequestPortNumber    = '443';
    // sRequestPath              = 'services/';



    //---- Cloud Server: VBK | Test (QWERTIKO)

    // let sRequestProtocol      = 'http';
    // let sRequestIPAddress     = '185.30.157.133';
    // let sRequestPortNumber    = '80';
    // sRequestPath              = 'services/';

    // let sRequestProtocol      = 'https';
    // let sRequestIPAddress     = '<domain>>';
    // let sRequestPortNumber    = '443';
    // sRequestPath              = 'services/';



    //---- Cloud Server: VRT | Test (IONOS)

    // let sRequestProtocol      = 'https';
    // let sRequestIPAddress     = '217.160.27.29';
    // let sRequestPortNumber    = '443';
    // sRequestPath              = 'services/';                // $Rev TC 2021-10-18: added to support reverse proxying


    //---- Cloud Server: VRT | Produktiv (QWERTIKO)

    // let sRequestProtocol      = 'https';
    // // let sRequestIPAddress     = '185.30.157.114';
    // let sRequestIPAddress     = 'bm.vrt-info.de';           // $Rev TC 2021-12-03: bei Verwendung eines offiziellen SSL-Zertifikates, muss der domain name verwendet werden
    // let sRequestPortNumber    = '443';
    // sRequestPath              = 'services/';



    //---- Cloud Server: Staging (IONOS) | mobility portal

    // let sRequestProtocol      = 'https';
    // // let sRequestIPAddress     = '217.160.27.29';
    // let sRequestIPAddress     = 'staging.mobility-portal.net';      // Bei Verwendung eines offiziellen SSL-Zertifikates, muss der domain name verwendet werden
    // let sRequestPortNumber    = '443';
    // sRequestPath              = 'api/';


    //---- Cloud Server: Staging (IONOS) | mobility portal | Apps

    // let sRequestProtocol      = 'https';
    // // let sRequestIPAddress     = '217.160.27.29';
    // let sRequestIPAddress     = 'staging.mobility-portal.net';     // Bei Verwendung eines offiziellen SSL-Zertifikates, muss der domain name verwendet werden
    // let sRequestPortNumber    = '443';
    // sRequestPath              = 'apiApps/';



    //---- Service Name
    let sRequestServiceName   = sRequestPath;                         // $Rev TC 2021-10-18: added to support reverse proxying


    if (a_bGenericDataRequest) {
      // let sRequestServiceName   = 'genericData';                   // $Rev TC 2021-06-09: added: generischer Data Service
      sRequestServiceName   = sRequestServiceName + 'genericData';    // $Rev TC 2021-10-18: added to support reverse proxying

      // return 'https://' + sRequestIPAddress + ':' + sRequestPortNumber + '/' + sRequestServiceName;
      return sRequestProtocol + '://' + sRequestIPAddress + ':' + sRequestPortNumber + '/' + sRequestServiceName;

    } else {

      // return 'https://' + sRequestIPAddress + ':' + sRequestPortNumber;
      return sRequestProtocol + '://' + sRequestIPAddress + ':' + sRequestPortNumber + '/' + sRequestServiceName;

    } // if

  } // RetrieveURLBase


} // TvsGlobalResources



//
// TvsAppInfo
//
// Service: Application Info (Singleton)
//
// [Labor] TODO: Verlagern in eigene Datei
//

@Injectable({
  providedIn: 'root',
})
export class TvsAppInfo {

  //====== Properties (PUBLIC)

  public  readonly    AppTitle:                   string              = '';
  public  readonly    AppDescriptionShort:        string              = '';
  public  readonly    AppVersionInfo:             TvsAppVersionInfo   = null;
  public              selectedLang?:              string              = 'DE-de';

  //====== Constructor

  constructor() {

    // [Labor] TODO: this data schould be loaded from a JSON configuration file
    this.AppTitle                     = 'AMIS®7 goes WEB';
    this.AppDescriptionShort          = 'MyPlayground (TC) | Spielwiese für Experimente';
    this.AppVersionInfo               = new TvsAppVersionInfo();

   } // constructor

} // TvsAppInfo



//
// TvsAppVersionInfo
//
// Service: Application Info (Singleton)
//
// [Labor] TODO: Verlagern in eigene Datei
//


export class TvsAppConfigData  {
  public version:                     string;
  public requestProtocol:             string;
  public requestIPAddress:            string;
  public requestPortNumber:           string;
  public requestPath:                 string;
  public requestAssetPath:            string;
  public styles:                      string;

  public KennUserImage:                      boolean;
  public KennAntragIndividual:               boolean;
  public KennGrundantragErstattung:          boolean;
  public KennAntragErstattung:               boolean;
  public KennAntragTicket:                   boolean;
  public KennOrtsteilRequired:               boolean;
  public KennAntragAzubi:                    boolean;
  public KennStrassenVerz:                   boolean = false
  //$REV MP 2023-09-25: Ausbildungsstättenangabe ist bei einigen Kunden nicht notwendig (VBK), bei anderen schon (SAMR)
  public KennAusbStaettRequired:             boolean;
  //$REV MP 2023-09-25: Bei SAMR soll das aktuell laufende Halbjahr mit angezeigt werden, bei anderen Kunden nicht (RTK)
  public KennErstattungRunningHalfYear:      boolean;
  //$REV MP 2023-10-18: Bei SAMR ist eine required Abfrage nach der Transportart zwingend Notwendig
  public KennTransportOptions:               boolean;
  //$REV MP 2024-05-16: Beim RTK ist eine Anfrage nach dem Antragsgrund notwendig.
  public KennApplicationReason:              boolean;

  //$REV MP 2024-11-25: Zum besseren kenntlich machen des Testsystems.
  public KennTestMode:                       boolean;

  public KennCMISList:                       boolean = false;

  public KennPoenalModul:                    boolean = false;

  public AMIS7_API_Orders:                   string = '';

  //$REV MP 2024-03-08: Beim VBK ist ein Impressum und eine Datenschutzseite notwendig
  public KennImprint:                        boolean;
  public KennDataProtection:                 boolean;


  public footerLinkFrequentQuestions: string;
  public footerLinkKontakt:           string;
  public footerLinkImprint:           string;
  public footerLinkDataSecurity:      string;
  public footerLinkImageCreator:      string;
  public footerLinkImageCreatorText:  string;

  public appType:                     string;

  public progModulID:                 string;

  public schoolIndividual?:            boolean;

  public eGovSadOnlineID:              number;
  public eGovSadOfflineID:             number;


//------ Constructor

constructor() {

  // [Labor] TODO: this data schould be loaded from a JSON configuration file
  this.version = '1.0';
  this.requestProtocol      = 'HTTPS';
  this.requestIPAddress     = 'localhost';
  this.requestPortNumber    = '2001';
  this.requestPath          = '';
  this.styles               = '';

} // constructor

}



export enum TvsAppVersionType {
    versionUndefined          = "<UNBEKANNT>>"
  , versionLab                = "LABOR"
  , versionAlpha              = "ALPHA"
  , versionBeta               = "BETA"
  , versionReleaseCandidate   = "RC"
  , versionFinal              = "FINAL"
} // TvsUserAccountType



export class TvsAppVersionInfo {

  //------ Properties (PUBLIC)

  public  readonly    AppVersionType:             TvsAppVersionType   = TvsAppVersionType.versionUndefined;
  public  readonly    AppVersionTypeName:         string              = null;
  public  readonly    AppVersionNumMajor:         number              = null;
  public  readonly    AppVersionNumMinor1:        number              = null;
  public  readonly    AppVersionNumMinor2:        number              = null;
  public  readonly    AppVersionNumBuild:         number              = null;


  //------ Constructor

  constructor() {

    // [Labor] TODO: this data schould be loaded from a JSON configuration file
    this.AppVersionType           = TvsAppVersionType.versionLab;
    this.AppVersionTypeName       = 'LABOR';
    this.AppVersionNumMajor       = 0;
    this.AppVersionNumMinor1      = 1;
    this.AppVersionNumMinor2      = 0;
    this.AppVersionNumBuild       = 9999;
  } // constructor



//====== Methods (PUBLIC)


//------------------------------------------------------------------------------------------------
// Method:  getAppVersionNumFormatted
//
// Args:    ./.
//
// Result:  Formatierter string
//
// Notes:   Berechneter Wert: Formatierte App-Versionsnummer als String
//------------------------------------------------------------------------------------------------

public getAppVersionNumFormatted(): string {
  let sResult:        string    = '';

  sResult = sResult + this.AppVersionNumMajor + '.' + this.AppVersionNumMinor1 + '.' + this.AppVersionNumMinor2;
  if (this.AppVersionType != TvsAppVersionType.versionFinal) {
    sResult = sResult + ' ' + this.AppVersionType;
  }

  return sResult;

} // getAppVersionNumFormatted

} // TvsAppVersionInfo



//
// TvsUserProfile
//
// Service: User Profile (Singleton)
//
// [Labor] TODO: Verlagern in eigene Dateien
//

export enum TvsUserAccountType {
    uatUndefined      = "uatUndefined"
  , uatInternal       = "uatInternal"
  , uatSystemUser     = "uatSystemUser"
  , uatWeb            = "uatWeb"
} // TvsUserAccountType


@Injectable({
  providedIn: 'root',
})

export class TvsWebAccProfile {
  private             FIsLoggedIn:            boolean               = false;
  private             FAccLoginID:            string                = null;
  private             FKontaktID:             string                = null;
  private             FGlobalRessources:      TvsGlobalResources    = null;
  //$Rev MP 2024-04-30: Erweiterung um Registrierungsdatum
  private             FaccErfassDatum:        string                = null;

  public  get         IsLoggedIn():           boolean               {return this.FIsLoggedIn}
  public  get         KontaktID():            string                {return this.FKontaktID}
  public  get         AccLoginID():           string                {return this.FAccLoginID};

  public get          accErfassDatum():       string                {return this.FaccErfassDatum};


  constructor(private       globalRessources: TvsGlobalResources
             ,protected     HttpClient:       HttpClient)
  {
    this.FGlobalRessources = this.globalRessources;

  }


  public dataLogin(a_sLoginID: string, a_sLoginPWD: string): Observable<any[]> {

    let sURL = this.FGlobalRessources.RetrieveURLBase(false) + 'webActionUserAccLogin';// + '?LoginID='  + a_sLoginID  + '&LoginPWD=' + a_sLoginPWD;
    // let sURL = "https://localhost:7005/authentication/login";
    // return this.HttpClient.get(sURL).pipe((data: any) => {return data;});

    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Authorization':  'Basic ' + btoa(a_sLoginID + ':' + a_sLoginPWD)
    //   })
    // };
    // return this.HttpClient.post(sURL, '', httpOptions).pipe((data: any) => {return data;});
    let envelope: vsCommon.TWebParamPwdChange_Envelope = new vsCommon.TWebParamPwdChange_Envelope();
    envelope.data.userID    = a_sLoginID;
    envelope.data.password  = a_sLoginPWD;

    // this.HttpServiceComponent.HttpServicePostRequest('sysActionUserPwdChange',
    // '&LoginID=' + this.globalResources.UserProfile.userID + '&LoginPwdNew=' + this.FormGroup.get('User_Pwd_New').value, '')
    return this.HttpClient.post(sURL, JSON.stringify(envelope)).pipe((data: any) => {return data;});

  }

  public Login(a_sLoginID: string, a_sLoginPWD: string) {

    this.dataLogin(a_sLoginID, a_sLoginPWD).subscribe(   // Portal-WebUser (Eltern)
      data => {
        let x: any = data;
        this.FIsLoggedIn = x.result;
        this.FaccErfassDatum = x.accErfassDatum;
        if (!this.FIsLoggedIn) {
          if (!x.result) {
          // ToDo: MP Messages übersetzen
          // Statt Alerts sollten an dieser Stelle Exceptions erzeugt werden. Diese sollten dann in der sysViewUserLogin.component.ts abgefangen werden können.
          // Je nach Exception eine bestimmte Fehlermeldung im File triggern. Die Variablen in sysViewUserLogin stehen hier:
          // displayPwdOrAccountMessage:     boolean               = false;
          // displayAccountActivateMessage:  boolean               = false;

            switch (x.error.errCode) {
              case '01': alert('Benutzer-ID oder Kennwort ist nicht korrekt');

              break;

              case '02': alert('Benutzer-Account muss noch bestätigt werden');

              break;

              case '03': alert('Benutzer-Account wurde gesperrt');

              break;

              case '04': alert('Der Benutzer-Account ist gesperrt');

              break;
            }
          }
        }
        else {
          // KontaktID aus WebAccount holen
          this.FKontaktID = x.okMessage;
          this.FAccLoginID = a_sLoginID;


          // this.FGlobalRessources.AccessToken = x.accessToken;  // $Rev MH 2024-01-19  AccessToken
          localStorage.setItem('AccessToken', x.accessToken);
          this.FGlobalRessources.LoginID     = a_sLoginID;
          // console.log('TEST Accesstoken: ', x);
          // console.log('USER Reg', x.accErfassDatum);
        }
      }
    );

  } // Login

  public Logout() {
    this.FIsLoggedIn            = false;
    this.FAccLoginID            = null;

    const httpOptions = {
      headers: new HttpHeaders({
        'AT':    localStorage.getItem('AccessToken')
        })
    };

    let body = {}
    // window.location.reload();
    let sURL = this.FGlobalRessources.RetrieveURLBase(false) + 'sysActionUserLogout';// + '?LoginID='  + a_sLoginID  + '&LoginPWD=' + a_sLoginPWD;
    this.HttpClient.post(sURL, body, httpOptions).subscribe((val) => {
      localStorage.removeItem('AccessToken');
      // this.globalRessources.AccessToken = null;
    });
  } // Logout


} // class TvsWebAccProfile

@Injectable({
  providedIn: 'root',
})
export class TvsUserProfile {


  //====== Properties (PRIVATE)
  private             FGlobalRessources:      TvsGlobalResources    = null;

  private             FIsLoaded:              boolean               = false;
  private             FIsLoggedIn:            boolean               = false;

  private             FUserAccID:             string                = null;
  private             FUserAccLoginID:        any                   = null;
  private             FUserAccName:           string                = null;
  private             FUserAccType:           TvsUserAccountType    = TvsUserAccountType.uatUndefined;
  private             FUserAccTypeID:         string                = null;
  private             FUserAccTypeName:       string                = null;
  private             FUserAccIsLocked:       boolean               = false;
  private             FUserIsAdmin:           boolean               = false;

  private             FUserID:                any                   = null;
  private             FUserName1:             string                = null;
  private             FUserName2:             string                = null;
  private             FUserCommPhone:         string                = null;
  private             FUserCommEMail:         string                = null;

  private             FUserAccDefaultLangID:  string                = null;

  private             FUserEmployeeID:        string                = null;
  private             FUserMitarbOrgeinhID:   string                = null;
  private             FUserMitarbKLinkID:     string                = null;

  private             FMandPKValue:           string                = null;
  private             FMandOrgEinhID:         string                = null;
  // UsrRights
  private             FUserRights                                   = {progFuncList: []}



  //====== Properties (PUBLIC)

  public  get         IsLoaded():             boolean               {return this.FIsLoaded}

  // $Rev TC 2020-12-31: braucht man isLoaded UND isLoggedIn? Vermutlich reicht isLoaded aus, da das UserProfile ja aufgrund eines Logins geladen wird.
  public  get         IsLoggedIn():           boolean               {return this.FIsLoggedIn}


  public  get         UserAccID(): string                           {return this.FUserAccID};
  public  get         UserAccLoginID(): any                         {return this.FUserAccLoginID};
  public  get         UserAccName(): string                         {return this.FUserAccName};
  public  get         UserAccType(): TvsUserAccountType             {return this.FUserAccType};

  public  get         UserAccTypeID(): string                       {return this.FUserAccTypeID};
  public  get         UserAccTypeName(): string                     {return this.FUserAccTypeName};
  public  get         UserAccIsLocked(): boolean                    {return this.FUserAccIsLocked};
  //$REV MP 2023-07-12: UserID zu userID korrigiert. Wird beim Aufruf der globalresources ist kleingeschrieben und führte bei SAMR zu Fehlern.
  public  get         userID(): any                                 {return this.FUserID};
  public  get         UserName1(): string                           {return this.FUserName1};
  public  get         UserName2(): string                           {return this.FUserName2};
  public  get         UserCommPhone(): string                       {return this.FUserCommPhone};
  public  get         UserCommEMail(): string                       {return this.FUserCommEMail};
  public  get         UserIsAdmin(): boolean                        {return this.FUserIsAdmin};
  // UsrRights
  public  get         UserRights(): any                             { return this.FUserRights };

  public  get         UserAccDefaultLangID(): string                {return this.FUserAccDefaultLangID};

  public  get         UserEmployeeID(): string                      {return this.FUserEmployeeID};

  public  get         UserMitarbOrgeinhID(): string                 {return this.FUserMitarbOrgeinhID};
  public  get         UserMitarbKLinkID(): string                   {return this.FUserMitarbKLinkID};

  public  get         MandPKValue(): string                         {return this.FMandPKValue};
  public  get         MandOrgEinhID(): string                       {return this.FMandOrgEinhID};


  //====== Constructor

  constructor( private       globalRessources: TvsGlobalResources
              ,protected     HttpClient:       HttpClient
             )
  {
    this.FGlobalRessources = this.globalRessources;

  } // constructor


  public dataLogin(a_sLoginID: string, a_sLoginPWD: string): Observable<any[]> {

    let sURL = this.FGlobalRessources.RetrieveURLBase(false) + 'sysActionUserLogin'; // + '?LoginID='  + a_sLoginID  + '&LoginPWD=' + a_sLoginPWD;
    // let sURL = 'https://localhost:7005/authentication/login';

//    return this.HttpClient.get(sURL).pipe((data: any) => {return data;});
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Authorization':  'Basic ' + btoa(vsCommon.vsStringReplaceHTMLSymbols(a_sLoginID + ':' + a_sLoginPWD))
    //   })
    // };
    // return this.HttpClient.post(sURL, '', httpOptions).pipe((data: any) => {return data;});
    // let envelope: vsCommon.TWebParamPwdChange_Envelope = new vsCommon.TWebParamPwdChange_Envelope();
    // envelope.data.userID    = a_sLoginID;
    // envelope.data.password  = a_sLoginPWD;

      // Prepare the request payload
  let payload = {
    data: {
      userID: a_sLoginID,
      password: a_sLoginPWD
    }
  };

  // Specify only the necessary headers
  const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json', // Specify content type
      // Include other headers as necessary
    })
  };

    // this.HttpServiceComponent.HttpServicePostRequest('sysActionUserPwdChange',
    // '&LoginID=' + this.globalResources.UserProfile.userID + '&LoginPwdNew=' + this.FormGroup.get('User_Pwd_New').value, '')
    // return this.HttpClient.post(sURL, JSON.stringify(envelope)).pipe((data: any) => {return data;});
    return this.HttpClient.post<any[]>(sURL, JSON.stringify(payload), httpOptions);
}




  public Login(a_sLoginID: string, a_sLoginPWD: string) {

    this.dataLogin(a_sLoginID, a_sLoginPWD).subscribe(
      (data : any) => {
        console.log("login data", data);
        // UsrRights: Anpassung des Results auf die school-eigenen ProgFuncIds
        // MUSS NUR AUSKOMMENTIERT WERDEN, WENN NOCH KEINE RICHTIGEN DATEN GELIEFERT WERDEN, ANSONSTEN EINFACH SKIPPEN
        // 1.
        // data.userProfile.progFuncList = data.userProfile.progFuncList.map((progFuncItem) => {
        //   if(progFuncItem.progFuncID === "Kontakt-Kat-Kategorie") {
        //     progFuncItem = {
        //       "progFuncID": "Home",
        //       "execute": true,
        //       "read": true,
        //       "new": true,
        //       "edit": true,
        //       "delete": true
        //     }
        //   }
        //   if(progFuncItem.progFuncID === "Kontakt-Kat-Land") {
        //     progFuncItem = {
        //       "progFuncID": "Ausb_Azubi",
        //       "execute": true,
        //       "read": false,
        //       "new": false,
        //       "edit": true,
        //       "delete": true
        //     }
        //   }
        //     if(progFuncItem.progFuncID === "Kontakt-Kat-Branche") {
        //       progFuncItem = {
        //         "progFuncID": "Antrag",
        //         "execute": true,
        //         "read": true,
        //         "new": true,
        //         "edit": false,
        //         "delete": false
        //       }
        //   }
        //   if(progFuncItem.progFuncID === "Common-ArbPlatz-Assign") {
        //     progFuncItem = {
        //       "progFuncID": "Sys_Benutzergruppe",
        //       "execute": true,
        //       "read": true,
        //       "new": true,
        //       "edit": true,
        //       "delete": true
        //     }
        //   }
        //   if(progFuncItem.progFuncID === "Kontakt-Kat-MA-Funktion") {
        //     progFuncItem = {
        //       "progFuncID": "Erstattungsantrag",
        //       "execute": true,
        //       "read": false,
        //       "new": true,
        //       "edit": true,
        //       "delete": true
        //     }
        //   }
        //   if(progFuncItem.progFuncID === "Kontakt-Kat-MA-Gruppe") {
        //     progFuncItem = {
        //       "progFuncID": "Auswertungs-Center",
        //       "execute": true,
        //       "read": true,
        //       "new": true,
        //       "edit": true,
        //       "delete": true
        //     }
        //   }
        //   if(progFuncItem.progFuncID === "Common-Standort") {
        //     progFuncItem = {
        //       "progFuncID": "Antrags-Positionen",
        //       "execute": true,
        //       "read": true,
        //       "new": true,
        //       "edit": true,
        //       "delete": true
        //     }
        //   }
        //   if(progFuncItem.progFuncID === "OPV-EBE-Center") {
        //     progFuncItem = {
        //       "progFuncID": "Sys_Benutzergruppe",
        //       "execute": false,
        //       "read": false,
        //       "new": true,
        //       "edit": true,
        //       "delete": true
        //     }
        //   }
        //   if(progFuncItem.progFuncID === "Artikel-Artikel") {
        //     progFuncItem = {
        //       "progFuncID": "Sys_Benutzergruppe_Funktionen",
        //       "execute": true,
        //       "read": true,
        //       "new": true,
        //       "edit": true,
        //       "delete": true
        //     }
        //   }
        //   if(progFuncItem.progFuncID === "Common_OrgEinh_Kat") {
        //     progFuncItem = {
        //       "progFuncID": "DOC_Module_Center",
        //       "execute": true,
        //       "read": true,
        //       "new": true,
        //       "edit": true,
        //       "delete": true
        //     }
        //   }

        //   if(progFuncItem.progFuncID === "Faktura-Stamm") {
        //     progFuncItem = {
        //       "progFuncID": "Bestellungen_Center",
        //       "execute": true,
        //       "read": true,
        //       "new": true,
        //       "edit": true,
        //       "delete": true
        //     }
        //   }
        //   if(progFuncItem.progFuncID === "eTicket") {
        //     progFuncItem = {
        //       "progFuncID": "CRM_Ticket",
        //       "execute": true,
        //       "read": true,
        //       "new": true,
        //       "edit": true,
        //       "delete": true
        //     }
        //   }
        //   if(progFuncItem.progFuncID === "Rpt_Center") {
        //     progFuncItem = {
        //       "progFuncID": "CRM_Reporter",
        //       "execute": true,
        //       "read": true,
        //       "new": true,
        //       "edit": true,
        //       "delete": true
        //     }
        //   }
        //   if(progFuncItem.progFuncID === "Lager_GV") {
        //     progFuncItem = {
        //       "progFuncID": "Bestellungen_Center",
        //       "execute": true,
        //       "read": true,
        //       "new": true,
        //       "edit": true,
        //       "delete": true
        //     }
        //   }
        //   if(progFuncItem.progFuncID === "Obj_Inventar") {
        //     progFuncItem = {
        //       "progFuncID": "Bestellungen_Center",
        //       "execute": true,
        //       "read": true,
        //       "new": true,
        //       "edit": true,
        //       "delete": true
        //     }
        //   }
        //   if(progFuncItem.progFuncID === "CRM-Vorfall") {
        //     progFuncItem = {
        //       "progFuncID": "OPV_HSt",
        //       "execute": true,
        //       "read": true,
        //       "new": true,
        //       "edit": true,
        //       "delete": true
        //     }
        //   }
        //   if(progFuncItem.progFuncID === "Faktura-GV") {
        //     progFuncItem = {
        //       "progFuncID": "CRM_EMailAcc",
        //       "execute": true,
        //       "read": true,
        //       "new": true,
        //       "edit": true,
        //       "delete": true
        //     }
        //   }
        //   if(progFuncItem.progFuncID === "Preisliste") {
        //     progFuncItem = {
        //       "progFuncID": "Ausb_Staett",
        //       "execute": true,
        //       "read": true,
        //       "new": true,
        //       "edit": true,
        //       "delete": true
        //     }
        //   }
        //   if(progFuncItem.progFuncID === "OPV-EBE-Stamm") {
        //     progFuncItem = {
        //       "progFuncID": "Stammdaten_Bescheid_Template",
        //       "execute": true,
        //       "read": true,
        //       "new": true,
        //       "edit": true,
        //       "delete": true
        //     }
        //   }
        //   if(progFuncItem.progFuncID === "Preisliste") {
        //     progFuncItem = {
        //       "progFuncID": "Ausb_Staett",
        //       "execute": true,
        //       "read": true,
        //       "new": true,
        //       "edit": true,
        //       "delete": true
        //     }
        //   }
        //   return progFuncItem;
        // });
            // console.log('UserProfile Input: ', data);
        let menge : any[] = [data];
        let item: any = menge[0].userProfile;
        // console.log('Menge', menge);
        // console.log('Item', item);
        // this.FGlobalRessources.AccessToken = menge[0].accessToken;  // $Rev MH 2024-01-19  AccessToken
        // console.log('TEST Accesstoken2: ', this.FGlobalRessources.AccessToken);

        localStorage.setItem('AccessToken', menge[0].accessToken);

        this.FGlobalRessources.LoginID     = item.userAccLoginID;

        this.FUserAccID           =  item.uerAccID;
        this.FUserAccIsLocked     =  item.userAccIsLocked;
        this.FUserAccLoginID      =  item.userAccLoginID;

        // $Rev TC 2021-11-05:
        // Aktuell werden nur System User unterstützt (noch kein Web Accounts), daher ist accountName momentan immer leer

        // this.FUserAccName         =  item.userAccName;
        this.FUserAccName         =  vsCommon.vsStringHasValue(item.userAccName) ? item.userAccName : item.userAccLoginID;

        this.FUserAccType         =  item.userAccType;
        this.FUserAccTypeID       =  item.userAccTypeID;
        this.FUserAccTypeName     =  item.userAccTypeName;
        this.FUserID              =  item.userID;
        this.FUserName1           =  item.userName1;
        this.FUserName2           =  item.userName2;
        this.FUserCommEMail       =  item.userCommEMail;
        this.FUserCommPhone       =  item.userCommPhone;
        this.FUserAccDefaultLangID   =  item.userAccDefaultLangID;
        this.FUserEmployeeID      =  item.userEmployeeID;
        this.FUserMitarbOrgeinhID = item.userMitarbOrgeinhID;
        this.FUserMitarbKLinkID   = item.userMitarbKLinkID;
        this.FMandPKValue         = item.mandPKValue;
        this.FMandOrgEinhID       = item.mandOrgEinhID;

        // console.log('UserProfile loaded target', this);
        // console.log('UserProfile loaded src:', item);

        this.FIsLoaded            =  true;
        this.FIsLoggedIn          =  true;
        this.FUserIsAdmin         =  item.userIsAdmin;
        // UsrRights
        this.FUserRights.progFuncList          =  item.progFuncList;
      },
      error => {
        alert('Login fehlgeschlagen - bitte überprüfen Sie Ihre Eingabe von Benutzer-ID und Kennwort.');
      }


    );

    // [LABOR] TODO: authentication should be done by a service

    // if (bOK) {
    //   bOK = false;
    //   bOK = bOK || (vsCommon.vsStringSameText(a_sUserID, 'a')       && (a_sUserPwd === 'a'));
    //   bOK = bOK || (vsCommon.vsStringSameText(a_sUserID, 'mh')      && (a_sUserPwd === 'mh'));
    //   // bOK = bOK || (vsCommon.vsStringSameText(a_sUserID, 'tc')      && (a_sUserPwd === 'tc'));
    //   // bOK = bOK || (vsCommon.vsStringSameText(a_sUserID, 'johndoe') && (a_sUserPwd === 'johndoe'));
    //   bOK = bOK || (vsCommon.vsStringSameText(a_sUserID, 'johndoe') && (a_sUserPwd === 'vrtTest'));
    //   // bOK = bOK || (vsCommon.vsStringSameText(a_sUserID, 'vedisys') && (a_sUserPwd === 'vedisys'));
    // }


  } // Login



  protected LoadData(a_sUserID: string) {

    // [LABOR] TODO: this data schould be loaded by a data service

    switch (a_sUserID) {

      case 'mh': {
        this.FUserAccID           = 'm.heinlein';
        this.FUserAccLoginID      = 'm.heinlein@vedisys.de';

        this.FUserAccName         = 'Michael Heinlein';
        this.FUserAccType         = TvsUserAccountType.uatSystemUser;
        this.FUserAccTypeID       = 'accTypeSystemUser';
        this.FUserAccTypeName     = 'System User';
        this.FUserAccIsLocked     = false;

        this.FUserID              = 'DD39ECE8E159434B89FF28D76239D428';           // m.heinlein in Datenbank AMIS7_web
        this.FUserName1           = 'Heinlein';
        this.FUserName2           = 'Michael';
        this.FUserCommPhone       = '01234 - 123 456 78';
        this.FUserCommEMail       = 'm.heinlein@vedisys.de';
        this.FUserIsAdmin         = false;

        this.FUserAccDefaultLangID = 'de-DE';

        break;
      } // case mh


      case 'tc': {
        this.FUserAccID           = 't.cordey';
        this.FUserAccLoginID      = 't.cordey@vedisys.de';

        this.FUserAccName         = 'Thomas Cordey';
        this.FUserAccType         = TvsUserAccountType.uatSystemUser;
        this.FUserAccTypeID       = 'accTypeSystemUser';
        this.FUserAccTypeName     = 'System User';
        this.FUserAccIsLocked     = false;

        this.FUserID              = '2EDE59B36FEA4987BF35B4662123A747';           // t.cordey in Datenbank AMIS7_web
        this.FUserName1           = 'Cordey';
        this.FUserName2           = 'Thomas';
        this.FUserCommPhone       = '01234 - 123 456 78';
        this.FUserCommEMail       = 't.cordey@vedisys.de';
        this.FUserIsAdmin         = false;

        this.FUserAccDefaultLangID = 'de-DE';

        break;
      } // case tc


      default: {
        this.FUserAccID           = 'john.doe';
        this.FUserAccLoginID      = 'a'

        this.FUserAccName         = 'John Doe (Test)';
        this.FUserAccType         = TvsUserAccountType.uatSystemUser;
        this.FUserAccTypeID       = 'accTypeSystemUser';
        this.FUserAccTypeName     = 'System User';
        this.FUserAccIsLocked     = false;

        this.FUserID              = 'E914A636CE4148FB9E1F9B160CAF9B59';  // a/a in Datenbank AMIS7_web
        this.FUserName1           = 'Doe';
        this.FUserName2           = 'John';
        this.FUserCommPhone       = '01234 - 123 456 78';
        this.FUserCommEMail       = 'john.doe@vedisys.de';
        this.FUserIsAdmin         = true;

        this.FUserAccDefaultLangID = 'de-DE';
      } // default

    } // switch

  } // LoadData



  public Logout() {
    this.FIsLoaded              = false;
    this.FIsLoggedIn            = false;

    const httpOptions = {
      headers: new HttpHeaders({
        'AT':    localStorage.getItem('AccessToken')
        })
    };

    let body = {}

    let sURL = this.FGlobalRessources.RetrieveURLBase(false) + 'sysActionUserLogout';// + '?LoginID='  + a_sLoginID  + '&LoginPWD=' + a_sLoginPWD;
    this.HttpClient.post(sURL, body, httpOptions).subscribe((val) => {
      localStorage.removeItem('AccessToken');
      // this.globalRessources.AccessToken = null;
    });
  } // Logout


} // TvsUserProfile



//
// TvsMandProfile
//
// Service: Mand Profile (Singleton)
//
// [Labor] TODO: Verlagern in eigene Dateien
//

@Injectable({
providedIn: 'root',
})
export class TvsMandProfile {


  //====== Properties (PUBLIC)

  public  readonly    IsLoaded:               boolean               = false;
  public  googleAPIKey:                       string                = null;
  public  googleAPIKeyDummy:                  string                = null;

  public  readonly    MandID:                 string                = null;
  public  readonly    MandName1:              string                = null;
  public  readonly    MandName2:              string                = null;
  public  readonly    MandCommPhone:          string                = null;
  public  readonly    MandCommFAX:            string                = null;
  public  readonly    MandCommEMail:          string                = null;
  public  readonly    MandAdrStr:             string                = null;
  public  readonly    MandAdrHausNr:          string                = null;
  public  readonly    MandAdrPLZ:             string                = null;
  public  readonly    MandAdrOrt:             string                = null;


  //====== Constructor

  constructor(protected     HttpClient: HttpClient) {

    // [Labor] TODO: this data schould be loaded by a data service
    this.IsLoaded             = true;
    this.googleAPIKey         = "AIzaSyDU0_nLHdHHoIvAXffkfbnC9Mo6fU-19Fw";
    this.googleAPIKeyDummy    = "AIzaSyDU0_nLHdHHoIvAXffkfbnC9Mo6fU-19Fw";
    this.MandID               = 'MAIN';
    this.MandName1            = 'Playground GmbH';
    this.MandName2            = '';

    this.MandCommPhone        = '01234 - 123 456 78';
    this.MandCommFAX          = '01234 - 123 456 99';
    this.MandCommEMail        = 'info@xyz.de';

    this.MandAdrStr           = 'Mandantenstraße';
    this.MandAdrHausNr        = '42';
    this.MandAdrPLZ           = '99999';
    this.MandAdrOrt           = 'Mandantenstadt';

  } // constructor

} // TvsMandProfile



<!-- Header: Standard ----------------------------------------------------------------------------->

<appHeader></appHeader>

<!-- Main Content --------------------------------------------------------------------------------->

<div class="container vsMainContentContainer">

<form [formGroup]="this.editForm">


  <vsLayoutGroup class="col" MediumGutterWidth=true Orientation="horizontal">

    <vsLayoutGroup class="col-lg-2 col-md-1 col-sm-0"></vsLayoutGroup>

    <vsLayoutGroup class="col-lg-8 col-md-10 col-sm-12" isPanel=true panelTitleVisible=true panelTitleText="{{'application.school.heading'| translate}}" Orientation="horizontal">

      <vsLayoutGroup class="col-12" Orientation="horizontal">
        <vsLayoutGroup class="col-lg-6 col-md-6 col-sm-12">
          <!-- <vsInputAutoComplete FrmCtlName="AusbStaett_Name" label="{{'application.school.school_name'| translate}}" labelWidth="110px" placeholder="{{'application.input_required'| translate}}" [suggestions]="Schulen" FieldName="AusbStaett_Name" MinLength=1 Dropdown=true></vsInputAutoComplete> -->
          <vsInputButtonEdit FrmCtlName="AusbStaett_Name" label="{{'application.school.school_name'| translate}}" labelWidth="110px" (onButtonClick)="doSchoolSearch($event)"></vsInputButtonEdit>
        </vsLayoutGroup>
        
        <vsLayoutGroup class="col-lg-6 col-md-6 col-sm-12">
          <!-- TODO MP Tooltip einfügen-->
          <!-- <vsInputAutoComplete FrmCtlName="Antr_ID_Ausb_Staett_Art" label="{{'application.school.school_kind'| translate}}" labelWidth="110px" placeholder="{{'application.input_required'| translate}}" [suggestions]="Schularten" FieldName="Art_Name" MinLength=1 Dropdown=true></vsInputAutoComplete> -->
          <vsInputText FrmCtlName="Antr_ID_Ausb_Staett_Art" label="{{'application.school.school_kind'| translate}}" labelWidth="110px" MinLength=1 Dropdown=true></vsInputText>
        </vsLayoutGroup>

      </vsLayoutGroup>

      <vsLayoutGroup class="col-12" Orientation="horizontal">
        <vsLayoutGroup class="col-lg-6 col-md-6 col-12">
          <vsInputNumber FrmCtlName="Antr_Klasse_Beg_Nr" label="{{'application.school.class'| translate}}" labelWidth="110px" placeholder="{{'application.input_required'| translate}}"></vsInputNumber>
        </vsLayoutGroup>
        <vsLayoutGroup class="col-lg-6 col-md-6 col-12">
          <vsInputText FrmCtlName="Antr_Klasse_Beg_Zusatz" label="{{'application.school.class_add'| translate}}" labelWidth="110px"></vsInputText>
        </vsLayoutGroup>
      </vsLayoutGroup>

    </vsLayoutGroup>

    <vsLayoutGroup class="col-lg-2 col-md-1 col-sm-0"></vsLayoutGroup>

  </vsLayoutGroup>

</form> <!-- Form Ende -->


<div style="margin-top: 3rem; margin-bottom: 1.5rem;">
  <button pButton label="{{'change.button_cancel'| translate}}" (click)="actionStepPrev()" class="vsElternButton vsBack" style="margin-right: 10px;"></button>
  <button pButton label="{{'change.button_send'| translate}}"  (click)="actionStepNext()" class="vsElternButton vsForward"></button>
</div>

</div>  <!-- Ende container -->


<p-dialog header="{{'dialog.error_fill_heading'| translate}}" [(visible)]="displayDialogError" [baseZIndex]="10000" class="vsDialog vsDanger vsRequired">
  <p [innerHtml]="'dialog.error_fill_text'| translate"></p>
  <ng-template pTemplate="footer">
      <button pButton pRipple type="button" (click)="displayDialogError=false" label="{{'dialog.error_fill_button_ok'| translate}}" class="vsToolbarItem vsToolbarItemOK vsMb-2">
        <span>
          <img alt="close" src="/assets/img/Icons/vs-check-circle.svg" />
        </span>
      </button>
  </ng-template>
</p-dialog>

<!-- Footer --------------------------------------------------------------------------------------->


<appFooter></appFooter>



<!-- Messages (Toasts) ---------------------------------------------------------------------------->


<p-toast></p-toast>


<!-- <pre>{{ this.editForm.value | json}}</pre> -->



<!-- Header: Standard ----------------------------------------------------------------------------->

  <appHeader></appHeader>


<!-- Main Content --------------------------------------------------------------------------------->

  <div class="container vsMainContentContainer">

  
    <div class="row">

      <!-- Antrag auf ein Schülerticket ----------->

      <div *ngIf="dataModel.KennAntragTicket" class="col-lg-6 col-md-6 col-sm-12">

        <img src="/assets/img/Images/Uebernahme_Image.png" height="200px" class="vsCategoryImage" style="margin-left:-35px; margin-bottom:1rem;" alt="">

        <h4 [innerHtml]="'application_kind.heading_1'| translate">
        </h4>

        <p [innerHtml]="'application_kind.text_1'| translate">
        </p>

        <button pButton label="{{'application_kind.button_forward'| translate}}" (click)="actionUebernahme()" class="vsElternButton vsForward vsMb-2" [attr.aria-label]="'application_kind.button_1'| translate"></button>

      </div>  <!--col-lg-3 col-md-6 col-sm-12 Ende-->



      <!-- Grundantrag Erstattung ----------->
      
      <div *ngIf="dataModel.KennGrundantragErstattung" class="col-lg-6 col-md-6 col-sm-12">
        <img src="/assets/img/Images/Uebernahme_Image.png" height="200px" class="vsCategoryImage" style="margin-bottom:1rem;" alt="">

        <h4 [innerHtml]="'application_kind.heading_4'| translate">
        </h4>

        <p [innerHtml]="'application_kind.text_4'| translate">
        </p>
        <button pButton label="{{'application_kind.button_forward'| translate}}" (click)="actionGrundantrag()" class="vsElternButton vsForward vsMb-2" [attr.aria-label]="'application_kind.button_2'| translate"></button>

      </div>  <!--col-lg-3 col-md-6 col-sm-12 Ende-->

      <!-- Antrag auf Erstattung ----------->

      <div *ngIf="dataModel.KennAntragErstattung" class="col-lg-6 col-md-6 col-sm-12">
        <img src="/assets/img/Images/Erstattung_Bild.png" height="200px" class="vsCategoryImage" style="margin-bottom:1rem;" alt="">
            
          <h4 [innerHtml]="'application_kind.heading_2'| translate">
          </h4>
            
          <p [innerHtml]="'application_kind.text_2'| translate"></p>
          <button pButton label="{{'application_kind.button_forward'| translate}}" (click)="actionErstattung()" class="vsElternButton vsForward vsMb-2" [attr.aria-label]="'application_kind.button_2'| translate"></button>
            
      </div>  <!--col-lg-3 col-md-6 col-sm-12 Ende-->

      <!-- Grundantrag Azubi ----------->
      
      <div *ngIf="dataModel.KennAntragAzubi" class="col-lg-6 col-md-6 col-sm-12">
        <img src="/assets/img/Images/Bus_Azubi.png" height="200px" class="vsCategoryImage" style="margin-bottom:1rem;" alt="">
      
        <h4 [innerHtml]="'application_kind.heading_5'| translate"></h4>
      
        <p [innerHtml]="'application_kind.text_5'| translate"></p>
        <button pButton label="{{'application_kind.button_forward'| translate}}" (click)="actionGrundantragAzubi()" class="vsElternButton vsForward vsMb-2" [attr.aria-label]="'application_kind.button_2'| translate"></button>
      
      </div>  <!--col-lg-3 col-md-6 col-sm-12 Ende-->


      <!-- Antrag auf Erstattung Azubi ----------->
      
      <div *ngIf="dataModel.KennAntragAzubi" class="col-lg-6 col-md-6 col-sm-12">
        <img src="/assets/img/Images/Erstattung_Azubi.png" height="200px" class="vsCategoryImage" style="margin-bottom:1rem;" alt="">
            
        <h4 [innerHtml]="'application_kind.heading_6'| translate"></h4>
            
        <p [innerHtml]="'application_kind.text_6'| translate"></p>
        <button pButton label="{{'application_kind.button_forward'| translate}}" (click)="actionErstattungAzubi()" class="vsElternButton vsForward vsMb-2" [attr.aria-label]="'application_kind.button_2'| translate"></button>
            
      </div>  <!--col-lg-3 col-md-6 col-sm-12 Ende-->


      <!-- Individualbeförderung ---------->

      <div *ngIf="dataModel.KennAntragIndividual" class="col-lg-6 col-md-6 col-sm-12">

        <img src="/assets/img/Images/Individualbefoerderung_Bild.png" height="200px" class="vsCategoryImage" style="margin-bottom:1rem;" alt="">

        <h4 [innerHtml]="'application_kind.heading_3'| translate">
        </h4>

        <p [innerHtml]="'application_kind.text_3'| translate">
        </p>
        <button pButton label="{{'application_kind.button_forward'| translate}}" (click)="actionIndividual()" class="vsElternButton vsForward" [attr.aria-label]="'application_kind.button_3'| translate"></button>

      </div>  <!--col-lg-3 col-md-6 col-sm-12 Ende-->



    </div>  <!--Ende row-->

    <div class="row">
      <div style="margin-top: 1rem; margin-bottom: 1rem;">
        <button pButton label="{{'application_kind.button_back'| translate}}" (click)="actionStepPrev()" class="vsElternButton vsBack"
          style="margin-right: 10px;"></button>
      </div>
    </div>

  </div> <!-- Ende container-xxl -->

<!-- Footer --------------------------------------------------------------------------------------->

  <appFooter></appFooter>


<!-- Messages (Toasts) ---------------------------------------------------------------------------->


<p-toast></p-toast>



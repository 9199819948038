<div class="vsInputDropDown InvalidCSSClass" appMobileResizeFix>
  <div class="row vsMb-2">

    <label class="vsInputLabel" [style.width]="labelWidth" id="label-{{FrmCtlName}}" for="input-{{FrmCtlName}}">{{labelContent}}</label>
    <div class="col">
      <div *ngIf="ReadOnly=='true'">
        <div *ngIf="Disabled=='true'">
          <p-dropdown class="vsForm"
                    inputId="input-{{FrmCtlName}}"
                    inputStyleClass="vsFormControl"
                    formControlName="{{FrmCtlName}}"
                    [options]=LookupSrc
                    optionLabel="{{LookupDisplay}}"
                    optionValue="{{LookupKey}}"
                    placeholder=" - "
                    emptyMessage="{{EmptyMessage}}"
                    [readonly]=true
                    (onChange)  = doOnChange($event)
                    [disabled]=true
          ></p-dropdown>
        </div>
        <div *ngIf="Disabled=='false'">
          <p-dropdown class="vsForm"
                    inputId="input-{{FrmCtlName}}"
                    inputStyleClass="vsFormControl"
                    formControlName="{{FrmCtlName}}"
                    [options]=LookupSrc
                    optionLabel="{{LookupDisplay}}"
                    optionValue="{{LookupKey}}"
                    placeholder=" - "
                    emptyMessage="{{EmptyMessage}}"
                    [readonly]=true
                    (onChange)  = doOnChange($event)
          ></p-dropdown>
        </div>
      </div>
      <div *ngIf="ReadOnly=='false'">
        <div *ngIf="Disabled=='true'">
          <p-dropdown class="vsForm"
                    inputId="input-{{FrmCtlName}}"
                    inputStyleClass="vsFormControl"
                    formControlName="{{FrmCtlName}}"
                    [options]=LookupSrc
                    optionLabel="{{LookupDisplay}}"
                    optionValue="{{LookupKey}}"
                    placeholder=" - "
                    emptyMessage="{{EmptyMessage}}"
                    (onChange)  = doOnChange($event)
                    [disabled]=true
          ></p-dropdown>
        </div>
        <div *ngIf="Disabled=='false'">
          <p-dropdown class="vsForm"
                    inputId="input-{{FrmCtlName}}"
                    inputStyleClass="vsFormControl"
                    formControlName="{{FrmCtlName}}"
                    [options]=LookupSrc
                    optionLabel="{{LookupDisplay}}"
                    optionValue="{{LookupKey}}"
                    placeholder=" - "
                    emptyMessage="{{EmptyMessage}}"
                    (onChange)  = doOnChange($event)
                    [showClear]=allowClear
          ></p-dropdown>
        </div>
      </div>
    </div>
  </div>
</div>



<form [formGroup]="this.editForm">
  <div class="container-xxl"  style="margin-top: 1.5rem; margin-bottom: 1.5rem; height: 608px; color: #808080; ">


    <vsLayoutGroup class="col" MediumGutterWidth=true Orientation="horizontal">

      <vsLayoutGroup class="col-lg-3 col-md-2 col-sm-0"></vsLayoutGroup>

      <vsLayoutGroup class="col-lg-6 col-md-8 col-sm-12" Orientation="horizontal">

        <vsLayoutGroup class="col-12">
          <div #explanation_header class="vsFormTextDiv">
            <b>{{'explanation.sepa_data_heading'| translate}}</b>
          </div>
          <div #explanation_text class="vsFormTextDivParagraph vsMb-2">
            {{'explanation.sepa_data_text'| translate}}
          </div>
        </vsLayoutGroup>

        <vsLayoutGroup class="col-12" isPanel=true panelTitleVisible=true panelTitleText="{{'application.bank.heading'| translate}}">

          <vsLayoutGroup class="col-12" Orientation="horizontal">
            <vsLayoutGroup class="col">

              <ng-template [ngIf]="KennErstattung" [ngIfElse]="elseBlockInh">
                <vsInputText FrmCtlName="AntrStell_Kto_Inh" label="{{'application.bank.account_owner'| translate}}"  placeholder="{{'application.input_required'| translate}}" labelWidth="110px"></vsInputText>
              </ng-template>
              <ng-template #elseBlockInh>
                <vsInputText FrmCtlName="AntrStell_Kto_Inh" label="{{'application.bank.account_owner'| translate}}" labelWidth="110px"></vsInputText>
              </ng-template>

            </vsLayoutGroup>

            <vsLayoutGroup class="col-12">

              <ng-template [ngIf]="KennErstattung" [ngIfElse]="elseBlockIBAN">
                <vsInputText id="IBAN"  aria-describedby="IBAN-help" (onBlur)="validateIBAN()" FrmCtlName="AntrStell_IBAN" label="{{'application.bank.IBAN'| translate}}" placeholder="{{'application.input_required'| translate}}" labelWidth="110px"></vsInputText>
              </ng-template>

              <ng-template #elseBlockIBAN>
                <vsInputText id="IBAN"  aria-describedby="IBAN-help" (onBlur)="validateIBAN()" FrmCtlName="AntrStell_IBAN" label="{{'application.bank.IBAN'| translate}}" labelWidth="110px"></vsInputText>
              </ng-template>

            </vsLayoutGroup>

            <div class="col-12" style="min-height:27.19px;">
              <small *ngIf="!ibanValid" id="IBAN-help" class="p-error" style="margin-left:140px;">Keine gültige IBAN.</small>
            </div>

          </vsLayoutGroup>

          <vsLayoutGroup class="col-12" Orientation="horizontal">

            <vsLayoutGroup class="col">
              <ng-template [ngIf]="KennErstattung" [ngIfElse]="elseBlockBIC">
                <vsLayoutGroup class="col-12">
                  <vsInputText Disabled="true" FrmCtlName="AntrStell_Bank_BIC" disabled label="BIC" labelWidth="110px"></vsInputText>
                </vsLayoutGroup>
                <vsLayoutGroup class="col-12">
              <vsInputText Disabled="true" FrmCtlName="AntrStell_Bank_Name" label="{{'application.bank.bank_name'| translate}}" labelWidth="110px" ></vsInputText>
              </vsLayoutGroup>

              </ng-template>
              <ng-template #elseBlockBIC>
                <vsLayoutGroup class="col-12">
                  <vsInputText Disabled="true" FrmCtlName="AntrStell_Bank_BIC" disabled label="BIC" labelWidth="110px"></vsInputText>
                </vsLayoutGroup>
                <vsLayoutGroup class="col-12">
              <vsInputText Disabled="true" FrmCtlName="AntrStell_Bank_Name" label="{{'application.bank.bank_name'| translate}}" labelWidth="110px" ></vsInputText>
              </vsLayoutGroup>
              </ng-template>

            </vsLayoutGroup>

          </vsLayoutGroup>

        </vsLayoutGroup>

        <vsLayoutGroup class="col-lg-3 col-md-2 col-sm-0"></vsLayoutGroup>

      </vsLayoutGroup>

    </vsLayoutGroup>

    <div style="margin-top: 3rem; margin-bottom: 1.5rem;">

      <button pButton label="{{'application.button_back'| translate}}" (click)="actionStepPrev()" class="vsElternButton vsBack" style="margin-right: 10px;"></button>
      <button pButton label="{{'application.button_forward'| translate}}" (click)="actionStepNext()" class="vsElternButton vsForward"></button>
    </div>

  </div> <!-- Ende container-xxl -->
</form> <!-- Form Ende -->


<p-dialog header="{{'dialog.error_fill_heading'| translate}}" [(visible)]="displayDialogError" [baseZIndex]="10000" class="vsDialog vsDanger vsRequired">
  <p [innerHtml]="'dialog.error_fill_text'| translate"></p>
  <ng-template pTemplate="footer">
      <button pButton pRipple type="button" (click)="displayDialogError=false" label="{{'dialog.error_fill_button_ok'| translate}}" class="vsToolbarItem vsToolbarItemOK vsMb-2">
        <span>
          <img alt="close" src="/assets/img/Icons/vs-check-circle.svg" />
        </span>
      </button>
  </ng-template>
</p-dialog>

<!-- <pre>{{ this.editForm.value | json}}</pre> -->


//--------------------------------------------------------------------------------------------------
//  AMIS7school - Externes Portal: Eltern
//
//  Domain:   School
//  Title:    Grundantrag Schülerbeförderung (Edit View)
//  Notes:    MOCKUP
//
//
//  H I S T O R Y
//
//  2021-08-05  TC   Initial Version
//--------------------------------------------------------------------------------------------------

import {  Component, ViewChild
        , OnInit
        , ComponentFactoryResolver}         from '@angular/core';

import { Router }                           from '@angular/router';

import { FormGroup, FormControl, Validators }           from '@angular/forms';

import  * as schoolDataModel                from 'AMIS7school_Portal_Eltern/src/app/school/schoolDataModel.service';

// PrimeNG
import { MenuItem }                         from 'primeng/api';
import { MessageService }                   from 'primeng/api';


// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import * as vsView                          from '@vsLib/View/components/vsView/vsView.component';
import * as vsGlobalResources               from '@vsLib/Services/vsGlobalResources.service';
import * as vsTabControl                    from '@vsLib/Components/vsTab/vsTabControl.component';

import { TvsHttpServiceComponent }          from '@vsLib/Services/vsHttp.service.component';


// AMIS7core
import * as AMIS7coreConst                  from '@AMIS7core/$Common/comConst';
import { formatDate } from '@angular/common';
import { asLiteral } from '@angular/compiler/src/render3/view/util';


@Component({
  selector:       'schoolViewAntragEdit_Antrag',
  templateUrl:  './schoolViewAntragEdit_Antrag.component.html',
  // styleUrls:   ['./schoolViewAntragEdit_Antrag.component.scss']
})
export class TschoolViewAntragEdit_Antrag extends vsView.TvsView implements OnInit {


  //====== Properties (PRIVATE)

  // ./.

  //====== Properties (PUBLIC)

  public      editForm:                     FormGroup               = new FormGroup({});


  public lookupArtName:               any[];

  public displayDialogError: boolean   = false;

  //====== Constructor

  constructor(protected router:                     Router,
              public    globalResources:            vsGlobalResources.TvsGlobalResources
            , public    dataModel:                  schoolDataModel.TschoolDataModel
            , protected messageService:             MessageService
            , protected HttpServiceComponent:       TvsHttpServiceComponent
  ) {
    super(router, globalResources);

    this.ViewProperties.Instance        = this;
    this.ViewProperties.ViewTitle       = 'Antragsauswahl';

    this.ViewProperties.ProgFuncID      = null;
    this.ViewProperties.DataSourceID    = null;
    this.ViewProperties.QueryFields     = [];


  this.lookupArtName = [
    {Art_Name: 'Antrag auf Übernahme von Schülerfahrtkosten',      Art_ID: 'U'},
    {Art_Name: 'Antrag auf Erstattung von Schülerfahrtkosten',      Art_ID: 'E'}
  ];


    // this.Anrede = [
    //   {Anrede: 'Herr',      Anrede_ID: 'HERR'},
    //   {Anrede: 'Frau',      Anrede_ID: 'FRAU'},
    //   // {Anrede: 'Divers',    Anrede_ID: 'DIVERS'},
    //   {Anrede: '<Unbek>',   Anrede_ID: 'UNBEK'}
    // ];


  } // constructor

  //====== Methods: NG event handler

  ngOnInit(): void {


    this.editForm.addControl('Art_Name', new FormControl(null));
    this.editForm.addControl('Antr_Datum',     new FormControl(null));


  } // ngOnInit


  public actionStepPrev(): void {
    this.router.navigate(['landing']);
  }

  public actionStepNext(): void {

    let bOK:        boolean       = true;


    if (bOK) {
      this.editForm.get('Art_Name').setValidators(Validators.required);
      this.editForm.get('Art_Name').updateValueAndValidity();

      this.editForm.get('Antr_Datum').setValidators(Validators.required);
      this.editForm.get('Antr_Datum').updateValueAndValidity();

      bOK = !this.editForm.invalid;
      this.displayDialogError = !bOK;

    }

    if (bOK) {
      this.dataSave();
      this.router.navigate(['antragEdit/student']);
    }

  } // actionStepNext()



  public dataSave() :void{

    this.dataModel.antrag_Art      =  this.editForm.controls['Art_Name'].value;
    this.dataModel.antrag_Datum    =  this.editForm.controls['Antr_Datum'].value;
    // this.dataModel.antrag_Datum    =  vsCommon.DateStrToISO8061(this.editForm.controls['Antr_Datum'].value);

    console.log('this.dataModel.antrag_Art ' , this.dataModel.antrag_Art.Art_ID)
    console.log('this.editForm.controls_Art_Name' , this.editForm.controls['Art_Name'])

  }


} // schoolViewAntragEdit_Student




<!-- Header: Standard ----------------------------------------------------------------------------->


<appHeader></appHeader>

<!-- Main Content --------------------------------------------------------------------------------->

<div class="container-xxl vsMainContentContainer">

  <form [formGroup]="this.editForm">

    <vsLayoutGroup class="col-12" Orientation="horizontal" MediumGutterWidth=true>

      <vsLayoutGroup class="col" MediumGutterWidth=true Orientation="horizontal">

        <vsLayoutGroup class="col-lg-2 col-md-1 col-sm-0"></vsLayoutGroup>

        <vsLayoutGroup class="col-lg-8 col-md-10 col-sm-12" Orientation="horizontal" MediumGutterWidth=true>

          <vsLayoutGroup class="col-12" isPanel=true panelTitleVisible=true panelTitleText="{{'application.student.heading'| translate}}"
            Orientation="horizontal">

            <vsLayoutGroup class="col-lg-6 col-md-6 col-12" Orientation="vertical">
              <vsLayoutGroup class="col-12">
                <vsInputAutoComplete FrmCtlName="Azubi_ID_Anrede" label="{{'application.student.form_of_address'| translate}}" labelWidth="110px"
                  placeholder="*Eingabe erforderlich" [suggestions]="LookupAnrede" FieldName="{{'application.student.form_of_address'| translate}}" MinLength=1 Dropdown=true ></vsInputAutoComplete>

              </vsLayoutGroup>

              <vsLayoutGroup class="col-12">
                <vsInputText FrmCtlName="Azubi_Vorname" label="{{'application.student.first_name'| translate}}" placeholder="*Eingabe erforderlich"
                  labelWidth="110px" ></vsInputText>
              </vsLayoutGroup>

              <vsLayoutGroup class="col-12">
                <vsInputText FrmCtlName="Azubi_Nachname" label="{{'application.student.last_name'| translate}}" placeholder="*Eingabe erforderlich"
                  labelWidth="110px" ></vsInputText>
              </vsLayoutGroup>

              <vsLayoutGroup class="col-12">
                <vsInputDateTime FrmCtlName="Azubi_Geb_Datum" label="{{'application.student.date_birth'| translate}}" labelWidth="110px"
                  placeholder="*Eingabe erforderlich" ></vsInputDateTime>
              </vsLayoutGroup>

            </vsLayoutGroup>

            <vsLayoutGroup class="col-lg-6 col-md-6 col-12" Orientation="vertical">

              <div class="row">
                <label for="Bild" class="vsInputLabel" style="width: 110px;">{{'application.student.image'| translate}}:</label>
                <div class="col">
                  <button pButton type="button" label="{{'application.student.button_img_upload'| translate}}" class="vsUploadContactImage"  (click)="showDlgDoc(DocType[0].ID)">
                    <div style="margin-bottom: 0.425rem;"><img alt="logo"
                        src="/assets/img/Icons/vs-plus-circle-outline-grey.svg" /></div>
                  </button>
                </div>
              </div> <!-- Ende row -->

            </vsLayoutGroup>

          </vsLayoutGroup>

        </vsLayoutGroup>

        <vsLayoutGroup class="col-lg-2 col-md-1 col-sm-0"></vsLayoutGroup>

      </vsLayoutGroup>


    </vsLayoutGroup>

  </form>

  <div style="margin-top: 1rem; margin-bottom: 1rem;">
    <button pButton label="{{'change.button_cancel'| translate}}" (click)="actionStepPrev()" class="vsElternButton vsBack"
      style="margin-right: 10px;"></button>
    <button pButton label="{{'change.button_send'| translate}}" (click)="actionStepNext()" class="vsElternButton vsForward"></button>
  </div>


<p-dialog header="{{'dialog.error_fill_heading'| translate}}" [(visible)]="displayDialogError" [baseZIndex]="10000" class="vsDialog vsDanger vsRequired">
  <p [innerHtml]="'dialog.error_fill_text'| translate"></p>
  <ng-template pTemplate="footer">
      <button pButton pRipple type="button" (click)="displayDialogError=false" label="{{'dialog.error_fill_button_ok'| translate}}" class="vsToolbarItem vsToolbarItemOK vsMb-2">
        <span>
          <img alt="close" src="/assets/img/Icons/vs-check-circle.svg" />
        </span>
      </button>
  </ng-template>
</p-dialog>

</div>
<!-- Ende container-xxl -->


<!-- Footer --------------------------------------------------------------------------------------->


<appFooter></appFooter>



<!-- Messages (Toasts) ---------------------------------------------------------------------------->


<p-toast></p-toast>


<!-- <pre>{{ this.editForm.value | json}}</pre> -->


  <form [formGroup]="this.editForm">

    <vsLayoutGroup class="col-12" Orientation="horizontal" MediumGutterWidth=true>

      <vsLayoutGroup class="col" MediumGutterWidth=true Orientation="horizontal">

        <vsLayoutGroup class="col-12">
          <div #explanation_header class="vsFormTextDiv">
            <b>{{'explanation.student_selection_heading'| translate}}</b>
          </div>
          <div #explanation_text class="vsFormTextDivParagraph vsMb-2">
            {{'explanation.student_selection_text'| translate}}
          </div>
        </vsLayoutGroup>

        <vsLayoutGroup class="col-xl-12 col-lg-12 col-md-12 vsMb-2 vsMt-2" Orientation="horizontal" MediumGutterWidth=true>

          <vsLayoutGroup *ngFor="let item of this.data" class="col-lg-auto col-md-auto col-sm-6 col-6" Orientation="horizontal">
            <button pButton type="button" label="{{item.xAzubiName}}" class="vsStudentButton" (click)="handleButtonClick(item)">
              <div style="margin-bottom: 0.425rem;"><img id="student-select-img" class="student-select-img" alt="logo" src="/assets/img/Icons/account-outline_grey.svg" width="24px" height="24px" />
              </div>
            </button>

          </vsLayoutGroup>

          <vsLayoutGroup class="col-lg-auto col-md-auto col-sm-4 col-6" Orientation="horizontal">
            <button pButton type="button" label="{{'application.button_new_student'| translate}}" class="vsStudentButton" (click)="handleButtonClick('new')">
              <div style="margin-bottom: 0.425rem;"><img alt="logo" src="/assets/img/Icons/vs-plus-circle-outline-grey.svg" /></div>
            </button>
          </vsLayoutGroup>

        

        </vsLayoutGroup>


        </vsLayoutGroup>


      </vsLayoutGroup>

  </form>

  <div style="margin-top: 1rem; margin-bottom: 1rem;">
    <button pButton label="{{'application.button_back'| translate}}" (click)="actionStepPrev()" class="vsElternButton vsBack" style="margin-right: 10px;"></button>
    <button pButton label="{{'application.button_forward'| translate}}" (click)="actionStepNext()" class="vsElternButton vsForward"></button>
  </div>

<p-dialog header="{{'dialog.error_fill_heading'| translate}}" [(visible)]="displayDialogError" [baseZIndex]="10000" class="vsDialog vsDanger vsRequired">
  <p [innerHtml]="'dialog.error_fill_text'| translate"></p>
  <ng-template pTemplate="footer">
      <button pButton pRipple type="button" (click)="displayDialogError=false" label="{{'dialog.error_fill_button_ok'| translate}}" class="vsToolbarItem vsToolbarItemOK vsMb-2">
        <span>
          <img alt="close" src="/assets/img/Icons/vs-check-circle.svg" />
        </span>
      </button>
  </ng-template>
</p-dialog>

<p-dialog header="{{'dialog.error_age_heading'| translate}}" [(visible)]="displayDialogAgeError" [baseZIndex]="10000" class="vsDialog vsDanger vsRequired">
  <p [innerHtml]="'dialog.error_age_text'| translate"></p>
  <ng-template pTemplate="footer">
      <button pButton pRipple type="button" (click)="displayDialogAgeError=false" label="{{'dialog.error_fill_button_ok'| translate}}" class="vsToolbarItem vsToolbarItemOK vsMb-2">
        <span>
          <img alt="close" src="/assets/img/Icons/vs-check-circle.svg" />
        </span>
      </button>
  </ng-template>
</p-dialog>

<!-- <pre>{{ this.editForm.value | json}}</pre> -->

<vsView>
  [ViewProperties] = "ViewProperties"
</vsView>


<div class="vsWrapper">


  <div *ngIf="FDeepLink" style="margin-top: 25%; text-align: center;" class="container">
    <div *ngIf="FDeepLinkOK">
      
      <p>{{'register.text_activation_success' | translate}}</p>

      <button pButton type="button" label="Weiter" class="vsElternButton"
        (click)="FDeepLink=false; ">
      </button>    

    </div>
    <div *ngIf="!FDeepLinkOK">
      <p *ngIf="FDeepLinkErrorExpired"><b>{{'register.error' | translate}}:</b>{{'register.text_activation_link_expired' | translate}}</p>

      <p *ngIf="FDeepLinkErrorActivated"><b>{{'register.error' | translate}}:</b>{{'register.text_already_activated' | translate}}</p>

      <p *ngIf="FDeepLinkErrorInvalid"><b>{{'register.error' | translate}}:</b>{{'register.text_activation_link_invalid' | translate}}</p>

      <button pButton type="button" label="Weiter" class="vsElternButton"
      (click)="FDeepLink=false; FDeepLinkErrorExpired=false; FDeepLinkErrorActivated=false; FDeepLinkErrorInvalid=false;">
    </button>

    </div>

  </div>

  <div *ngIf="!FDeepLink">



    <!-- Header: Standard ----------------------------------------------------------------------------->
    <appHeader></appHeader>



    <!-- Header: Banner ------------------------------------------------------------------------------->


    <div class="vsTopContent">
      <!--vsTopContent Start-->
      <div class="container">
        <!--container-xxl Start-->

        <div class="row">

          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 vsMainPageSubHeader">
            <h3 [innerHtml]="'home.heading'| translate">

            </h3>

            <button pButton type="button" label="{{'home.application_button'| translate}}" class="vsTopContentButton"
              (click)="actionLogin()">
              <span>
                <img src="/assets/img/Icons/schueler_Icon_grau.svg" alt="" />
              </span>
            </button>

            <ul class="vsTopContentList">
              <!-- <li><a style="cursor: pointer" (click)="actionLogin()" tabindex="0">{{'home.login'| translate}}</a></li>
              <li><a style="cursor: pointer" (click)="actionInfoPerso()" tabindex="0">{{'home.info_ID'| translate}}</a></li> -->
              <!-- <li><a style="cursor: pointer" (click)="actionInfoFAQ()" tabindex="0">{{'home.FAQ'| translate}}</a></li> -->
              <li><a style="cursor: pointer" [innerHtml]="'home.FAQ'| translate" href="{{dataModel.footerLinkFrequentQuestions}}" target="_blank" tabindex="0"></a></li>
            </ul>
          </div>
          <!--col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 Ende-->

          <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
            <img src="{{'header.img_url_bus'| translate}}" width="500px" class="vsMainPageImageBus" alt="">
          </div>
          <!--col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 Ende-->

        </div>
        <!--Ende row-->

      </div>
      <!--container Ende-->
    </div>
    <!--vsTopContentEnde-->


    <!-- Main ----------------------------------------------------------------------------------------->

    <div class="vsMainContent">
      <!--vsMainContent Start-->
      <div class="container">
        <!--container-xxl Start-->

        <!-- Features ------------------------------->

        <div class="row">


          <!-- Feature: Online beantragen ----------->
          <!-- TODO MP Variable einbauen um die 3 Blöcke konfigurierbar ein- und auszublenden -->
          <!-- <div class="col" style="text-align: center;">

            <img src="/assets/img/Images/interface_black_white.png" class="vsImageStudentTicket" alt="">

            <h4 [innerHtml]="'home.subheading_1'| translate">
            </h4>

            <p [innerHtml]="'home.text_block_1'| translate">
            <p>

          </div> -->
          <!--col Ende-->


          <!-- Feature: Bearbeitungsstatus ---------->

          <div class="col" style="text-align: center;">

            <img src="/assets/img/Images/Bearbeitungsstatus_black_white.png" class="vsImageViewStatus" alt="">

            <h4 [innerHtml]="'home.subheading_2'| translate">
            </h4>

            <p [innerHtml]="'home.text_block_2'| translate">
            <p>

          </div>
          <!--col-lg-4 Ende-->


          <!-- Feature: Transparenter Prozess ------->

          <div class="col" style="text-align: center;">

            <img src="/assets/img/Images/Schnell_Sicher.png" class="vsImageFastSecure" alt="">

            <h4 [innerHtml]="'home.subheading_3'| translate">
            </h4>

            <p [innerHtml]="'home.text_block_3'| translate">
            <p>

          </div>
          <!--col-lg-4 Ende-->


        </div>
        <!--Ende row-->


        <!-- Grafik --------------------------------->

        <div class="row mt-4">

          <div class="col-lg-12" style="text-align: center;">
            <!-- <h4 [innerHtml]="'home.application_howto'| translate"></h4> -->
            <img src="/assets/img/Images/Frau_Schreibtisch.png" width="600px" class="vsMainPageImageHowto" alt="">
          </div>

        </div>
        <!--Ende row-->

      </div>
      <!--container-xxl Ende-->
    </div>
    <!--vsTopContentEnde-->


    <!-- Footer --------------------------------------------------------------------------------------->

    <appFooter></appFooter>


  </div>
  <!--vsWrapper Ende-->

</div>


<!-- Messages (Toasts) ---------------------------------------------------------------------------->

<p-toast></p-toast>

//--------------------------------------------------------------------------------------------------
//  AMIS7school - Externes Portal: Eltern
//
//  Domain:   School
//  Title:    Grundantrag Schülerbeförderung (Edit View)
//  Notes:    MOCKUP
//
//
//  H I S T O R Y
//
//  2021-08-05  TC   Initial Version
//--------------------------------------------------------------------------------------------------

import {  Component, ViewChild
        , OnInit
        , ComponentFactoryResolver,
        ElementRef}         from '@angular/core';
import { ChangeDetectionStrategy, AfterViewInit } from '@angular/core';

import { Router }                           from '@angular/router';

import { FormGroup
        ,FormControl
        ,Validators }                       from '@angular/forms';

import { Platform }                         from '@angular/cdk/platform';

import  * as schoolDataModel                from 'AMIS7school_Portal_Eltern/src/app/school/schoolDataModel.service';

// [ngx-translate]
import { TranslateService }                 from '@ngx-translate/core';

// PrimeNG
import { MessageService }                   from 'primeng/api';
import { DialogService }                    from 'primeng/dynamicdialog';
import { DynamicDialogRef }                 from 'primeng/dynamicdialog';

// vsLib
import * as vsView                          from '@vsLib/View/components/vsView/vsView.component';
import * as vsGlobalResources               from '@vsLib/Services/vsGlobalResources.service';
import * as vsCommon                        from '@vsLib/vsCommon';

// AMIS7core
import * as AMIS7coreConst                  from '@AMIS7core/$Common/comConst';

import { TdlgDoc, TFileData }               from 'AMIS7school_Portal_Eltern/src/app/school/dlgDoc.component';

// Alyle Image Cropper
import { lyl, WithStyles } from '@alyle/ui';
import { ImgCropperConfig, ImgCropperEvent, LyImageCropper, ImgCropperErrorEvent, ImgCropperLoaderConfig } from '@alyle/ui/image-cropper';


type TranslationDialogItemsType = {
  data:         any;
  header:       string;
  width?:        string;
  styleClass:   string;
  style?:        any;
  baseZIndex:   number;
}

@Component({
  selector:       'schoolViewAntragEdit_Doc',
  templateUrl:  './schoolViewAntragEdit_Doc.component.html',
  // styleUrls:   ['./schoolViewAntragEdit_Doc.component.scss']
  providers: [DialogService]
})

export class TschoolViewAntragEdit_Doc extends vsView.TvsView implements OnInit {

  // // Alyle Image Cropper Anfang
  // //classes = this.sRenderer.renderSheet(STYLES);
  // croppedImage?: string;
  // scale: number;
  // ready: boolean;
  // minScale: number;
  // @ViewChild(LyImageCropper) cropper: LyImageCropper;
  // myConfig: ImgCropperConfig = {
  //   // autoCrop: true,
  //   width: 200, // Default `250` doppelte größe des Standard-Passfoto-Formates (100 x 128)
  //   height: 256, // Default `200`
  //   fill: '#ff2997', // Default transparent if type = png else #000
  //   type: 'image/png', // Or you can also use `image/jpeg`
  //   responsiveArea: true}
     // Alyle Image Cropper Ende

  //====== Properties (PRIVATE)

  // ./.

  //====== Properties (PUBLIC)

  // ./.
  public crmVorgData:   any[] = [];
  public DocType:       any[] =
    [
      {Caption: 'application.documents.button_load_document',      ID: 'Doc'}
    , {Caption: 'application.documents.button_load_user_image',    ID: 'Pic'}

    ]

  public      editForm:                     FormGroup               = new FormGroup({});

  public      Files:                        TFileData[] = [];

  public      refOpen:                      DynamicDialogRef;

  public      translationDialogItems:       TranslationDialogItemsType[]                          = [];

  @ViewChild('explanation_header')  explanation_header: ElementRef;
  @ViewChild('explanation_text')    explanation_text:   ElementRef;

  //====== Constructor

  ref: DynamicDialogRef;

  constructor(protected router:                 Router
            , protected globalResources:        vsGlobalResources.TvsGlobalResources
            , public    dataModel:              schoolDataModel.TschoolDataModel
            , public    dialogService:          DialogService
            , private   _platform:              Platform //Alyle Image Cropper
            , public    translate:              TranslateService


) {
    super(router, globalResources);

    console.log('#### TschoolViewAntragEdit_DOC: constructor');

    this.ViewProperties.Instance        = this;
    this.ViewProperties.ViewTitle       = 'Dokumente';

    this.ViewProperties.ProgFuncID      = null;
    this.ViewProperties.DataSourceID    = null;
    this.ViewProperties.QueryFields     = [];


  } // constructor


  //Upload-Dialog öffnen
  showDlgDoc(a_Value: any) {

    let x    = this.DocType.map(el => el.ID)
    let i    = x.indexOf(a_Value);


    this.translationDialogItems = [
      { data:       a_Value, 
        header:     'application.documents.button_load_document', 
        styleClass: 'vsDialog vsFullscreenDialog vsUploadDialog vsElternDialog',
        baseZIndex: 10000}
    ]

    this.translationDialogItems.map((item) => {
        this.translate.get(item.header).subscribe((res)=>{
    
            this.refOpen = this.dialogService.open(TdlgDoc, {
              data:         item.data,
              header:       res,
              styleClass:   item.styleClass,
              baseZIndex:   item.baseZIndex
    
            });
          });
        });
  
    this.refOpen.onClose.subscribe((fileData: any) =>{
        
      if (vsCommon.vsVarAssigend(fileData)) {
        this.Files.push(fileData);

        this.editForm.controls['Title'].enable();
        this.editForm.controls['MessageText'].enable();
      }
  
      console.log('this.Files' , this.Files);
  
    });

    // this.translationDialogItems = [
    //   { data:  this.searchDlgData, 
    //     header: 'dialog.title_search_school', 
    //     styleClass: 'vsDialog vsFullscreenDialog',
    //     baseZIndex: 10000}
    // ]
  
    // this.translationDialogItems.map((item) => {
    //   this.translate.get(item.header).subscribe((res)=>{
  
    //       this.refSearchDlg = this.dialogService.open(TvsSearchDlg, {
    //         data:         item.data,
    //         header:       res,
    //         width:        item.width,
    //         styleClass:   item.styleClass,
    //         style:        item.style,
    //         baseZIndex:   item.baseZIndex
  
    //       });
    //     });
    //   });
    
    //   this.refSearchDlg.onClose.subscribe((Data: any) => {
    //     if (vsCommon.vsVarAssigend(Data)) {
    //       this.schoolData = Data;
    //       this.editForm.controls['AusbStaett_Name'].setValue(Data.AusbStaett_Name);
    //       this.editForm.controls['Antr_ID_Ausb_Staett_Art'].setValue(Data.Art_Name);
    //       }
    //   });

   }
  
   //Löschen von Files
   docDelete(a_file: TFileData){
    let search = this.Files.map(el => el.UploadFileName);
    let i      = search.indexOf(a_file.UploadFileName);
    this.Files.splice(i, 1);
  }
  



    //Wenn Dokument vorhanden ist, laden des Dokumentes auf Button-Click
  docDownload(a_file: TFileData){
    let sExt = vsCommon.vsStringFileExtractExt(a_file.UploadFileName);
    if (vsCommon.vsStringSameText(sExt,'pdf')) {
      var a = document.createElement("a");
      a.href = "data:application/octet-stream;base64," + a_file.UploadFileDataBase64;
    }
    else {
      var a = document.createElement("a");
      a.href = "data:image/" + sExt + ";base64," + a_file.UploadFileDataBase64;
    }
    a.download = a_file.UploadFileName;
    a.click();
  }

  //====== Methods: NG event handler


  ngOnInit(): void {
    // this.crmVorgData.push(
    //   {Datum: "01.06.2021", Uhrzeit: "12:45", Name: "Test-Vorgang 1"}
    //  ,{Datum: "01.07.2021", Uhrzeit: "13:10", Name: "Test-Vorgang 2"}
    //  ,{Datum: "01.08.2021", Uhrzeit: "18:01", Name: "Test-Vorgang 3"}
    //  );


    this.editForm.addControl('Title', new FormControl(null));
    this.editForm.addControl('MessageText', new FormControl(null));

    //Sperren des Textfeldes wenn kein File hochgeladen wird.
    this.editForm.controls['Title'].disable();
    this.editForm.controls['MessageText'].disable();



    //Falls files vorhanden sind
    if (vsCommon.vsStringHasValue(this.dataModel.files)) {
      this.Files = this.dataModel.files;

      this.editForm.controls['Title'].enable();
      this.editForm.controls['MessageText'].enable();
    }

    if (vsCommon.vsStringHasValue(this.dataModel.vorgMain)) {

      this.editForm.controls['Title'].setValue(this.dataModel.vorgMain.crmActivityName);
      this.editForm.controls['MessageText'].setValue(this.dataModel.vorgMain.crmActivityDesc);

      this.editForm.controls['Title'].enable();
      this.editForm.controls['MessageText'].enable();
    }


 } // ngOnInit


 ngAfterViewInit(): void {

  //$REV MP 2023-03-17: Wenn in der Übersetzungsdatei kein Text für eine Erläuterung/Erklärung vorhanden ist wird das HTML-Element entfernt.
  if(this.explanation_header.nativeElement.innerText === '') {
    this.explanation_header.nativeElement.remove();
  }
  if(this.explanation_text.nativeElement.innerText === '') {
    this.explanation_text.nativeElement.remove();
  }

}




  public actionStepPrev(): void {

    if(this.dataModel.antrag_Art === 'E') {
      this.saveInDataModel();
      this.router.navigate(['antragEdit/erstattung']);
    }
    if(this.dataModel.antrag_Art === 'U') {
      this.saveInDataModel();
      this.router.navigate(['antragEdit/besondere']);
    }
    if(this.dataModel.antrag_Art === 'I') {
      this.saveInDataModel();
      this.router.navigate(['antragEdit/pickup']);
    }
    if(this.dataModel.antrag_Art === 'G') {
      this.saveInDataModel();
      this.router.navigate(['antragEdit/besondere']);
    }
    if(this.dataModel.antrag_Art === 'GA') {
      this.saveInDataModel();
      this.router.navigate(['antragEdit/besondere']);
    }
    if(this.dataModel.antrag_Art === 'EA') {
      this.saveInDataModel();
      this.router.navigate(['antragEdit/erstattung']);
    }

    // else {
    //   this.saveInDataModel();
    //   window.history.go(-1)
    //   console.log('window.history.go(-1)')
    // }

  } // actionStepPrev()

  

  public actionStepNext(): void {

    if(this.dataModel.antrag_Art === 'E') {
      this.saveInDataModel();
      this.router.navigate(['antragEdit/sepa']);
    }
    if(this.dataModel.antrag_Art === 'EA') {
      this.saveInDataModel();
      this.router.navigate(['antragEdit/sepa']);
    }
    if(this.dataModel.antrag_Art === 'G'|| this.dataModel.antrag_Art === 'GA') {
      this.saveInDataModel();
      this.router.navigate(['antragEdit/commit']);
    }
    if(this.dataModel.antrag_Art === 'U') {
      this.saveInDataModel();
      this.router.navigate(['antragEdit/commit']);
    }

  } // actionStepNext()



public saveInDataModel(): void {

  var dtStr: string = vsCommon.RetrieveDateStrNow();
  var tmStr: string = vsCommon.RetrieveTimeStrNow();

    // console.log('case Message mit Anhang');
    let vorgMain:  vsCommon.TVorgMain  =  new vsCommon.TVorgMain();

    // vorfID muss am Ende des Prozesses hinzugefügt werden.
    // vorgMain.crmTicketID               = this.vorfID;
  
    //Vorf_ID_Kontakt muss später noch hinzugefügt werden
    // vorgMain.crmContactID           = this.ParamList.get('Vorf_ID_Kontakt');
  
    vorgMain.crmActivityID             = null;

    console.log('this.Files', this.Files);

    if (this.Files.length == 0) {
      console.log('Keine Files');
      this.dataModel.KennAttachment = false;
    }

    //Falls Anhänge vorhanden sind.
    if (this.Files.length > 0) {
      vorgMain.crmActivityType           = vsCommon.TcrmVorgangArt.vaDokumentSonstiges;
      this.dataModel.KennMessageWithAttachment = true;
      this.dataModel.KennAttachment = true;

      vorgMain.crmActivityDate           = vsCommon.DateStrToISO8061(dtStr);
      vorgMain.crmActivityTime           = vsCommon.TimeStrToISO8061(tmStr);

      if (vsCommon.vsStringHasValue(this.editForm.controls["Title"].value)) {
        vorgMain.crmActivityName         = this.editForm.controls["Title"].value;
      }
      else {
        vorgMain.crmActivityName         = "Anhang zum Antrag"
      }

      if (vsCommon.vsStringHasValue(this.editForm.controls["MessageText"].value)) {
        vorgMain.crmActivityDesc         = this.editForm.controls["MessageText"].value;
      }
      else {
        vorgMain.crmActivityDesc         = " "
      }
     
      
      vorgMain.crmActivityMultiDoc       = false;
      vorgMain.crmActivityIsIncoming     = true;
          
      let vDoc: vsCommon.TVorg = new vsCommon.TVorg(vorgMain);
        for (let i=0; i < this.Files.length; i++) {
        vDoc.DocAdd(this.Files[i].UploadFileName,
        null,
        vsCommon.vsStringFileExtractExt(this.Files[i].UploadFileName),
        this.Files[i].UploadFileDataBase64);
      }
  
      this.dataModel.files = this.Files;
  
      this.dataModel.vorgMain = vorgMain;
    }
    // Falls in Zukunft Nachrichten ohne Anhang möglich sein sollten
    // else {
    //   vorgMain.crmActivityType           = vsCommon.TcrmVorgangArt.vaKommunikation;
    //   this.dataModel.KennMessageWithAttachment = false;
    //   this.dataModel.KennAttachment = true;
    // }


}


} // schoolViewAntragEdit_Doc



//--------------------------------------------------------------------------------------------------
//  AMIS7school - Externes Portal: Eltern
//
//  Domain:   School
//  Title:    Grundantrag Schülerbeförderung (Edit View)
//  Notes:    MOCKUP
//
//
//  H I S T O R Y
//
//  2021-08-05  TC   Initial Version
//--------------------------------------------------------------------------------------------------

import {  Component, ViewChild
        , OnInit
        , ComponentFactoryResolver,
        ElementRef}         from '@angular/core';

import { Router }                           from '@angular/router';

import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl, ValidationErrors }           from '@angular/forms';

import  * as schoolDataModel                from 'AMIS7school_Portal_Eltern/src/app/school/schoolDataModel.service';

import {  forkJoin }                        from 'rxjs';


// PrimeNG
import { MenuItem }                         from 'primeng/api';
import { MessageService }                   from 'primeng/api';
import { DialogService }                    from 'primeng/dynamicdialog';
import { DynamicDialogRef }                 from 'primeng/dynamicdialog';


// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import * as vsViewData                        from '@vsLib/View/components/vsViewData/vsViewData.component';
import * as vsGlobalResources               from '@vsLib/Services/vsGlobalResources.service';
import * as vsTabControl                    from '@vsLib/Components/vsTab/vsTabControl.component';

import { TvsGetType
        ,TvsHTTPMethod
        ,TvsHttpServiceComponent }          from '@vsLib/Services/vsHttp.service.component';

import { TvsDataFilter }                    from '@vsLib/vsDataFilter';
import { TvsDataObject }                    from '@vsLib/vsDataObject';


// AMIS7core
import * as AMIS7coreConst                  from '@AMIS7core/$Common/comConst';
import { formatDate } from '@angular/common';
import { asLiteral } from '@angular/compiler/src/render3/view/util';


import { TdlgDoc }                          from 'AMIS7school_Portal_Eltern/src/app/school/dlgDoc.component';
import { ThrowStmt }                        from '@angular/compiler';


@Component({
  selector:       'schoolViewAntragEdit_Student',
  templateUrl:  './schoolViewAntragEdit_Student.component.html',
  // styleUrls:   ['./schoolViewAntragEdit_Student.component.scss']
  providers: [DialogService]
})
export class TschoolViewAntragEdit_Student extends vsViewData.TvsViewData implements OnInit {


  //====== Properties (PRIVATE)

  // ./.

  //====== Properties (PUBLIC)

  public      editForm:                     FormGroup               = new FormGroup({});

  public Anrede: any[];
  public image;
  public cropped: boolean = false;

  public lookupAnrede:                  any[];

  //$REV MP: Neue Anreden-Datenmenge für Azubis um Ehepaar / Firma auszufiltern
  public lookupAnredeStudent:          any[];

  public lookupTitel:                   any[];
  public lookupProfilData:              any[];
  public lookupAdresse:                 any[];
  public lookupAdresseFiltered:         any[];

  public azubiCalculatedAge:            number;

  public KennAzubiGesVertr:             boolean = false;
  public KennOrtsteilRequired:          boolean = false;
  public KennStrassenVerz:              boolean = false;
  public KennAdrNotFoundAzubi:          boolean = false;

  public kennDisplayPersData:           boolean = false;

  public displayDialogError: boolean    = false;
  public displayDialogAgeError: boolean = false;
  // public data;

  public DocType:       any[] =
  [
    {Caption: 'Nutzerfoto laden',    ID: 'Pic'}

  ]

  @ViewChild('explanation_header')  explanation_header: ElementRef;
  @ViewChild('explanation_text')    explanation_text:   ElementRef;

  //====== Constructor


  ref: DynamicDialogRef;

  constructor(protected router:                     Router
    , protected globalResources:            vsGlobalResources.TvsGlobalResources
    , protected HttpServiceComponent:       TvsHttpServiceComponent
    , public    dataModel:                  schoolDataModel.TschoolDataModel
    , public    dialogService:              DialogService
) {
super(router, globalResources, HttpServiceComponent);

    this.ViewProperties.Instance        = this;
    this.ViewProperties.ViewTitle       = 'Schüler';

    this.ViewProperties.ProgFuncID      =  null;
    this.ViewProperties.DataSourceID    = null;
    this.ViewProperties.QueryFields     = [];

      //Füllen aus dem dataModel falls vorhanden.
      if(vsCommon.vsVarAssigend(this.dataModel.KennAzubiGesVertr)) {
        this.KennAzubiGesVertr = this.dataModel.KennAzubiGesVertr;
      }
      //$REV MP 2023-05-05: KennOrtsteilRequired kann in der app.config.json auf true oder false gesetzt werden, durch true wird die Azubi Ortsteil-Angabe verpflichtend.
      if(vsCommon.vsVarAssigend(this.dataModel.KennOrtsteilRequired)) {
        this.KennOrtsteilRequired = this.dataModel.KennOrtsteilRequired;
      }

      //$REV MP 2023-08-23: KennStrassenVerz kann in der app.config.json auf true oder false gesetzt werden
      if(vsCommon.vsVarAssigend(this.dataModel.KennStrassenVerz)) {
        this.KennStrassenVerz = this.dataModel.KennStrassenVerz;
      }

      if(vsCommon.vsVarAssigend(this.dataModel.KennAdrNotFoundAzubi)) {
        this.KennAdrNotFoundAzubi = this.dataModel.KennAdrNotFoundAzubi;
      }

  } // constructor

  //====== Methods: NG event handler

  ngOnInit(): void {

    this.kennDisplayPersData = this.dataModel.kennDisplayPersData;

    //Schüler
    this.editForm.addControl('KennAzubiGesVertr',   new FormControl(this.KennAzubiGesVertr));
    this.editForm.addControl('Azubi_ID_Anrede',     new FormControl(this.dataModel?.azubi_Anrede_Rec));
    this.editForm.addControl('Azubi_Vorname',       new FormControl(this.dataModel?.azubi_Vorname));
    this.editForm.addControl('Azubi_Nachname',      new FormControl(this.dataModel?.azubi_Nachname));
    this.editForm.addControl('Azubi_Geb_Datum',     new FormControl(this.dataModel?.azubi_Geb_Datum));

    this.editForm.addControl('Azubi_PLZ',           new FormControl(this.dataModel?.azubi_PLZ));
    this.editForm.addControl('Azubi_Ort',           new FormControl(this.dataModel?.azubi_Ort));
    this.editForm.addControl('Azubi_Ortsteil',      new FormControl(this.dataModel?.azubi_Ortsteil));
    this.editForm.addControl('Azubi_Strasse',       new FormControl(this.dataModel?.azubi_Strasse));
    this.editForm.addControl('Azubi_Adr_Zusatz',    new FormControl(this.dataModel?.azubi_Adr_Zusatz));
    this.editForm.addControl('Azubi_HausNr',        new FormControl(this.dataModel?.azubi_HausNr));
    this.editForm.addControl('Azubi_Phone',         new FormControl(this.dataModel?.azubi_Phone));
    this.editForm.addControl('Azubi_eMail',         new FormControl(this.dataModel?.azubi_eMail));
    this.editForm.addControl('Azubi_Mobile',        new FormControl(this.dataModel?.azubi_Mobile));

    // Erziehungsberechtigte/r
    this.editForm.addControl('AntrStell_ID_Anrede',     new FormControl(this.dataModel?.antrStell_Anrede_Rec));

    this.editForm.addControl('AntrStell_ID_Titel',      new FormControl(this.dataModel?.antrStell_Titel_Rec));

    this.editForm.addControl('AntrStell_Vorname',       new FormControl(this.dataModel?.antrStell_Vorname));
    this.editForm.addControl('AntrStell_Nachname',      new FormControl(this.dataModel?.antrStell_Nachname));
    this.editForm.addControl('AntrStell_Geb_Datum',     new FormControl(this.dataModel?.antrStell_Geb_Datum));

    this.editForm.addControl('AntrStell_PLZ',           new FormControl(this.dataModel?.antrStell_PLZ));
    this.editForm.addControl('AntrStell_Ort',           new FormControl(this.dataModel?.antrStell_Ort));
    this.editForm.addControl('AntrStell_Ortsteil',      new FormControl(this.dataModel?.antrStell_Ortsteil));
    this.editForm.addControl('AntrStell_Strasse',       new FormControl(this.dataModel?.antrStell_Strasse));
    this.editForm.addControl('AntrStell_HausNr',        new FormControl(this.dataModel?.antrStell_HausNr));
    this.editForm.addControl('AntrStell_Adr_Zusatz',    new FormControl(this.dataModel?.antrStell_Adr_Zusatz));
    this.editForm.addControl('AntrStell_Phone',         new FormControl(this.dataModel?.antrStell_Phone));
    this.editForm.addControl('AntrStell_eMail',         new FormControl(this.dataModel?.antrStell_eMail));
    this.editForm.addControl('AntrStell_Mobile',        new FormControl(this.dataModel?.antrStell_Mobile));

    if(this.KennStrassenVerz) {
      this.editForm.addControl('Azubi_StrassenVerz',       new FormControl(this.dataModel?.azubi_StrVerz));
      this.editForm.addControl('KennAdrNotFoundAzubi',     new FormControl(this.dataModel?.KennAdrNotFoundAzubi));
      // this.editForm.addControl('AntrStell_StrassenVerz',       new FormControl(this.dataModel?.antrStell_StrVerz));
    }

    //$REV MP 2023-09-08 Falls in den Steps vor und zurück gegangen wird, deaktivieren der GUI-Elemente
    if(this.editForm.get('KennAzubiGesVertr').value) {
      this.editForm.controls['Azubi_ID_Anrede'].disable();
      this.editForm.controls['Azubi_Vorname'].disable();
      this.editForm.controls['Azubi_Nachname'].disable();
      this.editForm.controls['Azubi_PLZ'].disable();
      this.editForm.controls['Azubi_Ort'].disable();
      this.editForm.controls['Azubi_Ortsteil'].disable();
      this.editForm.controls['Azubi_Strasse'].disable();
      this.editForm.controls['Azubi_HausNr'].disable();
    }

    this.dataDisplay();

  } // ngOnInit



  //$REV MP 2022-08-24: dataLoad mit forkJoin eingebaut, da es immer wieder dazu kam das lookupDatenmengen nicht rechtzeitig verfügbar waren.
  async dataLoad() {

    const adrDoKatPersAnrede = this.HttpServiceComponent.dataGet('adrDoKatPersAnrede', false, [], null, TvsGetType.gtNormal, false);
    const adrDoKatPersTitel = this.HttpServiceComponent.dataGet('adrDoKatPersTitel', false, [], null, TvsGetType.gtNormal, false);


//GDS-Aufruf 7 [OK] adrDoStammKontaktList
    // console.log('GDS_KontaktList Kontakt_ID');
// const adrDOStammKontaktList = this.HttpServiceComponent.dataGet('adrDOStammKontaktList', true, [], DataFilter, TvsGetType.gtNormal, false);
    const adrDOStammKontaktList = this.HttpServiceComponent.HttpServiceGetRequest('school/data/kontakt', '')


    const comDoStammStrVerzKomplett = this.HttpServiceComponent.dataGet('comDoStammStrVerzKomplett', true, [], null, TvsGetType.gtNormal, false);

    forkJoin([adrDoKatPersAnrede, adrDoKatPersTitel, adrDOStammKontaktList, comDoStammStrVerzKomplett]).subscribe((results: any) => {
      // this.lookupAnrede        = results[0]['Dataset'].Data.slice(1).filter((PKValue) => {return PKValue.Caption !== "UNBEK";});
      this.lookupAnrede        = results[0]['Dataset'].Data;

      this.lookupAnredeStudent = results[0]['Dataset'].Data.filter(item => {
        return item.PKValue !== 'EHEPAAR' && item.PKValue !== 'FIRMA';
      });

      this.lookupTitel         = results[1]['Dataset'].Data;
      this.lookupProfilData    = results[2]['Dataset'].Data;
      this.lookupAdresse       = results[3]['Dataset'].Data;

      Object.assign(this.DOMain.Dataset, results[3]['Dataset']);

      this.ViewProperties.SubjInitLoad.next();
    });

} // dataLoad

dataDisplay(): void {

  this.dataLoad().then(() => {

    this.ViewProperties.SubjInitLoad.subscribe((args) => {

      //Prüfung ob Daten im dataModel schon vorhanden sind
      if (vsCommon.vsStringHasValue(this.dataModel.azubi_Anrede)) {
        this.editForm.controls['Azubi_ID_Anrede'].setValue(vsCommon.vsRetrieveRecordByPKValue(this.lookupAnrede, this.dataModel.azubi_Anrede));
      }

      //Prüfen ob das dataModel schon gefüllt ist, wenn nicht vorbefüllen mit Daten des eingeloggten Users
      if (!vsCommon.vsStringHasValue(this.dataModel.azubi_PLZ)) {
        this.editForm.controls['Azubi_PLZ'].setValue(this.lookupProfilData[0]?.Adr_PLZ);
        this.dataModel.azubi_PLZ = this.lookupProfilData[0]?.Adr_PLZ;
      }
      if (!vsCommon.vsStringHasValue(this.dataModel.azubi_Ort)) {
        this.editForm.controls['Azubi_Ort'].setValue(this.lookupProfilData[0]?.Adr_Ort);
        this.dataModel.azubi_Ort = this.lookupProfilData[0]?.Adr_Ort;
      }
      if (!vsCommon.vsStringHasValue(this.dataModel.azubi_Ortsteil)) {
        this.editForm.controls['Azubi_Ortsteil'].setValue(this.lookupProfilData[0]?.Adr_Ortsteil);
        this.dataModel.azubi_Ortsteil = this.lookupProfilData[0]?.Adr_Ortsteil;
      }
      if (!vsCommon.vsStringHasValue(this.dataModel.azubi_Strasse)) {
        this.editForm.controls['Azubi_Strasse'].setValue(this.lookupProfilData[0]?.Adr_Strasse);
        this.dataModel.azubi_Strasse = this.lookupProfilData[0]?.Adr_Strasse;
      }
      if (!vsCommon.vsStringHasValue(this.dataModel.azubi_Adr_Zusatz)) {
        this.editForm.controls['Azubi_Adr_Zusatz'].setValue(this.lookupProfilData[0]?.azubi_Adr_Zusatz);
        this.dataModel.azubi_Adr_Zusatz = this.lookupProfilData[0]?.Adr_Name_Zusatz_1;
      }
      if (!vsCommon.vsStringHasValue(this.dataModel.azubi_HausNr)) {
        this.editForm.controls['Azubi_HausNr'].setValue(this.lookupProfilData[0]?.Adr_HausNr);
        this.dataModel.azubi_HausNr = this.lookupProfilData[0]?.Adr_HausNr;
      }



      //Vergleich der Daten mit dem Straßenverzeichnis
      if (vsCommon.vsStringHasValue(this.dataModel?.azubi_PLZ)
          && vsCommon.vsStringIsNotNull(this.dataModel?.azubi_Ort)
          && vsCommon.vsStringIsNotNull(this.dataModel?.azubi_Ortsteil)
          && vsCommon.vsStringIsNotNull(this.dataModel?.azubi_Strasse)
          && this.lookupAdresse.length > 0) {

            const filterCondition =
              `${this.dataModel?.azubi_PLZ} | ` +
              `${this.dataModel?.azubi_Ort} | ` +
              `${this.dataModel?.azubi_Ortsteil} | ` +
              `${this.dataModel?.azubi_Strasse}`;

            this.lookupAdresseFiltered = this.lookupAdresse.filter(data => {
            return data.xAnzeige === filterCondition;
            });

                //Wenn die gespeicherte Adresse nicht im Straßenverzeichnis gefunden werden kann, wird die Checkbox "Adresse ist im Straßenverzeichnis nicht vorhanden" automatisch aktiviert.
            if (this.lookupAdresseFiltered.length === 0 && this.KennStrassenVerz) {
              this.KennAdrNotFoundAzubi = true;
              this.editForm.controls['KennAdrNotFoundAzubi'].setValue(true);
            }
          //Füllen des Adressfeldes aus den gepeicherten Daten
          if(this.KennStrassenVerz) {
            this.editForm.controls['Azubi_StrassenVerz'].setValue(this.lookupAdresseFiltered[0]);
          }

        }

      // Erziehungsberechtigte/r
      if (!vsCommon.vsStringHasValue(this.dataModel.antrStell_Anrede_Rec)) {
        this.editForm.controls['AntrStell_ID_Anrede'].setValue(this.lookupProfilData[0]);
      }
      if (!vsCommon.vsStringHasValue(this.dataModel.antrStell_Titel_Rec)) {
        this.editForm.controls['AntrStell_ID_Titel'].setValue(this.lookupProfilData[0]?.Titel_Name);
      }
      if (!vsCommon.vsStringHasValue(this.dataModel.antrStell_Vorname)) {
        this.editForm.controls['AntrStell_Vorname'].setValue(this.lookupProfilData[0].Kontakt_Name2);
      }
      if (!vsCommon.vsStringHasValue(this.dataModel.antrStell_Nachname)) {
        this.editForm.controls['AntrStell_Nachname'].setValue(this.lookupProfilData[0].Kontakt_Name1);
      }
      if (!vsCommon.vsStringHasValue(this.dataModel.antrStell_Geb_Datum)) {
        this.editForm.controls['AntrStell_Geb_Datum'].setValue(vsCommon.ISO8061ToDateStr(this.lookupProfilData[0].Kontakt_Geb_Datum));
      }
      if (!vsCommon.vsStringHasValue(this.dataModel.antrStell_PLZ)) {
        this.editForm.controls['AntrStell_PLZ'].setValue(this.lookupProfilData[0].Adr_PLZ);
      }
      if (!vsCommon.vsStringHasValue(this.dataModel.antrStell_Ortsteil)) {
        this.editForm.controls['AntrStell_Ortsteil'].setValue(this.lookupProfilData[0].Adr_Ortsteil);
      }
      if (!vsCommon.vsStringHasValue(this.dataModel.antrStell_Ort)) {
        this.editForm.controls['AntrStell_Ort'].setValue(this.lookupProfilData[0].Adr_Ort);
      }
      if (!vsCommon.vsStringHasValue(this.dataModel.antrStell_Strasse)) {
        this.editForm.controls['AntrStell_Strasse'].setValue(this.lookupProfilData[0].Adr_Strasse);
      }
      if (!vsCommon.vsStringHasValue(this.dataModel.antrStell_Strasse)) {
        this.editForm.controls['AntrStell_HausNr'].setValue(this.lookupProfilData[0].Adr_HausNr);
      }
      if (!vsCommon.vsStringHasValue(this.dataModel.antrStell_Adr_Zusatz)) {
        this.editForm.controls['AntrStell_Adr_Zusatz'].setValue(this.lookupProfilData[0].AntrStell_Adr_Zusatz);
      }
      if (!vsCommon.vsStringHasValue(this.dataModel.antrStell_Phone)) {
        this.editForm.controls['AntrStell_Phone'].setValue(this.lookupProfilData[0].Phone);
      }
      if (!vsCommon.vsStringHasValue(this.dataModel.antrStell_eMail)) {
        this.editForm.controls['AntrStell_eMail'].setValue(this.lookupProfilData[0].eMail);
      }
      if (!vsCommon.vsStringHasValue(this.dataModel.antrStell_Mobile)) {
        this.editForm.controls['AntrStell_Mobile'].setValue(this.lookupProfilData[0].Mobile);
      }

      //$REV MP 2023-12-06: Bankdaten hinzugefügt, falls in Profildaten schon vorhanden.
      //$REV MP 2024-05-27: Müssen aus Datenschutzgründen leer bleiben / Müssen neu eingegeben werden.
      // if (!vsCommon.vsStringHasValue(this.dataModel.antrStell_Bank_ID)) {
      //   this.dataModel.antrStell_Bank_ID = this.lookupProfilData[0]?.Bank_ID_Bank;
      // }
      // if (!vsCommon.vsStringHasValue(this.dataModel.antrStell_Kto_Inh)) {
      //   this.dataModel.antrStell_Kto_Inh = this.lookupProfilData[0]?.Bank_Inhaber_Name;
      // }
      // if (!vsCommon.vsStringHasValue(this.dataModel.antrStell_IBAN)) {
      //   this.dataModel.antrStell_IBAN = this.lookupProfilData[0]?.Bank_IBAN;
      // }
      // if (!vsCommon.vsStringHasValue(this.dataModel.antrStell_Bank_Name)) {
      //   this.dataModel.antrStell_Bank_Name = this.lookupProfilData[0]?.Bank_Name;
      // }
      // if (!vsCommon.vsStringHasValue(this.dataModel.antrStell_Bank_BIC)) {
      //   this.dataModel.antrStell_Bank_BIC = this.lookupProfilData[0]?.Bank_BIC;
      // }

      //Input-Felder sperren wenn eine KontaktID vorhanden ist.
      if (vsCommon.vsStringHasValue(this.globalResources.WebAccProfile.KontaktID)) {
        // this.editForm.controls['AntrStell_ID_Anrede'].disable();
        this.editForm.controls['AntrStell_ID_Titel'].disable();
        this.editForm.controls['AntrStell_Vorname'].disable();
        this.editForm.controls['AntrStell_Nachname'].disable();
        //$REV MP 2023-09-08: Da bei der Registrierung kein Pflichtfeld, sollte das Geburtsdatum nicht gesperrt sein.
        // this.editForm.controls['AntrStell_Geb_Datum'].disable();
        this.editForm.controls['AntrStell_PLZ'].disable();
        this.editForm.controls['AntrStell_Ort'].disable();
        this.editForm.controls['AntrStell_Strasse'].disable();
        this.editForm.controls['AntrStell_HausNr'].disable();
      }
        this.ViewProperties.SubjDisplay.next();
        this.ViewProperties.SubjDisplay.complete();
      })
  })
}



  ngAfterViewInit(): void {

    //$REV MP 2023-03-17: Wenn in der Übersetzungsdatei kein Text für eine Erläuterung/Erklärung vorhanden ist wird das HTML-Element entfernt.
    if(this.explanation_header.nativeElement.innerText === '') {
      this.explanation_header.nativeElement.remove();
    }
    if(this.explanation_text.nativeElement.innerText === '') {
      this.explanation_text.nativeElement.remove();
    }
  }


  public actionStepPrev(): void {
    this.dataSave();
    // this.router.navigate(['antragEdit/antrag']);
    this.router.navigate(['antragEdit/studentSelection']);
  }

  public actionStepNext(): void {

    let bOK:        boolean       = true;

    if (bOK && this.editForm.get('Azubi_Geb_Datum').value) {
      //Aufrufen der Altersberechnung zur Validierung
      // if (this.editForm.get('KennAzubiGesVertr').value === true) {
        this.getAge(this.editForm.get('Azubi_Geb_Datum').value);
    }


    if (bOK) {

      // Validierung Schülerdaten
      this.editForm.get('Azubi_ID_Anrede').setValidators(Validators.required);
      this.editForm.get('Azubi_ID_Anrede').updateValueAndValidity();

      this.editForm.get('Azubi_Vorname').setValidators(Validators.required);
      this.editForm.get('Azubi_Vorname').updateValueAndValidity();

      this.editForm.get('Azubi_Nachname').setValidators(Validators.required);
      this.editForm.get('Azubi_Nachname').updateValueAndValidity();

      this.editForm.get('Azubi_Geb_Datum').setValidators([Validators.required, this.validateDateLessThanToday()]);
      this.editForm.get('Azubi_Geb_Datum').updateValueAndValidity();

      this.editForm.get('Azubi_PLZ').setValidators(Validators.required);
      this.editForm.get('Azubi_PLZ').updateValueAndValidity();

      this.editForm.get('AntrStell_Ort').setValidators(Validators.required);
      this.editForm.get('AntrStell_Ort').updateValueAndValidity();

      this.editForm.get('Azubi_Strasse').setValidators(Validators.required);
      this.editForm.get('Azubi_Strasse').updateValueAndValidity();

      this.editForm.get('Azubi_HausNr').setValidators(Validators.required);
      this.editForm.get('Azubi_HausNr').updateValueAndValidity();


      if(this.KennOrtsteilRequired) {
        this.editForm.get('Azubi_Ortsteil').setValidators(Validators.required);
        this.editForm.get('Azubi_Ortsteil').updateValueAndValidity();
      }

      // Validierung Gesetzl. Vertretung

      this.editForm.get('AntrStell_ID_Anrede').setValidators(Validators.required);
      this.editForm.get('AntrStell_ID_Anrede').updateValueAndValidity();

      this.editForm.get('AntrStell_Vorname').setValidators(Validators.required);
      this.editForm.get('AntrStell_Vorname').updateValueAndValidity();

      this.editForm.get('AntrStell_Nachname').setValidators(Validators.required);
      this.editForm.get('AntrStell_Nachname').updateValueAndValidity();

      //TODO MP falls gesetzlicher Vertreter vorhanden ist muss hier nicht zusätzlich geprüft werden.
      // this.editForm.get('AntrStell_Geb_Datum').setValidators(Validators.required);
      // this.editForm.get('AntrStell_Geb_Datum').updateValueAndValidity();

      this.editForm.get('AntrStell_PLZ').setValidators(Validators.required);
      this.editForm.get('AntrStell_PLZ').updateValueAndValidity();

      this.editForm.get('AntrStell_Ort').setValidators(Validators.required);
      this.editForm.get('AntrStell_Ort').updateValueAndValidity();

      this.editForm.get('AntrStell_Strasse').setValidators(Validators.required);
      this.editForm.get('AntrStell_Strasse').updateValueAndValidity();

      this.editForm.get('AntrStell_HausNr').setValidators(Validators.required);
      this.editForm.get('AntrStell_HausNr').updateValueAndValidity();

      bOK = !this.editForm.invalid;
      this.displayDialogError = !bOK;

    }

    if (bOK && this.azubiCalculatedAge < 18 && this.editForm.get('KennAzubiGesVertr').value === true) {
      //Fehlermeldung triggern.
      this.displayDialogAgeError = true;
      bOK = false;
    }

    if (bOK && this.dataModel.antrag_Art === 'GA') {
      this.dataSave();
      this.router.navigate(['antragEdit/school_ausb']);
    }

    if (bOK && this.dataModel.antrag_Art !== 'GA') {
      this.dataSave();
      this.router.navigate(['antragEdit/school']);
    }

  } // actionStepNext()



  public dataSave() :void{

    if (vsCommon.vsVarAssigend(this.editForm.controls['Azubi_ID_Anrede'].value)) {
      this.dataModel.azubi_Anrede_Rec  =  this.editForm.controls['Azubi_ID_Anrede'].value;
      this.dataModel.azubi_Anrede  =  this.editForm.controls['Azubi_ID_Anrede'].value.PKValue;
    }

    //Wenn der Schüler der eigene Vertreter ist
    if(this.editForm.controls['KennAzubiGesVertr'].value === true) {
      this.dataModel.KennAzubiGesVertr = true;

      //$REV MP 07-02-2023: Azubi-ID darf momentan vom Elternportal aus nicht mitübermittelt werden, führt zu Fehlern.
      // this.dataModel.azubi_ID                  = this.globalResources.WebAccProfile.KontaktID;
      this.dataModel.antr_ID_Kontakt_AntrStell = this.globalResources.WebAccProfile.KontaktID;
    }

    // this.dataModel.azubi_Anrede       =  this.editForm.controls['Azubi_ID_Anrede'].value.PKValue;

    this.dataModel.azubi_Vorname      =  this.editForm.controls['Azubi_Vorname'].value;
    this.dataModel.azubi_Nachname     =  this.editForm.controls['Azubi_Nachname'].value;
    this.dataModel.azubi_Geb_Datum    =  this.editForm.controls['Azubi_Geb_Datum'].value;;
    // this.dataModel.azubi_Geb_Datum    =  vsCommon.DateStrToISO8061(this.editForm.controls['Azubi_Geb_Datum'].value);
    this.dataModel.azubi_Geb_Datum    =  this.editForm.controls['Azubi_Geb_Datum'].value;
    this.dataModel.azubi_Strasse      =  this.editForm.controls['Azubi_Strasse'].value;
    this.dataModel.azubi_HausNr       =  this.editForm.controls['Azubi_HausNr'].value;
    this.dataModel.azubi_Adr_Zusatz   =  this.editForm.controls['Azubi_Adr_Zusatz'].value;
    this.dataModel.azubi_PLZ          =  this.editForm.controls['Azubi_PLZ'].value;
    this.dataModel.azubi_Ort          =  this.editForm.controls['Azubi_Ort'].value;
    this.dataModel.azubi_Ortsteil     =  this.editForm.controls['Azubi_Ortsteil'].value;
    this.dataModel.azubi_Phone        =  this.editForm.controls['Azubi_Phone'].value;
    this.dataModel.azubi_eMail        =  this.editForm.controls['Azubi_eMail'].value;
    this.dataModel.azubi_Mobile       =  this.editForm.controls['Azubi_Mobile'].value;

    //Wenn der Schüler sich selbst vertritt, müssen die Antragstellerdaten nicht gespeichert werden
    if (this.editForm.controls['KennAzubiGesVertr'].value === false) {

      this.dataModel.KennAzubiGesVertr = false;

      // Hier nochmal leeren, falls man die Checkbox vorher aktiviert und wieder deaktiviert hat.
      // this.dataModel.azubi_ID = '';

      this.dataModel.antr_ID_Kontakt_AntrStell = this.globalResources.WebAccProfile.KontaktID;

      if (vsCommon.vsVarAssigend(this.editForm.controls['AntrStell_ID_Anrede'].value)) {
        this.dataModel.antrStell_Anrede_Rec  =  this.editForm.controls['AntrStell_ID_Anrede'].value;
      }

      if (vsCommon.vsVarAssigend(this.editForm.controls['AntrStell_ID_Titel'].value)) {
        this.dataModel.antrStell_Titel_Rec  =  this.editForm.controls['AntrStell_ID_Titel'].value;
      }
      //$REV MP 2023-09-08 : Korrektur auf PKValue
      this.dataModel.antrStell_Anrede        =  this.editForm.controls['AntrStell_ID_Anrede'].value.PKValue;
      this.dataModel.antrStell_Titel         =  this.editForm.controls['AntrStell_ID_Titel'].value;

      this.dataModel.antrStell_Vorname       =  this.editForm.controls['AntrStell_Vorname'].value;
      this.dataModel.antrStell_Nachname      =  this.editForm.controls['AntrStell_Nachname'].value;
      this.dataModel.antrStell_Geb_Datum     =  this.editForm.controls['AntrStell_Geb_Datum'].value;
      // this.dataModel.antrStell_Geb_Datum    =  vsCommon.DateStrToISO8061(this.editForm.controls['AntrStell_Geb_Datum'].value);
      this.dataModel.antrStell_Geb_Datum     =  this.editForm.controls['AntrStell_Geb_Datum'].value;
      this.dataModel.antrStell_Strasse       =  this.editForm.controls['AntrStell_Strasse'].value;
      this.dataModel.antrStell_Ortsteil      =  this.editForm.controls['AntrStell_Ortsteil'].value;
      this.dataModel.antrStell_HausNr        =  this.editForm.controls['AntrStell_HausNr'].value;
      this.dataModel.antrStell_Adr_Zusatz    =  this.editForm.controls['AntrStell_Adr_Zusatz'].value;
      this.dataModel.antrStell_PLZ           =  this.editForm.controls['AntrStell_PLZ'].value;
      this.dataModel.antrStell_Ort           =  this.editForm.controls['AntrStell_Ort'].value;
      this.dataModel.antrStell_Phone         =  this.editForm.controls['AntrStell_Phone'].value;
      this.dataModel.antrStell_eMail         =  this.editForm.controls['AntrStell_eMail'].value;
      this.dataModel.antrStell_Mobile        =  this.editForm.controls['AntrStell_Mobile'].value;
    }

  }


  createImagePreview(fileData) {

    var arrayBuffer = fileData.UploadFileDataBinary;
      var bytes = new Uint8Array(arrayBuffer);
      var blob = new Blob([bytes.buffer]);
       var img = document.createElement("img");
       img.src = fileData.croppedImage;
       this.cropped = true;

        document.getElementById("image").appendChild(img);
        img.addEventListener('click', () => this.showDlgDoc(this.DocType[0].ID));
        img.setAttribute('id', 'shownImage');


      var reader = new FileReader();
      reader.onloadend = (e) => {

      };
      reader.readAsDataURL(blob);
  }

  showDlgDoc(a_Value: any) {

    let x    = this.DocType.map(el => el.ID)
    let i    = x.indexOf(a_Value);

    this.ref = this.dialogService.open(TdlgDoc, {
        data:         a_Value,
        header:       this.DocType[i].Caption,
        width:        '56%',
        style:        { 'min-width': '750px' },
        styleClass:   'vsDialog',
//        contentStyle: {"max-height": "500px", "overflow": "auto"},
        baseZIndex:   10000
    });


    this.ref.onClose.subscribe((fileData: any) =>{
       if(document.getElementById('shownImage')) {
        document.getElementById("shownImage").remove();
        this.createImagePreview(fileData);
       } else {
        this.createImagePreview(fileData);
      }

    });
}


private validateDateLessThanToday() :ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const todayDateStr = vsCommon.DateStrToISO8061(vsCommon.RetrieveDateStrNow());
    const inputDateStr = vsCommon.DateStrToISO8061(control.value as string);

    let isOlder:boolean = inputDateStr < todayDateStr;
    return isOlder ? null: {dateValidator: {errorMsg: 'Value date has to be older than today\'s date.', value: control.value}};
  };
}

  //Funktion zur Berechnung des aktuellen Alters
  getAge(dateString) {

    var today = new Date();
    var birthDate = new Date(vsCommon.DateStrToISO8061(dateString));

    var age = today.getFullYear() - birthDate.getFullYear();
    //getMonth() + 1 weil die Monate in Zahlen von 0-11 ausgegeben werden
    var m = (today.getMonth() + 1) - (birthDate.getMonth() + 1);

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate()))
    {
      age = age - 1;
      if (age < 0) {
        age = 0;
      }
    }

    this.azubiCalculatedAge = age;

    return age;
  }

  //Wird über die Checkbox im HTML aufgerufen, wenn der Schüler volljährig ist.
  doSetGesetzVertret() {

    this.KennAzubiGesVertr = this.editForm.controls['KennAzubiGesVertr'].value;

    //Füllen der Schülerdaten aus den Angaben im Profil und Sperren der GUI + Ausblenden des Blockes Gesetzlicher Vertreter + leeren der Daten des Antragstellers.
    if(this.editForm.controls['KennAzubiGesVertr'].value) {
      //$REV MP 2023-09-08: Änderung auf RetrieveRecord
      this.editForm.controls['Azubi_ID_Anrede'].setValue(vsCommon.vsRetrieveRecordByPKValue(this.lookupAnrede, this.lookupProfilData[0].Anrede_ID));
      this.editForm.controls['Azubi_Vorname'].setValue(this.lookupProfilData[0].Kontakt_Name2);
      this.editForm.controls['Azubi_Nachname'].setValue(this.lookupProfilData[0].Kontakt_Name1);
      this.editForm.controls['Azubi_Geb_Datum'].setValue(vsCommon.ISO8061ToDateStr(this.lookupProfilData[0].Kontakt_Geb_Datum));
      this.editForm.controls['Azubi_PLZ'].setValue(this.lookupProfilData[0].Adr_PLZ);
      this.editForm.controls['Azubi_Ort'].setValue(this.lookupProfilData[0].Adr_Ort);
      this.editForm.controls['Azubi_Ortsteil'].setValue(this.lookupProfilData[0].Adr_Ortsteil);
      this.editForm.controls['Azubi_Strasse'].setValue(this.lookupProfilData[0].Adr_Strasse);
      this.editForm.controls['Azubi_HausNr'].setValue(this.lookupProfilData[0].Adr_HausNr);
      this.editForm.controls['Azubi_Phone'].setValue(this.lookupProfilData[0].Phone);
      this.editForm.controls['Azubi_eMail'].setValue(this.lookupProfilData[0].eMail);
      this.editForm.controls['Azubi_Mobile'].setValue(this.lookupProfilData[0].Mobile);

      //Sperren der Inputs
      this.editForm.controls['Azubi_ID_Anrede'].disable();
      this.editForm.controls['Azubi_Vorname'].disable();
      this.editForm.controls['Azubi_Nachname'].disable();
      //$REV MP 2023-09-08: Da bei der Registrierung kein Pflichtfeld, sollte das Geburtsdatum nicht gesperrt sein.
      // this.editForm.controls['Azubi_Geb_Datum'].disable();
      this.editForm.controls['Azubi_PLZ'].disable();
      this.editForm.controls['Azubi_Ort'].disable();
      this.editForm.controls['Azubi_Ortsteil'].disable();
      this.editForm.controls['Azubi_Strasse'].disable();
      this.editForm.controls['Azubi_HausNr'].disable();
      // this.editForm.controls['Azubi_Phone'].disable();
      // this.editForm.controls['Azubi_eMail'].disable();
      // this.editForm.controls['Azubi_Mobile'].disable();

      this.dataModel.KennAzubiGesVertr = this.editForm.controls['KennAzubiGesVertr'].value;
    }

    if(!this.editForm.controls['KennAzubiGesVertr'].value) {

      this.editForm.controls['Azubi_ID_Anrede'].setValue(null);
      this.editForm.controls['Azubi_Vorname'].setValue(null);
      this.editForm.controls['Azubi_Nachname'].setValue(null);
      this.editForm.controls['Azubi_Geb_Datum'].setValue(null);
      this.editForm.controls['Azubi_PLZ'].setValue(this.lookupProfilData[0].Adr_PLZ);
      this.editForm.controls['Azubi_Ort'].setValue(this.lookupProfilData[0].Adr_Ort);
      this.editForm.controls['Azubi_Ortsteil'].setValue(this.lookupProfilData[0].Adr_Ortsteil);
      this.editForm.controls['Azubi_Strasse'].setValue(this.lookupProfilData[0].Adr_Strasse);
      this.editForm.controls['Azubi_HausNr'].setValue(this.lookupProfilData[0].Adr_HausNr);
      this.editForm.controls['Azubi_Phone'].setValue(null);
      this.editForm.controls['Azubi_eMail'].setValue(null);
      this.editForm.controls['Azubi_Mobile'].setValue(null);

      //Entsperren der Inputs
      this.editForm.controls['Azubi_ID_Anrede'].enable();
      this.editForm.controls['Azubi_Vorname'].enable();
      this.editForm.controls['Azubi_Nachname'].enable();
      this.editForm.controls['Azubi_Geb_Datum'].enable();
      this.editForm.controls['Azubi_PLZ'].enable();
      this.editForm.controls['Azubi_Ort'].enable();
      // this.editForm.controls['Azubi_Ortsteil'].enable();
      this.editForm.controls['Azubi_Strasse'].enable();
      this.editForm.controls['Azubi_HausNr'].enable();
      // this.editForm.controls['Azubi_Phone'].enable();
      // this.editForm.controls['Azubi_eMail'].enable();
      // this.editForm.controls['Azubi_Mobile'].enable();

      this.dataModel.KennAzubiGesVertr = this.editForm.controls['KennAzubiGesVertr'].value;
    }
  }


  doSelectAdresse(data) {

    this.dataModel.azubi_StrVerz = data;

    if (vsCommon.vsStringHasValue(data.OrtPLZ_PLZ)) {
      this.editForm.controls['Azubi_PLZ'].setValue(data.OrtPLZ_PLZ);
    }
    if (vsCommon.vsStringHasValue(data.Ortsteil_Name)) {
      this.editForm.controls['Azubi_Ortsteil'].setValue(data.Ortsteil_Name);
    }
    if (vsCommon.vsStringHasValue(data.Ort_Name)) {
      this.editForm.controls['Azubi_Ort'].setValue(data.Ort_Name);
    }
    if (vsCommon.vsStringHasValue(data.Str_Name)) {
      this.editForm.controls['Azubi_Strasse'].setValue(data.Str_Name);
    }

  }

  doActivateManualInput() {

    //Freischalten der Input-Felder für die manuelle Eingabe, evtl. Wechsel von html-hidden zu visible
    if(this.editForm.controls['KennAdrNotFoundAzubi'].value) {
      this.KennAdrNotFoundAzubi = true;
    }

    if(!this.editForm.controls['KennAdrNotFoundAzubi'].value) {
      this.KennAdrNotFoundAzubi = false;
    }

  }

} // schoolViewAntragEdit_Student



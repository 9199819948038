
<div class="container-xxl"  style="margin-top: 1.5rem; margin-bottom: 1.5rem; min-height: 608px; color: #808080; ">
  
  <form [formGroup]="this.editForm">

    <vsLayoutGroup class="col-12" Orientation="horizontal" MediumGutterWidth=true>
    
      <vsLayoutGroup class="col-lg-2 col-md-1 col-sm-0" Orientation="horizontal"></vsLayoutGroup>
      
        <vsLayoutGroup class="col-lg-8 col-md-10 col-sm-12" Orientation="horizontal">

          <vsLayoutGroup class="col-12">



            <ng-template [ngIf]="(this.dataModel.antrag_Art === 'E' || this.dataModel.antrag_Art === 'EA')"
            [ngIfElse]="elseBlock">
            <div #explanation_header class="vsFormTextDiv">
              <b>{{'explanation.documents_refund_heading'| translate}}</b>
            </div>
            <div #explanation_text class="vsFormTextDivParagraphLine vsMb-2" [innerHTML]="'explanation.documents_refund_text' | translate">
            </div>
          </ng-template>

          <ng-template #elseBlock>
            <div #explanation_header class="vsFormTextDiv">
              <b>{{'explanation.documents_data_heading'| translate}}</b>
            </div>
            <div #explanation_text class="vsFormTextDivParagraphLine vsMb-2" [innerHTML]="'explanation.documents_data_text' | translate" style="display:block!important;">
            </div>
          </ng-template>

          </vsLayoutGroup>
      
          <vsLayoutGroup class="col-12" isPanel=true panelTitleVisible=true panelTitleText="{{'application.documents.title' | translate}}" Orientation="horizontal">

            <vsLayoutGroup class="col-3" Orientation="vertical">

                <div *ngFor="let item of DocType">
                  <button pButton label="{{item.Caption | translate}}" (click)="showDlgDoc(item.ID)" class="vsElternButton vsMb-2"></button>
                </div>

              <div class="vsMb-2"></div>
            </vsLayoutGroup>
      
            <vsLayoutGroup class="col" Orientation="vertical">

                  <div *ngFor="let item of Files">
          
                    <vsLayoutGroup class="col" HasPaddingBottom=true>

                          <div style="display: flex; justify-content : space-between">
                            
                            <button pButton label="{{'application.documents.download' | translate}}: 	&quot;{{item.UploadFileName}}&quot;" (click)="docDownload(item)" class="vsElternButton vsMb-2"></button>
                          
                            <button pButton label="{{'application.documents.delete' | translate}}" (click)="docDelete(item)" class="vsElternButton vsMb-2"></button>

                          </div>
                    </vsLayoutGroup>
      
                  </div>

            </vsLayoutGroup>
      
          </vsLayoutGroup>

        </vsLayoutGroup>
    
      <vsLayoutGroup class="col-lg-2 col-md-1 col-sm-0" Orientation="horizontal"></vsLayoutGroup>  
    
    </vsLayoutGroup>

    <div class="vsMb-2"></div>


    <vsLayoutGroup class="col-12" Orientation="horizontal" MediumGutterWidth=true>

      <vsLayoutGroup class="col-lg-2 col-md-1 col-sm-0" Orientation="horizontal"></vsLayoutGroup>  

      <vsLayoutGroup class="col-lg-8 col-md-10 col-sm-12" isPanel=true panelTitleVisible=true panelTitleText="{{'application.documents.title_commentary' | translate}}" Orientation="horizontal">

          <vsLayoutGroup class="col-12">
            <vsInputText FrmCtlName="Title" label="{{'messages.title'| translate}}"  labelWidth="125px" pTooltip="{{'application.input_attachment_required' | translate}}" tooltipPosition="top" tooltipStyleClass="vsTooltip"></vsInputText>
          </vsLayoutGroup>

          <vsLayoutGroup class="col-12">
            <vsInputMemo FrmCtlName="MessageText" label="{{'messages.your_message'| translate}}" 
                         labelWidth="125px" Rows="8" maxLength=4000 
                         counter=true pTooltip="{{'application.input_attachment_required' | translate}}" 
                         tooltipPosition="top" tooltipStyleClass="vsTooltip"></vsInputMemo>
          </vsLayoutGroup>

      </vsLayoutGroup>  

      <vsLayoutGroup class="col-lg-2 col-md-1 col-sm-0" Orientation="horizontal"></vsLayoutGroup>  

    </vsLayoutGroup>

  </form>


  <div style="margin-top: 1rem; margin-bottom: 1rem; margin-right: 0; display: flex;">
    <button pButton label="{{'dialog.button_back'| translate}}" (click)="actionStepPrev()" class="vsElternButton vsBack" style="margin-right: 10px;"></button>
    <button pButton label="{{'application.button_forward'| translate}}" (click)="actionStepNext()" class="vsElternButton vsForward"></button>
  </div>


</div> <!-- Ende container-xxl -->







<!-- Header: Standard ----------------------------------------------------------------------------->


<appHeader></appHeader>



<!-- Main Content --------------------------------------------------------------------------------->


<div class="container-xxl vsMainContentContainer">



  <h4 class="mb-4 vsElternLoginTitle">
    {{'messages.messages_header'| translate}}
  </h4>

  <!-- <div class="row mt-2 mb-2 vsGutterWidth-2 vsPortalList">

    <div class="col-2"><b>Antragsinformationen</b></div>  

    <div class="col-10"></div>  

  </div> -->

  <div class="row mt-2 mb-2 vsGutterWidth-2 vsPortalList">

    <div class="col-lg-5 col-sm-12" style="line-height:1.5;"><b>{{'messages.application_kind'| translate}}:</b> {{dataModel.antrag?.Art_Name}}</div>  

    <div class="col-lg-4 col-sm-12" style="line-height:1.5;"><b>{{'messages.student'| translate}}:</b> {{antrAzubiName}}</div>  

    <div class="col-lg-3 col-sm-12" style="line-height:1.5;"><b>{{'messages.application_date'| translate}}:</b> {{antrDatum}}</div> 

  </div>

  <div style="display: inline-flex;">

    <button pButton style="margin-right: 10px;" label="{{'messages.button_new'| translate}}" (click)="actionNewMessage()" class="vsElternButton" icon="pi pi-plus"></button>
    <button pButton style="margin-right: 10px;" label="{{'messages.button_read'| translate}}" (click)="actionReadMessage()" class="vsElternButton" icon="pi pi-comment"></button>
    <!-- <button pButton style="margin-right: 10px;" label="Antworten"  class="vsElternButton" icon="pi pi-comments"></button>
    <button pButton style="margin-right: 10px;" label="Löschen"  class="vsElternButton" icon="pi pi-trash"></button> -->

  </div>


<div class="vsElternPortalAntrList">
  <vsLayoutGroup class="col">
    <vsTable #table [DOMain]="dataObject" (onRowSelect)=doTableRowSelect($event)></vsTable>
  </vsLayoutGroup>
</div>

<!-- 
<vsViewDataList
  [ViewProperties]            = "ViewProperties"
></vsViewDataList> -->

<div style="margin-top: 1rem; margin-bottom: 1rem;">
  <button pButton label="{{'application.button_back'| translate}}" (click)="actionStepPrev()" class="vsElternButton vsBack" style="margin-right: 10px;"></button>
  <!-- <button pButton label="{{'application.button_forward'| translate}}" (click)="actionStepNext()" class="vsElternButton"></button> -->
</div>

</div> <!-- Ende container-xxl -->




<!-- Footer --------------------------------------------------------------------------------------->


<appFooter></appFooter>



<!-- Messages (Toasts) ---------------------------------------------------------------------------->


<p-toast></p-toast>


//--------------------------------------------------------------------------------------------------
//  vedisys Library
//
//  File:   vs-view.component.ts
//  Notes:  View component (base class)
//
//
//  H I S T O R Y
//
//  2021-06-18  TC   Initial Version
//--------------------------------------------------------------------------------------------------

import {   Component
         , Input, Output
         , OnInit, AfterViewInit
       }                                          from '@angular/core';

import { Router }                                 from '@angular/router';
import { Subject }                                from 'rxjs';

// vsLib
import * as vsCommon                              from '@vsLib/vsCommon';
import * as vsGlobalResources                     from '@vsLib/Services/vsGlobalResources.service';
import * as vsTabControl                          from '@vsLib/Components/vsTab/vsTabControl.component';
import * as vsDataFilter                          from '@vsLib/vsDataFilter';
import * as vsParamList                           from '@vsLib/vsParamList';

// primeNG
import      { MenuItem }                          from 'primeng/api';
import { TvsTableComponent } from '@vsLib/Components/vsTable/vsTable.component';



//======[ TvsViewRouteParams ]======================================================================

//
// #### WORK IN PROGRESS ####
//
// $Rev TC 2020-11-18 TODO:
// Dies sollte noch in eine eigene Datei verlagert werden!
//
// Die Routing-Parameter dienen dazu, ggf. zusätzlich benötigte Parameter für das Routing spezi-
// fizieren zu können.
//
// Beispielsweise könnte eine konkrete Edit View zwei zusätzliche Parameter (außer dem Standard-
// parameter PKValue benötigen). Die zugehörige konkrete List View kann dann über die RouteParams
// diese zusätzlichen Parameter hinzufügen.
//


//
// TvsViewRouteParamsItem
//
// Filter Item
//
export class TvsViewRouteParamsItem {


  //====== Properties (PUBLIC)

  public  readonly    Name:                   String;
  public              Value:                  any;


  //====== Constructor

  constructor(a_sName: String, a_vValue?: any) {
      this.Name     = a_sName;
      this.Value    = a_vValue;
  } // contructor


  //====== Methods (PUBLIC)

  // ./.

} // TvsViewRouteParamsItem



//
// TvsViewRouteParams
//
// Parameter list for additional routing parameters
//
export class TvsViewRouteParams extends Array {

} // TvsViewRouteParams



//======[ TvsView ]=================================================================================



//
// TvsViewUsageMode
//
// Enumeration:
// Specifies the intended use of a view. Based on this information, properties can be set to
// suit the intended use.
//
export enum TvsViewUsageMode {
  umStandardView                    // Used e.g. as main view for a main programm function

, umDetailView                      // Used as detail view inside another view (e.g. another list view)
                                    // i.e. a detail view usually has no title bar and the detail section
                                    // is hidden by default.

, umSelectList                      // Used as list view inside a selection dialog
                                    // i.e. a select list usually has no title bar and no detail section
                                    // because the containing select dialog already has them.
} // TvsViewUsageMode



//
// TvsViewProperties
//
// View properties: base class
//
// Container to encapsulate all properties for views, thereby allowing easy reusing of this list
// of properties. This includes not just simple or complex properties like "ViewTitle", but also
// event handler methods.
//
export class TvsViewProperties {

  // Properties: Allgemein
  public    Instance:                     TvsView                 = null;
  public    InstanceBase:                 TvsView                 = null;

  public    ViewTitle:                    string                  = '';
  public    ViewUsageMode:                TvsViewUsageMode        = TvsViewUsageMode.umStandardView;
  public    TestModeLayout:               boolean                 = false;

  //public    ProgFuncID:                   string                = null;
  public    ToolbarItems:                 any                     = [];   // $Rev TC 2021-03-20: eventuell noch eine eigene Klasse hierfür
  public    MenuItemsContext:             MenuItem[]              = [];
  public    MenuItemsAction:              MenuItem[]              = [];
  public    MenuItemsNew:                 MenuItem[]              = [];
  public    TableList:                    TvsTableComponent;
  public    DlgDeleteConfirmShow:         boolean                 = false;

  public    DlgDeleteErrorShow:           boolean                 = false;
  public    DlgDeleteErrorMsgText:        string                  = "Der Datensatz kann nicht gelöscht werden!";

  public    DlgEditMultipleErrorShow:      boolean                  = false;
  public    DlgEditMultipleErrorMsgText:  string                  = "Es darf nur ein Datensatz bearbeitet werden!"

  public    DlgDeleteMultipleErrorShow:     boolean                  = false;
  public    DlgDeleteMultipleErrorMsgText:  string                  = "Es darf nur ein Datensatz bearbeitet werden!"

  public    DlgFlagSystemDeleteErrorShow:    boolean                 = false;
  public    DlgFlagSystemDeleteErrorMsgText: string                  = "Ein Systemeintrag kann nicht gelöscht werden!";


  public    doConfirmation:               boolean                 = true;
  //public    Modified:                     boolean;

  public detailDataId:                    string                    = null;

  // Properties: Routing
  public    RouteParams:                  TvsViewRouteParams    = new TvsViewRouteParams();

  // Properties: Section-Sichtbarkeit (beschreibbar im HTML per property binding)
  public    SectionIsVisible_Title:       boolean               = false;
  public    SectionIsVisible_Toolbar:     boolean               = true;
  public    SectionIsVisible_Filter:      boolean               = true;
  public    SectionIsVisible_MainRight:   boolean               = true;
  public    SectionIsVisible_Detail:      boolean               = true;
  public    SectionIsVisible_Footer:      boolean               = true;
  public    SectionIsVisible_SectionRight: boolean              = true;
  public    SectionRightMainColWidth:     number                = 8;


  // Event handler
  public    Subj:                         Subject<any>       = new Subject<any>(); // $Rev MHk 2021-06-01: für save event
  public    SubjDisplay:                  Subject<any>       = new Subject<any>(); // $Rev MH  2021-06-28: für DataDisplay
  public    SubjInitLoad:                 Subject<any>       = new Subject<any>(); // $Rev MH  2021-06-28: für DataDisplay


  // Properties: Sonstiges
  public    ProgFuncID:                   string                = '';
  public    DataSourceID:                 string                = null;
  public    QueryFields:                  string[]              = [];
  public    UseRecordBasedAccessRights:   boolean               = false;
  public    KennSaveData:                 boolean               = true;
 // public    DataFilter:                   vsDataFilter.TvsDataFilter    = new vsDataFilter.TvsDataFilter();


  // Fixed values for element/table height calculation
  public filterFlexContainerHeight: number = 34;
  public detailSectionTabHeight: number = 20;
  public toolbarHeight: number = 40.8;
  public fixedDetailSectionHeight: number = 350;
  public filterPaddings: number = 16;
  public paddingValues: number = 27.97;


  public fieldNameFlagSystem:     string                       = '';     // $Rev MP 2022-01-11: für die Abfrage nach Kenn_System-Eintrag hinzugefügt

} // TvsViewProperties



//
// TvsView
//
// Base class for all views
//
@Component({
  selector:       'vsView',
  templateUrl:  './vsView.component.html',
  styleUrls:   ['./vsView.component.scss']
})
export class TvsView implements OnInit, AfterViewInit {


  //====== Properties (PRIVATE)

  // ./.


  //====== Properties (PUBLIC)

//  static    readonly    ProgFuncID:             string                    = '';
  public                ProgFuncID:             string                        = '';     // $Rev TC 2021-06-06: class proerties (static) sind leider nicht in Instanzen der Klasse verfügbar :(
  public                TabItemMainView:        vsTabControl.TvsTabItem       = null;
  public                DataFilter:             vsDataFilter.TvsDataFilter    = new vsDataFilter.TvsDataFilter();
  public                ParamList:              vsParamList.TvsParamList<any> = new vsParamList.TvsParamList();
  public                HintInputReq:           string                        ="*Eingabe erforderlich";
  @Input()  ViewProperties:                     TvsViewProperties             = new TvsViewProperties();




  // Anmerkung:
  //
  // Auf die Elemente von ViewProperties kann zwar im HTML lesend zugegriffen werden (z.B. mit *ngIf
  // oder *ngFor oder {{xxx}}), aber nicht schreibend (per property binding)!  *grummel*
  // Deshalb werden hier alle Elemente von ViewProperties, auf die direkt schreibend per property binding
  // zugegriffen werden soll, nochmal als eigenständige @Input-Properties deklariert und mit einer Schreib-
  // zugriffsmethode versehen. Durch die Schreibzugriffsmethode wird sichergestellt, dass der entsprechende
  // Wert automatisch auch über "ViewProperties" zur Verfügung steht - das ist wichtig für das property
  // bindung im Selektor der Basisklasse.
  //
  // Übrigens funktioniert hier nur ENTWEDER @Output ODER @Input! *grrrr*
  // In diesem Zusammenhang wird aber wohl nur @Input benötigt...

  @Input()  set SectionIsVisible_Title(a_bValue: boolean) {
                  this.ViewProperties.SectionIsVisible_Title      = a_bValue
                }
  @Input()  set SectionIsVisible_Toolbar(a_bValue: boolean) {
                  this.ViewProperties.SectionIsVisible_Toolbar    = a_bValue
                }
  @Input()  set SectionIsVisible_Filter(a_bValue: boolean) {
                  this.ViewProperties.SectionIsVisible_Filter     = a_bValue
                }
  @Input()  set SectionIsVisible_MainRight(a_bValue: boolean) {
                  this.ViewProperties.SectionIsVisible_MainRight  = a_bValue
                }
  @Input()  set SectionIsVisible_Detail(a_bValue: boolean) {
                  this.ViewProperties.SectionIsVisible_Detail     = a_bValue
                }
  @Input()  set SectionIsVisible_Footer(a_bValue: boolean) {
                  this.ViewProperties.SectionIsVisible_Footer     = a_bValue
                }
   @Input()  set SectionIsVisible_SectionRight(a_bValue: boolean) {
                  this.ViewProperties.SectionIsVisible_SectionRight     = a_bValue
                }

  @Input()  set SectionRightMainColWidth(a_iValue: number) {
                  this.ViewProperties.SectionRightMainColWidth = a_iValue
  }



  //====== Constructor


  constructor(protected router:             Router
            , protected globalResources:    vsGlobalResources.TvsGlobalResources
  ) {
    // ./.
  } // constructor



  //====== NG event handler


  ngOnInit(): void {
  } // ngOnInit


  ngAfterViewInit(): void {
    // console.log('AfterViewInit VIEW')
  } // ngAfterViewInit



  //====== Methods (PUBLIC)


  //------------------------------------------------------------------------------------------------
  // Method:  dispatchRoute
  //
  // Notes:   Kapselt den Aufruf von router.navigate
  //
  // Durch diese Kapselung wirken sich Änderung an der Art, wie der Route-String mit seinen Parametern
  // konstruiert wird, nur auf die router-Methoden in dieser Basisklasse aus.
  //
  // Dies gilt insbesondere, da wir einen generischen Dispatcher benötigen, der auf Basis der Programm-
  // funktions-ID arbeitet, um die Benutzerzugriffsrechte zu berücksichtigen.
  //
  // Anmerkung:
  // Dies ist hier nur die allererste Implentierung für Tests des grundsätzlichen Verfahrens und basiert
  // auf dem Angular-Routing. Eine der nächsten Implementierungen wird eine Methode dispatchProgFunc
  // sein, welche die Programmfunktions-ID berücksichtigt
  //------------------------------------------------------------------------------------------------

  protected dispatchRoute(a_routeData: any[]): void {
    // console.log('TvsView.dispatchRoute():  a_routeData = ' + a_routeData);
    this.router.navigate(a_routeData);
  } // dispatchRoute


} // TvsView





<div *ngIf="false" class="container-xxl"  style="margin-top: 1.5rem; margin-bottom: 1.5rem; color: #808080; ">

  <div style="display: flex; font-size: 1.5rem; font-weight: bold; color: #f07918;  margin-top: 3rem; margin-bottom: 3rem; justify-content: center">
    App Header | Work In Progress
  </div>

</div> <!-- Ende container-xxl -->



<nav class="navbar bg vsNavbarElternPortal">
  <div class="container"><!--Container Start-->

    <!-- $REV MP 2022-12-13: KennLogoText und KennLogoHeader nicht mehr benötigt, beide werden durch Hintergrundbilder ersetzt. -->
    <!-- <div *ngIf="this.dataModel.KennLogoText" style="display: flex; cursor: pointer;" (click)="navHome()">
      <h1 class="vsElternPortalTitle">Schülerticket</h1><h2 class="vsElternPortalTitle">digital</h2>
    </div> -->

    <!-- <div *ngIf="this.dataModel.KennLogoHeader" style="display: flex; cursor: pointer;" (click)="navHome()" class="vsLogoBg">
      <div class="vsHeaderLogo"></div>
    </div> -->



    <ng-template [ngIf]="(this.KennTestMode === true)"
    [ngIfElse]="elseBlock">
    <div style="display: flex; cursor: pointer;" (click)="navHome()" class="vsLogoBg">
      <div class="vsHeaderLogoTest"></div>
    </div>
  </ng-template>

  <ng-template #elseBlock>
    <div style="display: flex; cursor: pointer;" (click)="navHome()" class="vsLogoBg">
      <div class="vsHeaderLogo"></div>
    </div>
  </ng-template>


    <!-- Sprachauswahl -->

    <div class="vsLangPicker vsForm">
      <label for="languagePicker" class="sr-only" >{{'header.languagePicker' | translate}}</label>
      <p-dropdown [options]="GUILanguageList" (onChange)="doHandleChangeEventAndSetSelectedLang($event)"
                  (keyup)="doHandleKeyUpEventAndChangeLang($event)" [(ngModel)]="GUILanguageSelected"
                  optionLabel="Caption" inputStyleClass="vsFormControl" inputId="languagePicker">
        <ng-template pTemplate="selectedItem">
            <div *ngIf="GUILanguageSelected" style="display: inline-flex;">
                <img src="{{GUILanguageSelected.IconURL}}" [class]="vsFlag" style="margin-right:5px;" alt=""/>
                <div>{{GUILanguageSelected.Caption}}</div>
            </div>
        </ng-template>
        <ng-template let-language pTemplate="item">
            <div class="language-item" (click)="doDropdownGUILanguageOnClick(this.GUILanguageList.indexOf(language))" style="display: inline-flex;">
                <img src="{{language.IconURL}}" [class]="vsFlag" style="margin-right:5px;" alt=""/>
                <div>{{language.Caption}}</div>
            </div>
        </ng-template>
      </p-dropdown>

    </div>
    <!-- Ende Sprachauswahl -->


    <!-- User Menu -->
    <!-- account-outline_dark_grey.svg -->
    <a *ngIf="!globalResources.WebAccProfile.IsLoggedIn" class="vsUser" (click)="actionLogin()" [attr.aria-label]="'header.login'| translate" tabindex="0">
      <div class="vsUserImage"></div>
      <span class="vsUserText" [innerHtml]="'header.login'| translate"></span>
    </a>

    <a *ngIf=" globalResources.WebAccProfile.IsLoggedIn" class="vsUser" (click)="menuUser.toggle($event)" [attr.aria-label]="globalResources.WebAccProfile.AccLoginID" tabindex="0">
      <!-- <img src="/assets/img/Icons/account-outline.svg" width="24px" height="24px"/> -->
      <div class="vsUserImage"></div>
      <p-menu #menuUser [model]="MenuUser" [popup]="true"></p-menu>
      <span *ngIf=" globalResources.WebAccProfile.IsLoggedIn" class="vsUserText">{{ globalResources.WebAccProfile.AccLoginID }}</span>
    </a>

  </div>  <!--Container Ende-->
</nav>  <!-- Header -->



<div *ngIf="false" class="container-xxl"  style="margin-top: 1.5rem; margin-bottom: 1.5rem; color: #808080; ">

  <div style="display: flex; font-size: 1.5rem; font-weight: bold; color: #f07918;  margin-top: 3rem; margin-bottom: 3rem; justify-content: center">
    App Header | END
  </div>

</div> <!-- Ende container-xxl -->


<!-- Header: Standard ----------------------------------------------------------------------------->
<appHeader></appHeader>
<!-- Main Content --------------------------------------------------------------------------------->

<div class="container-xxl"  style="margin-top: 1.5rem; margin-bottom: 1.5rem; height: 608px; color: #808080; ">
    <form [formGroup]="this.editForm">

        <vsLayoutGroup class="col" MediumGutterWidth=true Orientation="horizontal">

          <vsLayoutGroup class="col-lg-2 col-md-1 col-sm-0"></vsLayoutGroup>

          <vsLayoutGroup class="col-lg-8 col-md-10 col-sm-12" isPanel=true panelTitleVisible=true panelTitleText="{{'application.bank.heading'| translate}}">

            <vsLayoutGroup class="col-12" Orientation="horizontal">
              <vsLayoutGroup class="col">
                <vsInputText FrmCtlName="AntrStell_Kto_Inh" label="{{'application.bank.account_owner'| translate}}" labelWidth="110px"></vsInputText>
              </vsLayoutGroup>

            </vsLayoutGroup>
            <vsLayoutGroup class="col-12" Orientation="horizontal">
              <vsLayoutGroup class="col-lg-6 col-md-12">
                <vsInputButtonEdit FrmCtlName="AntrStell_Bank_Name" label="{{'application.bank.bank_name'| translate}}" labelWidth="110px" (onButtonClick)="doBankSearch($event)" ></vsInputButtonEdit>
              </vsLayoutGroup>
            </vsLayoutGroup>

            <vsLayoutGroup class="col-12" Orientation="horizontal">
              <vsLayoutGroup class="col">
                <vsInputText FrmCtlName="AntrStell_IBAN" label="{{'application.bank.IBAN'| translate}}" labelWidth="110px"></vsInputText>
              </vsLayoutGroup>
            </vsLayoutGroup>

          </vsLayoutGroup>

          <vsLayoutGroup class="col-lg-2 col-md-1 col-sm-0"></vsLayoutGroup>

        </vsLayoutGroup>

        <div style="margin-top: 3rem; margin-bottom: 1.5rem;">

          <button pButton label="{{'change.button_cancel'| translate}}"   (click)="actionStepPrev()" class="vsElternButton vsBack" style="margin-right: 10px;"></button>
          <button pButton label="{{'change.button_send'| translate}}"  (click)="actionStepNext()" class="vsElternButton vsForward"></button>
        </div>

    </form> <!-- Form Ende -->
</div> <!-- Ende container-xxl -->

<!-- Footer --------------------------------------------------------------------------------------->

<appFooter></appFooter>



<!-- Messages (Toasts) ---------------------------------------------------------------------------->


<p-toast></p-toast>


<!-- <pre>{{ this.editForm.value | json}}</pre> -->

